import ConsumerAssetMappingView from "../../components/Administration/ConsumerAssetMappingView";
import ScopeApprovers from "../../components/Administration/ScopeApprovers";

export const UIConstants = {
    Global_Roles:'Global_Roles',
    Role: 'Role',
    Group: 'Business Group',
    Platform: 'Platform',
    Form: 'Form',
    AccountType: 'Account Type',
    PermissionType: 'Permission Type',
    PermissionGroup: 'Permission Group',
    PermissionScope: 'Permission Scope',
    PermissionLevel: 'Permission Level',
    Attributes: 'Attribute',
    BusinessJustfication: 'Business Justification',
    BusinessOwner: 'Business Owner',
    Signature: 'Signature',
    AlternateManager:'Alternate Manager',
    TermsAndConditions: 'Terms & Conditions',
    Subsidaries: 'Subsidaries',
    SubsidiarySelection: 'Subsidiary Selection',
    BusinessSelection: 'Business Selection',
    Users: 'User Data',
    DefaultReportingSubsidiary: 'Default Reporting Subsidiary',
    File: 'File',
    FileType: 'File type is not supported. CSV file',
    FiscalCycles: 'Fiscal cycles',
    Messages: {
        ManagerNotFound: "The unavailability of your manager's information prevents you from submitting this request. A manager is required for request approval.",
        UserAliasChange: "Your user alias has been updated in Azure Active Directory. Please contact the EUA support team to proceed further.",
        TenantConfigurationNotFound: 'Tenant Configuration is missing',
        ResetConfirmation: 'Are you sure you want to reset the changes?',
        SaveConfirmation: 'Are you sure you want to submit this request?',
        RevokeConfirmation: 'Are you sure you want to revoke your access?',
        BulkRemovalConfirmation: "You are revoking access for '#count#' User(s). Are you sure you want to proceed?",
        RemoveRoleConfirmation: "Are you sure you want to remove '#RoleName#' ?",
        BasicRoleRemovalNote: "Removal of BASIC access will also remove INFORMA access.",
        AdvanceRoleRemovalNote: "Removal of Advanced access will also remove BASIC and INFORMA access.",
        PLPlanRemovalNote :'Removal of P&L role access will also remove INFORMA access.',
        RemoveRoleConfirmationAdmin: "Are you sure you want to remove '#RoleName#' for '#UserAlias#' ?",
        RemoveReviewConfirmationAdmin: "Are you sure you want to cancel '#ReviewName#' ?",
        InviteConfirmation: 'Are you sure you want to send email invite to selected User(s)?',
        SaveSuccess: 'Request submitted successfully.',
        SaveSuccessForDifferentPL: 'Request submitted successfully. Once your new P&L role is approved, your existing role will be removed.',
        SaveSuccessForDifferentTrail: 'Request submitted successfully. Once your new Trial balance role is approved, your existing role will be removed.',
        BulkRemovalSuccess: 'Bulk removal successfully processed.',
        ReviewSaveSuccess: 'Review submitted successfully.',
        RemoveSuccess: 'Access removed successfully.',  
        RemoveFailure: 'Failed to remove your request.',
        CancelReviewSuccess: 'Review cancelled successfully.',
        CancelReviewFailure: 'Failed to cancel your review.',
        InviteSuccess: 'Email invite sent successfully!',
        EndpointNotFound: 'Endpoint not found.',
        SaveFailure: 'Failed to submit your request.',
        FetchFailure: 'An unexpected error occurred.',
        RequestStatusNotFound: 'Request not found.',
        ScopeRemovalRequest: 'This is a scope removal request',
        InvalidProcessingError: 'There was an error processing your request. Please contact euaengineering@microsoft.com for further assistance.',
        RoleNotConfigured: 'No Role has been configured.',
        JustificationMaxLength: 'Business justification should not exceed 500 characters.',
        ReviewNameMaxLength: 'Review Name should not exceed 200 characters.',
        MandatoryField: 'Please fill in all required field.',
        HasExistingAccess: 'You already have access for ',
        ScopesRequired: 'required.',
        SubmitInProgress: 'Please wait while we process your Request.',
        InviteInProgress: 'Please wait while we send invitation your Request.',
        SubmitInProgressTitle: 'Processing...',
        RemoveRequestConfirmation: "Are you sure you want to remove your access request - '#Request#'?",
        LoadingDescription: 'Please wait while loading the page',
        Loading: 'Loading...',
        AccessRenewalRequest: 'Access Renewal Request',
        preventedRoutetoCEO: 'Warning: Access request cannot be routed to CEO. Please choose an alternate FTE approver.',
        MDSScopesRequired: 'Scope '
    },
    MessageBoxTitle: {
        Success: 'Success',
        Reset: 'Reset',
        RemoveAccess: 'Remove Access',
        Error: 'Error',
        SaveAccess: 'Submit Access',
        BulkRemoval: 'Bulk Removal',
        InviteUser: 'Invite Users',
        MyAccess: 'My Access',
        MyAccessEdit: 'My Access (View/Edit)',
        AccessDetails: 'Access Details',
        AccessHistory: 'Access History',
        AssetDetails: 'Asset Details',
        ReviewDetails: 'Review Details',
        UserDetails: 'User Details',
        RegistrationDetails: 'Registration Details',
        CancelReview: 'Cancel Review',
        Permissions:'Permissions'
    },
    ButtonText: {
        Close: 'Close',
        Ok: 'OK',
        Cancel: 'Cancel',
        Dismiss: 'Dismiss',
        Reset: 'Reset',
        Save: 'Save',
        Submit: 'Submit',
        SendInvite: 'Send Invite',
        Edit: 'Edit',
        Add: 'Add',
        Yes: 'Yes',
        No: 'No',
        ViewMyCurrentAccess: 'View/Edit My Access',
        ManageAcces: 'Manage Access',
        RemoveAccess: 'Remove Access',
        EditAccess: 'Edit Access',
        Remove: 'Remove',
        Search: 'Search',
        Update: 'Update',
        CreateReview: 'Create Review',
        FAQs: 'FAQs'
    },
    PageTitle: {
        Permissions:'Permissions',
        AccessRequest : 'Access Request',
        MyRequestHistory : 'My Request History',
        InviteUser : 'Invite Users',
        Delegation : 'Delegation',
        AppTitle : 'Enterprise User Access',
        Administration: 'Administration',
        Reports: 'Reports',
        UserManagement: 'User Management',
        BulkRemoval: 'Bulk Removal',
        UpdateRequest: 'Update Request',
        EditRequest: 'Edit Request',
        SupplierUserReport: 'Supplier User Report',
        SupplierUserUpdate: 'Supplier User Update',
        AssetView: 'Asset View',
        RegistrationRequestView: 'Registration Request View',
        ConsumerAccessRequestView: 'Consumer Access Request',
        ConsumerAssetMappingView: 'Consumer Asset Mapping',
        AccessReviewManagement: 'Access Review',
        CreateReview: 'Create Review',
        RequestRenewal: 'Access Request Renewal',
        MercuryDRSForm: 'DRS',
        DRSAccessRequest: 'DRS Access Request',
        MyPendingApprovals: 'My Pending Approvals',
        ApprovalPolicy: 'Approval Policy',
        FAQs: 'FAQs',
        TrainingLinks: 'Training Links'
    },
    Labletext: {
        RequestType: 'Request Type',
        MyApprovedRequest: 'My Approved Requests:',
        MyPendingAccess: 'My Pending Requests:',
        SelectUserOrGroup: 'Select Users / Group',
        Users: 'Users',
        Groups: 'Groups',
        CompanyCode: 'Company Code',
        Country : 'Country',
        RoleType: 'Financial Controller Type',
        SAPSystem : 'SAP System',
        Status : 'Status',
        InitialMigration: 'Initial migration data',
        OnBehalf: 'On Behalf',
        Removed : "Removed",
        Expired: "Expired",
        OperationsDetail: "OperationsDetail"
    },
    Status: {
        Submitted: 'Submitted',
        InProgress: 'InProgress',
        Complete: 'Complete',
        Approved: 'Approved',
        Rejected: 'Rejected'
    },
    PlaceHolderText: {
        SearchService: 'Search for a service',
        SearchAttribute: 'Search for '
    },
    RequestType: {
        Add: 'Add',
        Remove: 'Remove',
        Update: 'Update',
        Renew: 'Renew'
    },
    Configuration: {
        JEMTenantId: 'JEMTenantId',
        SAPRequestStatusEndPoint: 'SAPRequestStatusEndPoint',
        FDABotHostURL: 'FDABotHostURL',
        TenantConfig: 'TenantConfig',
        FDABotHostID: 'FDABotHostAppId',
        EnvironmentConfig: 'EnvironmentConfig',
        RolesForTenantConfig:'RolesForTenantConfig',
        KarnakGroupConfig: 'GroupConfig',
        MSTRoleGroupConfig: 'MSTRoleGroupConfig',
        MSSalesSubsidiaries: 'MSSalesSubsidiaries',
        FlightConfig: 'FlightConfig',
        VLAppsConfig: 'VLAppsConfig',
        PartnerCenterConfig: 'PartnerCenterConfig',
        KarnakFunctionConfig: 'KarnakFunctionConfig',
        MSSalesConfig: 'MSSalesConfig',
        AllowedDomains: 'AllowedDomains',
        MSApprovalLink: 'MSApprovalLink',
        DisplayAccessReview: 'DisplayAccessReview',
        MercuryConfig: 'MercuryConfig',
        PlanCastConfig: 'PlanCastConfig',
        MercuryFAQConfig: 'MercuryFAQConfig',
        AllowedAccountTypes: 'AllowedAccountTypes',
        QuizValidation: 'QuizValidation'
    },
    RouteConfig: {
        Permissions:'Permissions',
        RequestRoot : 'request',
        MyAccessRequest : 'myrequest',
        RequestAccess : 'access',
        InviteUser : 'inviteusers',
        UpdateRequest: 'update',
        AccessRenewal: 'accessrenew',
        EditRequest: 'edit',
        Administration: 'administration',
        Reports : 'reports',
        UserManagement: 'usermanagement',
        BulkRemoval: 'bulkremoval',
        SupplierUserManagement: 'supplierusermanagement',
        SupplierUserUpdate: 'supplieruserupdate',
        AssetView : 'assetview',
        RegistrationRequestView: 'registrationrequestview',
        ConsumerAccessRequestView: 'consumeraccessrequestview',
        ConsumerAssetMappingView: 'consumerassetmappingview',
        MSApprovals: 'msapprovals',
        Review: 'review',
        CreateReview: 'createreview',
        DRSForm: 'drsform',
        ApprovalPolicy: 'approvalpolicy',
        MercuryFAQ:'FAQ'
    },
    RoleDefinition: {
        Company_Allow_1: 'Company_Allow_1',
        Policy: 'Policy',
        Company: 'Company',
        UIDisplayFlag : 'UIDisplayFlag:false',
        AllowEditFlag: 'AllowEdit:true',
        AllScopesRequired: 'AllScopesRequired:false',
        MSSalesBasicRole: 'User Account - Basic',
        BasicAccess: 'Basic Access'
    },
    Tenant: {
        Alfred:'Alfred',
        FDL : 'FDL',
        Karnak : 'Karnak',
        Invoice: 'Invoice',
        FCW: 'FCW',
        MST: 'MST',
        PartnerCenter: 'PartnerCenter',
        MSSales: 'MSSales',
        Mercury: 'Mercury',
        MSPlancast: 'MSPlancast',
        CST: 'CST',
        CST_Prod: 'CST_Prod',
        CST_Canary: 'CST_Canary',
        CMAT: 'CMAT',
        MDS: 'MDS',
        PartnerCenter_WOBO: 'PartnerCenter_WOBO'
    },
    Attribute: {
        OperationsDetail: 'OperationsDetail',
        BusinessGroup: 'BusinessGroup',
        Company: 'Company',
        StatutoryReporting : 'Statutory reporting'
    },
    PermissionTypes: {
        MercuryBasicAccess: 'Basic',
        MercuryAdvanced: 'Advanced',
        MercuryInforma : 'Informa'
    },
    PermissionLevels: {
        MercuryBasicAccess: 'Mercury - Basic Access',
        BasicAccess:'Basic Access',
        Geography: 'Geography',
        FunctionalExeOrgSummary: 'Functional / Exec org Summary',
        ConsolidatedGaap: 'Consolidated Gaap',
        Specific: 'Specific',
        All: 'All companies',
        SCOMP: "Specific Companies"
    },

    PermissionGroups: {
        PLPlanGroup: 'P&L and P&L Plan',
        TrialBalanceGroup : 'Trial Balance & Statutory'
    },
    Review: {
        Name: 'Name',
        Description: 'Description',
        Reviewers: 'Reviewers',
        FiscalYear: 'Fiscal Year',
        Quarter: 'Quarter',
        NameErrorMsg: 'Review Name cannot be empty',
        DuplicateNameErrorMsg: 'The Review Name already exist',
        DescriptionErrorMsg: 'Description cannot be empty',
        FiscalQuarterErrorMsg:'Fiscal Year and Quarter cannot be empty',
        DateErrorMsg: 'EndDate cannot be less than StartDate of the review',
        DateDurationErrorMsg: 'The duration of the review should be minimum 3 days.',
        StartDateErrorMsg: 'StartDate cannot be empty',
        EndDateErrorMsg: 'EndDate cannot be empty',
        StartEndDateErrorMsg: 'StartDate and EndDate cannot be empty',
        ApproversErrorMsg: 'Approvers cannot be empty'
    },
    ProgramFamily: {
        Surface: 'Surface'
    }
}