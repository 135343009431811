import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { IDropdownOption, DefaultButton, TextField, Text, PrimaryButton, DialogType, getId, MessageBar, MessageBarButton, MessageBarType, Label, ILabelStyles, Spinner, ProgressIndicator, SpinnerSize, IconButton, ITextField, IButton, Dropdown, Checkbox, Link, ICheckbox, ComboBox, IComboBoxStyles, IComboBoxOption, Stack, IStyleFunctionOrObject, ILabelStyleProps } from '@fluentui/react';
import { ModalDialog } from '../ModalDialog';
import { RoleDetails, Attributes, AttributeValues, AccessRequestSubmissionModel } from '../../shared/models/UserAccess.model';
import { Role, RoleResponse, AttributeConfig, AttributeType, ExternalAADRoles, BulkRequest, AllowedAccount, CheckBoxTreeModel } from "../../shared/models/Role.model"
import { DialogModel } from "../../shared/models/Dialog.model"
import { IUser } from '../../shared/models/User.model';
import { UIConstants } from '../../shared/models/Constants';
import { msalAuth } from '../../shared/auth/MsalAuthProvider';
import { AttributePicker } from './AttributePicker';
import _, { Dictionary } from "lodash";
import MyAccess from './MyAccess';
import MercuryRequest from './MercuryRequest';
import ITenant from '../../shared/models/Tenant.model';
import { ValidationResponse } from '../../shared/models/ValidationResponse.model';
import { Utility } from '../../shared/models/Helper';
import AccessAPI from '../../store/AccessAPI';
import ConfigurationAPI from '../../shared/api/Configuration.api';
import CheckboxTree from 'react-checkbox-tree';
import { EmployeeDetails } from '../../shared/models/EmployeeDetails';
import PeoplePicker from '../PeoplePicker';
import { PeoplePickerContentType, PeoplePickerSelectionMode, PeoplePickerType } from '../../shared/models/PeoplePicker.enum';
import { ApprovedAccess, MyCurrentAccessModel } from '../../shared/models/MyCurrentAccess.model';
import { access } from 'fs/promises';
import { BusinessGroup, Roles, BusinessGroupResponse, BusinessGroups } from "../../shared/models/BusinessGroup.model";
import { roleMockData } from '../../shared/mock/RoleMockData';
import "react-checkbox-tree/lib/react-checkbox-tree.css";
import * as XLSX from "xlsx";
import { ChoiceGroup, IChoiceGroupOption } from '@fluentui/react/lib/ChoiceGroup';
import { Cycles } from '../../shared/models/Alfred';
import PlancastRequest from './PlancastRequest';
const labelStyles: Partial<IStyleFunctionOrObject<ILabelStyleProps, ILabelStyles>> = {
    root: { marginTop: 7 }
};
const comboBoxStyles: Partial<IComboBoxStyles> = { root: { maxWidth: 350, width: 350 } };
const businessGroupRadio: IChoiceGroupOption[] = [
    { key: 'Self', text: 'Self' },
    { key: 'OnBelfInBulk', text: 'On Behalf (in bulk mode)' },
];
export interface IRequestState {
    alfredCycles: Array<Cycles>;
    showPanel: boolean;
    hideDialog: boolean;
    selectedRole?: Role;
    hideBj: boolean;
    hideAttributes: boolean;
    modalDialogContent: any;
    showMessage: boolean;
    userDetails: IUser;
    message: string;
    messageType: MessageBarType;
    Justification?: string;
    Signature?: string;
    isSignatureVerfied: boolean;
    selectedAttributes?: Attributes[];
    showRequestArea: boolean;
    disableEditAccess: boolean;
    saveInProgress: boolean;
    disableRoleDrpDown: boolean;
    showModal: boolean;
    requestType: string;
    scope: string;
    autoHideMessage: boolean;
    pickerCtrlKey: number;
    tenantData: ITenant;
    RoleDescription: string;
    setAttributeFocus: boolean;
    roleType: string;
    RoleDetails: RoleResponse;
    attributesLoaded: boolean;
    operationsDetailsLoaded: boolean
    attributeConfig: AttributeConfig[];
    showTermsAndConditions: boolean;
    showMSSalesTermsAndConditions: boolean;
    isTermsAgreed: boolean;
    attestedList: any;
    karnakConfig: any;
    mstRoleGroupConfig: any;
    showKarnakAttributeDesc: boolean;
    attributeDescList: any;
    subsidiaryChecked: any;
    subsidiaryExpanded: any;
    businessChecked: any;
    businessExpanded: any;
    showSubsidiary: boolean;
    ManagerAlias: EmployeeDetails;
    isAlternateManager: string;
    alternateManagerAlias?: string;
    isAlternateManagerChecked: boolean;
    isManager: string;
    attributedisabled: boolean;
    ccattributedisabled: boolean;
    options: IComboBoxOption[];
    mstGrouplist: boolean;
    isMSTTenant: boolean;
    selectedGroup: any;
    subsidiariesConfig: any;
    defaultReportingSubsidiariesConfig: any;
    selectedDefaultReportingSubsidiary: string;
    businessConfig: any;
    approvedaccess: Array<ApprovedAccess>;
    updateRequest: boolean;
    editRequest: boolean;
    isPartnerCenterTenant: boolean;
    vlAppsRoleGroupConfig: BusinessGroupResponse;
    enableRoleGrouping: boolean;
    selectedBusinessGroupRole: string;
    partnerCenterConfig: any;
    programFamily: string;
    allowSkipLevel: boolean;
    karnakFunctionConfig: any;
    accessTypeBI: string;
    requestorPickerCtrlKey: number;
    requestorPrincipalId: string;
    enableRequestOnBehalf: boolean;
    renderSkiplevel: boolean;
    mercuryData: any;
    isMercuryEditMode : boolean;
    mercuryEditAccessData: any;
    hasMSSalesBasicAccess: boolean;
    displayApprovedBasicExistsMessage: boolean;
    approvedMSSalesAccess: Array<ApprovedAccess>;
    displayApprovedExistsMessage: boolean;
    myAccessDataLoaded: boolean;
    cosmosRoleDefinitions: ExternalAADRoles[];
    myAccess: MyCurrentAccessModel;
    displayPendingBasicExistsMessage: boolean;
    displayPendingExistsMessage: boolean;
    selectedFile: any;
    isSelected: boolean;
    isRequestModeBulk: boolean;
    selectedKey: any;
    fileData: string;
    hasBulkUploadAccess: boolean;
    BulkUserDetails?: BulkRequest[];
    isSiteUnderMaintenance: boolean,
    allowedAccountTypes: AllowedAccount[],
    selectedAccountType: string;
    selectedAccountUPN: string;
    selectedBGName: string;
    fetchingFiscalCycles: boolean;
    renderAttestation: boolean;
    alfredCyclesNodes: any;
    cyclesChecked: Array<string>;
    renderAlternateApprover: boolean;
    approvedAlfredCycles: string[];
    quizUrl: string;
    approvedMDSAccess: Array<ApprovedAccess>;
    attributeChecked: CheckBoxTreeModel[];
    attributeExpanded: CheckBoxTreeModel[];
    selectedValues: any;
    selectAllAttributeList: string[];
}
export interface IRequestProperty {
    saveAccess?: any;
    isLoadingGlobal?: boolean;
}

type RequestProps = IRequestState & IRequestProperty &
    RouteComponentProps<{}>;

export default class Request extends React.Component<RequestProps> {
    private _labelId: string = getId('dialogLabel');
    private _subTextId: string = getId('subTextLabel');
    private _roleDropdownItems: IDropdownOption[] = [];
    private _businessgroupDropdownItems: IDropdownOption[] = [];
    private tenantData: ITenant = null;
    private _modalDialogContent: DialogModel = {
        type: DialogType.normal,
        title: UIConstants.MessageBoxTitle.SaveAccess,
        closeButtonAriaLabel: UIConstants.ButtonText.Close,
        subText: UIConstants.Messages.SaveConfirmation,
        okAction: null,
        cancelAction: null,
    }
    private refBJ = React.createRef<ITextField>();
    private scopeRef = React.createRef<HTMLSelectElement>();
    private alertMsg2CloseBtnRef = React.createRef<IButton>();
    private alertMsgCloseBtnRef = React.createRef<IButton>();
    private refTC = React.createRef<ICheckbox>();
    private bulkUploadref = React.createRef<HTMLInputElement>();
    private accountTypeDivRef = React.createRef<HTMLDivElement>();
    private messageBarDivRef = React.createRef<HTMLDivElement>();

    public state: IRequestState = {
        alfredCycles:[],
        showPanel: false,
        selectedRole: { id: '0', name: null, scopes: null, description: '', permissions: [], sortOrder: '', attributes: [] },
        hideDialog: true,
        hideBj: true,
        hideAttributes: true,
        modalDialogContent: this._modalDialogContent,
        showMessage: false,
        userDetails: null,
        message: '',
        messageType: MessageBarType.info,
        Justification: '',
        Signature: '',
        isSignatureVerfied: false,
        selectedAttributes: [],
        showRequestArea: true,
        disableEditAccess: false,
        saveInProgress: false,
        disableRoleDrpDown: false,
        showModal: false,
        requestType: 'Add',
        autoHideMessage: true,
        tenantData: null,
        scope: '',
        RoleDescription: null,
        setAttributeFocus: false,
        roleType: '',
        RoleDetails: { Data: [], IsSuccess: false, IsDataLoaded: false },
        attributesLoaded: false,
        operationsDetailsLoaded: false,
        attributeConfig: [],
        pickerCtrlKey: 0,
        showTermsAndConditions: false,
        showMSSalesTermsAndConditions: false,
        isTermsAgreed: false,
        attestedList: [],
        karnakConfig: [],
        mstRoleGroupConfig: [],
        showKarnakAttributeDesc: false,
        attributeDescList: [],
        subsidiaryChecked: [],
        subsidiaryExpanded: [],
        businessChecked: [],
        businessExpanded: [],
        showSubsidiary: false,
        ManagerAlias: { id: '', name: '', displayName: '', email: '', upn: '', surname: '', domain: '', jobTitle: '' },
        alternateManagerAlias: '',
        isAlternateManager: '',
        isManager: '',
        isAlternateManagerChecked: false,
        attributedisabled: false,
        ccattributedisabled: false,
        options: [],
        mstGrouplist: false,
        isMSTTenant: false,
        selectedGroup: [],
        subsidiariesConfig: [],
        defaultReportingSubsidiariesConfig: [],
        selectedDefaultReportingSubsidiary: "0",
        businessConfig: [],
        approvedaccess: [],
        updateRequest: false,
        editRequest: false,
        isPartnerCenterTenant: false,
        enableRoleGrouping: false,
        selectedBusinessGroupRole: '',
        vlAppsRoleGroupConfig: { Data: null, IsSuccess: false, IsDataLoaded: false },
        partnerCenterConfig: [],
        programFamily: '',
        allowSkipLevel: false,
        karnakFunctionConfig: [],
        accessTypeBI: '',
        requestorPickerCtrlKey: 0,
        requestorPrincipalId: '',
        enableRequestOnBehalf: false,
        renderSkiplevel: false,
        mercuryData: null,
        isMercuryEditMode : false,
        mercuryEditAccessData: null,
        hasMSSalesBasicAccess: false,
        displayApprovedBasicExistsMessage: false,
        approvedMSSalesAccess: [],
        myAccessDataLoaded: false,
        cosmosRoleDefinitions: [],
        displayApprovedExistsMessage: false,
        myAccess: { approvedAccess: [], pendingRequests:[] },
        displayPendingBasicExistsMessage: false,
        displayPendingExistsMessage: false,
        selectedFile: null,
        isSelected: false,
        isRequestModeBulk: false,
        selectedKey: 'Self',
        fileData: '',
        hasBulkUploadAccess: false,
        BulkUserDetails: [],
        isSiteUnderMaintenance: false,
        allowedAccountTypes: [],
        selectedAccountType: "0",
        selectedAccountUPN: '',
        selectedBGName: '',
        fetchingFiscalCycles: false,
        renderAttestation: false,
        alfredCyclesNodes: [],
        cyclesChecked: [],
        renderAlternateApprover: false,
        approvedAlfredCycles: [],
        quizUrl: null,
        approvedMDSAccess: [],
        attributeChecked: [],
        attributeExpanded: [],
        selectedValues: [],
        selectAllAttributeList: []
    };
    public constructor(props) {
        super(props);

    }
    /**
     * React Life cycle Events
     */
    public async componentDidMount() {
        let userAccount = msalAuth.getAccount();
        let user: IUser = { PrincipalId: userAccount.accountIdentifier, Alias: userAccount.userName, Name: userAccount.name };
        let tenantName = (this.props.match.params as any).tenantName;
        tenantName = tenantName.toUpperCase();
        setTimeout(() => {
            let TenantData = Utility.GetTenantData();
            this.tenantData = TenantData.filter(x => x.TenantName.toUpperCase() === tenantName)[0];
            this.setState({ userDetails: user, tenantData: this.tenantData, Signature: '', });
            if (this.tenantData?.SiteMaintenance?.ShowMessage) {
                this.setState({ isSiteUnderMaintenance: true, showRequestArea :false});
            }
            else {
                this.onInit(this.tenantData);
            }
        }, 500);
    }
    /**
     * UI Render
     */
    public render(): JSX.Element {
        const { isSiteUnderMaintenance, showMessage, saveInProgress, showModal, RoleDetails, isMercuryEditMode, mercuryEditAccessData, myAccessDataLoaded } = this.state;

        return <React.Fragment>
            <div ref={this.messageBarDivRef} id={'div-msg-area'} style={{ height: 25 }}>
                {(RoleDetails?.IsDataLoaded && !RoleDetails?.IsSuccess) && this.renderMessage(UIConstants.Messages.FetchFailure, MessageBarType.error)}
                {(RoleDetails?.IsDataLoaded && RoleDetails?.IsSuccess && RoleDetails.Data.length === 0) && this.renderMessage(UIConstants.Messages.RoleNotConfigured, MessageBarType.warning)}
                {(showMessage) && this.renderMessage2()}
                {(saveInProgress) && <ProgressIndicator label={UIConstants.Messages.SubmitInProgressTitle} description={UIConstants.Messages.SubmitInProgress} />}
            </div>
            <div id={'div-req-section'} style={{ padding: '1%', paddingTop: '2%' }} >
                <div key="grid-request" className={"ms-Grid"} dir="ltr">
                    <div key='grid-row-pgtitle' className={"ms-Grid-row"}>
                        <div className={"ms-Grid-col ms-sm6 ms-md6 ms-lg9"}>
                            <Label style={{ paddingLeft: 0, fontSize: 20 }} > <h1 role="heading" aria-label={UIConstants.PageTitle.AccessRequest} aria-level={1} className={"h1"} style={{ paddingLeft: 0, fontSize: 20, margin: 0 }}> {UIConstants.PageTitle.AccessRequest}</h1></Label>
                        </div>
                        <div className={"ms-Grid-col ms-sm6 ms-md6 ms-lg3"}>
                            <div hidden={!RoleDetails.IsDataLoaded} style={{ paddingTop: 10 }}>
                                <DefaultButton style={{ float: "right", width: 'max-content', position: 'absolute', marginLeft: window.innerWidth < 400 ? '-15%' : 0 }} secondaryText={UIConstants.ButtonText.ViewMyCurrentAccess}
                                    text={UIConstants.ButtonText.ViewMyCurrentAccess} onClick={() => {
                                        this.setState({ showModal: true ,isMercuryEditMode: false , mercuryEditAccessData : null });
                                    }} />
                                {(showModal) && <MyAccess tenantId={this.tenantData.TenantId} roleData={RoleDetails.Data} {...this.props} onDismiss={(data) => {
                                    if (data) {
                                        this.onMyAccessEditClick(data);
                                        this.setState({ showModal: false })
                                    } else {
                                        this.setState({ showModal: false, updateRequest: false })
                                    }
                                }} />}
                            </div>
                        </div>
                    </div>
                </div>

                <div key="work-area" >
                    {(this.tenantData?.TenantName != UIConstants.Tenant.MSSales && RoleDetails?.IsDataLoaded) || (this.tenantData?.TenantName == UIConstants.Tenant.MSSales && RoleDetails?.IsDataLoaded && myAccessDataLoaded) ? <div hidden={!RoleDetails?.IsSuccess}>
                        {(this.state.showRequestArea) &&
                            <div>
                                {this.tenantData.TenantName != UIConstants.Tenant.Mercury && this.tenantData.TenantName != UIConstants.Tenant.MSPlancast && (this.state.isMSTTenant || this.state.enableRoleGrouping || this.tenantData?.isExternalAADTenant) && this.renderAccess()}
                                {this.tenantData.TenantName != UIConstants.Tenant.Mercury && this.tenantData.TenantName != UIConstants.Tenant.MSPlancast && !this.state.isMSTTenant && !this.state.enableRoleGrouping && !this.tenantData?.isExternalAADTenant && this.renderEditAccess()}
                                {this.tenantData.TenantName == UIConstants.Tenant.MSPlancast && <PlancastRequest messageBarRef={this.messageBarDivRef} tenantData={this.tenantData} toggleProgressBar={this.toggleProgressBar} toggleMessageBar={this.toggleMessageBar} />}
                                {this.tenantData.TenantName == UIConstants.Tenant.Mercury && <MercuryRequest messageBarRef={this.messageBarDivRef} tenantData={this.tenantData} isEditMode={isMercuryEditMode} editAccessData={mercuryEditAccessData}
                                 toggleProgressBar={this.toggleProgressBar} toggleMessageBar={this.toggleMessageBar} />}
                            </div>
                        }
                    </div>
                        :
                        (isSiteUnderMaintenance) ? <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%,-50%)', textAlign: 'center', fontSize: 18, fontWeight: 600 }}>{this.tenantData.SiteMaintenance.Message}</div>
                            :
                            <Spinner size={SpinnerSize.large} label="loading..." ariaLive="assertive" labelPosition="bottom" />
                    }
                </div>
            </div>
        </React.Fragment>
    };
    private renderAttributeValues = () => {
        let { selectedAttributes, selectedRole, setAttributeFocus, attributesLoaded, attributeConfig, attributedisabled, ccattributedisabled, operationsDetailsLoaded, attributeChecked, attributeExpanded, selectedValues } = this.state;
        let attributeNames = selectedRole.attributes && selectedRole.attributes.length > 0 ? selectedRole.attributes : [];
        let attributeItem = [];
        if (attributesLoaded) {
            for (const attributeName of attributeNames) {
                let attributeLabelName = attributeName && attributeName === UIConstants.RoleDefinition.Company ? UIConstants.Labletext.CompanyCode : attributeName;
                let policyDelimiter = false;
                if (!(this.state.tenantData.TenantName == UIConstants.Tenant.MSSales)) {
                    let permissionAction = selectedRole.permissions[0].actions;
                    if (permissionAction) {
                        let policy = permissionAction.filter(x => x.indexOf(UIConstants.RoleDefinition.Policy) > -1);
                        policyDelimiter = policy && policy.length > 0 ? policy[0].split(";").filter(x => x.indexOf(UIConstants.RoleDefinition.Company_Allow_1) > -1).length > 0 : false;
                    }
                }
                if (selectedRole?.permissions?.length > 0 && selectedRole.permissions[0].condition !== null && attributeName) {
                    let filterdAttributeConfig = attributeConfig.filter(x => x.attributeName === attributeName)[0];
                    switch (filterdAttributeConfig.attributeType) {
                        case AttributeType.List: // Dropdown
                            if (attributeLabelName.includes('AccessType')) {
                                let labelName = "AccessType";
                                attributeItem.push(<div key={'div-attr-' + labelName} className={"ms-Grid-col ms-sm12 ms-md8 ms-lg6"} style={{ paddingLeft: 0, display: 'contents' }}>
                                    <Label required={true} styles={labelStyles}>{labelName}</Label>
                                    <select id={'div-attr-' + labelName}
                                        title={labelName} required={true}
                                        onChange={(event) => {
                                            let val = event.target.value;
                                            let attrValue = [{ code: val, description: val }];
                                            if (attributeLabelName == 'AccessTypeBI') {
                                                this.setState({ accessTypeBI: val });
                                            }
                                            this.onAttributeDropDownChange(attributeLabelName, attrValue)
                                        }}
                                        style={{ height: 32, width: window.innerWidth < 400 ? 250 : 350 }} >
                                        <option value="0">-- Select --</option>
                                        {filterdAttributeConfig.attributeValue.map(x => { return <option key={'drpattr-' + x.code} value={x.code}>{x.code}</option> })}
                                    </select>
                                    <br />
                                </div>

                                );
                            }
                            else if (attributeLabelName == 'Program') {
                                attributeItem.push(<div key={'div-attr-' + attributeLabelName} className={"ms-Grid-col ms-sm12 ms-md8 ms-lg6"} style={{ paddingLeft: 0, display: 'contents' }}>
                                    <Label required={true} styles={labelStyles}>{attributeLabelName}</Label>
                                    {this.state.programFamily == UIConstants.ProgramFamily.Surface && (
                                        <ComboBox
                                            multiSelect
                                            options={this.getProgramValues().map(x => { return { key: x, text: x } })}
                                            styles={comboBoxStyles}
                                            onChange={(event, option) => this.onMultiSelectDropDownChange(event, option, filterdAttributeConfig.attributeName)}
                                        />
                                    )}
                                    {this.state.programFamily != UIConstants.ProgramFamily.Surface && (
                                        <select id={'div-attr-' + attributeLabelName}
                                            title={attributeLabelName} required={true}
                                            onChange={(event) => {
                                                let val = event.target.value;
                                                let attrValue = [{ code: val, description: val }];
                                                this.onAttributeDropDownChange(attributeLabelName, attrValue)
                                            }}
                                            style={{ height: 32, width: window.innerWidth < 400 ? 250 : 350 }} >
                                            <option value="0">-- Select --</option>
                                            {this.getProgramValues()?.map(x => { return <option key={'drpattr-' + x} value={x}>{x}</option> })}
                                        </select>
                                    )}
                                </div>);
                            }
                            else if (attributeLabelName == 'ProgramFamily') {
                                let labelName = "Program Family";
                                attributeItem.push(<div key={'div-attr-' + labelName} className={"ms-Grid-col ms-sm12 ms-md8 ms-lg6"} style={{ paddingLeft: 0, display: 'contents' }}>
                                    <Label required={true} styles={labelStyles}>{labelName}</Label>
                                    <select id={'div-attr-' + labelName}
                                        title={labelName} required={true}
                                        onChange={(event) => {
                                            let val = event.target.value;
                                            let attrValue = [{ code: val, description: val }];
                                            this.setState({ programFamily: val.split(":")[0] });
                                            this.onAttributeDropDownChange(attributeLabelName, attrValue)
                                        }}
                                        style={{ height: 32, width: window.innerWidth < 400 ? 250 : 350 }} >
                                        <option value="0">-- Select --</option>
                                        {this.getProgramFamilyValues()?.map(x => { return <option key={'drpattr-' + x} value={x}>{x}</option> })}
                                    </select>
                                    <br />
                                </div>);
                            }
                            else if (attributeLabelName == 'Function') {
                                attributeItem.push(<div key={'div-attr-' + attributeLabelName} className={"ms-Grid-col ms-sm12 ms-md8 ms-lg6"} style={{ paddingLeft: 0, display: 'contents' }}>
                                    <Label required={true} styles={labelStyles}>{attributeLabelName}</Label>
                                    <select id={'div-attr-' + attributeLabelName}
                                        title={attributeLabelName} required={true}
                                        onChange={(event) => {
                                            let val = event.target.value;
                                            let attrValue = [{ code: val, description: val }];
                                            this.onAttributeDropDownChange(attributeLabelName, attrValue)
                                        }}
                                        style={{ height: 32, width: window.innerWidth < 400 ? 250 : 350 }} >
                                        <option value="0">-- Select --</option>
                                        {this.getFunctionValues()?.map(x => { return <option key={'drpattr-' + x} value={x}>{x}</option> })}
                                    </select>
                                    <br />
                                </div>);
                            }
                            else {
                                attributeItem.push(<div key={'div-attr-' + attributeLabelName} className={"ms-Grid-col ms-sm12 ms-md8 ms-lg6"} style={{ paddingLeft: 0, display: 'contents' }}>
                                    <Label required={true} styles={labelStyles}>{attributeLabelName}</Label>
                                    <select id={'div-attr-' + attributeLabelName}
                                        title={attributeLabelName} required={true}
                                        onChange={(event) => {
                                            let val = event.target.value;
                                            let attrValue = [{ code: val.split(":")[0], description: val.split(":")[0] }];
                                            if (attributeLabelName == "Region") {
                                                attrValue = [{ code: val.split(":")[0], description: val.split(":")[1] }];
                                            }
                                            this.onAttributeDropDownChange(attributeLabelName, attrValue)
                                        }}
                                        style={{ height: 32, width: window.innerWidth < 400 ? 250 : 350 }} >
                                        // If Hideselect flag:true then by deafult First attribute should get selected
                                        {!selectedRole.permissions[0].actions.includes('HideSelect:true') && <option value="0">-- Select --</option>}
                                        {filterdAttributeConfig.attributeValue.sort((a, b) => a.code == "All" ? -1 : b.code == "All" ? 1 : ((a.code < b.code) ? -1 : 1)).map(x => { return <option key={'drpattr-' + x.code} value={x.code + ":" + x.description}>{x.code}</option> })}
                                        {(selectedRole.permissions[0].actions.includes('HideSelect:true')) && (selectedAttributes == null || selectedAttributes.length <= 0) && filterdAttributeConfig.attributeValue.length > 0 && this.onAttributeDropDownChange(attributeLabelName, [{ code: filterdAttributeConfig.attributeValue.sort((a, b) => a.code == "All" ? -1 : b.code == "All" ? 1 : ((a.code < b.code) ? -1 : 1))[0].code.split(":")[0], description: filterdAttributeConfig.attributeValue.sort((a, b) => a.code == "All" ? -1 : b.code == "All" ? 1 : ((a.code < b.code) ? -1 : 1))[0].description.split(":")[0] }])}
                                    </select>
                                    <br />
                                </div>);
                            }
                            break;
                        case AttributeType.MultiSelectList: // Multi Select Dropdown
                            if (attributeLabelName == 'AreaRelia') {
                                attributeLabelName = "Area";
                            }
                            if ((this.tenantData.EnableRoleGrouping && attributeLabelName == UIConstants.Labletext.Country) || this.tenantData.TenantName == UIConstants.Tenant.MDS) {
                                attributeItem.push(<div key={'div-attr-' + attributeLabelName} className={"ms-Grid-col ms-sm12 ms-md8 ms-lg6"} style={{ paddingLeft: 0, display: 'contents' }}>
                                    <Label required={true} styles={labelStyles}>{attributeLabelName}</Label>
                                    <div key='grid-row-attr-lkp'>
                                        <Stack horizontal tokens={{ childrenGap: 10 }}>
                                            {attributedisabled && <Dropdown
                                                disabled={this.tenantData.TenantName == UIConstants.Tenant.MDS ? (this.state.selectAllAttributeList.filter(x => x.includes(attributeLabelName))?.length>0):attributedisabled}
                                                multiSelect={true}
                                                selectedKeys={selectedValues}
                                                placeholder="--Select--"
                                                style={{ height: 32, width: window.innerWidth < 400 ? 250 : 350 }}
                                                options={filterdAttributeConfig.attributeValue.sort((a, b) => (a.description < b.description) ? -1 : 1).map(x => { return { key: x.code, text: this.tenantData.TenantName == UIConstants.Tenant.MDS ? x.description : x.code } })}
                                                onChange={(event, option) => { this.onMultiSelectDropDownChange(event, option, filterdAttributeConfig.attributeName) }}
                                            />
                                            }
                                            {!attributedisabled && <Dropdown
                                                disabled={this.tenantData.TenantName == UIConstants.Tenant.MDS ? (this.state.selectAllAttributeList.filter(x => x.includes(attributeLabelName))?.length > 0) : attributedisabled}
                                                multiSelect={true}
                                                selectedKeys={selectedValues}
                                                placeholder="--Select--"
                                                style={{ height: 32, width: window.innerWidth < 400 ? 250 : 350 }}
                                                options={filterdAttributeConfig.attributeValue.sort((a, b) => (a.description < b.description) ? -1 : 1).map(x => { return { key: x.code, text: this.tenantData.TenantName == UIConstants.Tenant.MDS ? x.description : x.code } })}
                                                onChange={(event, option) => { this.onMultiSelectDropDownChange(event, option, filterdAttributeConfig.attributeName) }}
                                            />
                                            }
                                            <span>&nbsp;</span>
                                            <Checkbox styles={{ checkbox: { height: '20px', width: '20px' } }} onRenderLabel={() => {
                                                return (<span>
                                                    Select All
                                                </span>
                                                );
                                            }} onChange={(ev, checked) => {
                                                let attributeConfigData = this.state.attributeConfig;
                                                let selectAllAttributeList = this.state.selectAllAttributeList;
                                                let selectedKeyValues = this.state.selectedValues;
                                                if (this.tenantData.TenantName == UIConstants.Tenant.MDS) {
                                                    let attributeValues = [];
                                                    if (checked) {
                                                        filterdAttributeConfig.attributeValue.forEach(x => {
                                                            attributeValues.push({ "code": x.code, "description": x.description })
                                                        });
                                                        selectAllAttributeList.push(attributeLabelName);
                                                    }
                                                    else {
                                                        if (this.state.editRequest) {
                                                            filterdAttributeConfig.attributeValue.forEach(x => {
                                                                attributeValues.push({ "code": x.code, "description": x.description, "isRemove": true })
                                                            });
                                                        }
                                                        _.remove(selectAllAttributeList, (z) => { return z === attributeLabelName })
                                                    }
                                                    attributeConfig.filter(x => x.attributeName === attributeName)[0].attributeValue.forEach(x => {
                                                        _.remove(selectedKeyValues, (z) => { return z === x.code })
                                                    });
                                                    this.setState({ attributedisabled: checked, selectedValues: selectedKeyValues, selectAllAttributeList: selectAllAttributeList })
                                                    this.onAttributeDropDownChange(filterdAttributeConfig.attributeName, attributeValues);
                                                }
                                                else {
                                                    attributeConfigData.forEach(x => {
                                                        x.selectedAttributeValue = []
                                                        this.onAttributeDropDownChange("Country", x.selectedAttributeValue);
                                                    });
                                                    this.setState({ attributedisabled: checked, show: true, attributeConfig: attributeConfigData })
                                                    this.updateAttributeValues("Country", [{ "code": "All", "description": "All" }]);
                                                }                                                
                                            }} />
                                        </Stack>
                                    </div>
                                    <br />
                                </div>);
                            }
                            else {
                                attributeItem.push(<div key={'div-attr-' + attributeLabelName} className={"ms-Grid-col ms-sm12 ms-md8 ms-lg6"} style={{ paddingLeft: 0, display: 'contents' }}>
                                    <Dropdown
                                        required={true}
                                        multiSelect={true}
                                        placeholder="--Select--"
                                        label={attributeLabelName}
                                        style={{ height: 32, width: window.innerWidth < 400 ? 250 : 350 }}
                                        options={filterdAttributeConfig.attributeValue.sort((a, b) => (a.code < b.code) ? -1 : 1).map(x => { return { key: x.code, text: this.tenantData?.TenantName == UIConstants.Tenant.MDS ? x.description : x.code } })}
                                        onChange={(event, option) => { this.onMultiSelectDropDownChange(event, option, filterdAttributeConfig.attributeName) }}
                                    />
                                    <br />
                                </div>);
                            }
                            break;
                        case AttributeType.Lookup: // Picker control
                            if (this.tenantData.TenantName == UIConstants.Tenant.FCW) {
                                if (attributeLabelName == UIConstants.Labletext.OperationsDetail) {
                                    if (operationsDetailsLoaded) {
                                        attributeItem.push(<div key={'div-attr-' + attributeLabelName} className={"ms-Grid-col ms-sm12 ms-md8 ms-lg6"} style={{ paddingLeft: 0, display: 'contents' }}>
                                            <Label required={true} styles={labelStyles}>{attributeLabelName}</Label>
                                            <div key='grid-row-attr-lkp'>
                                                <Stack horizontal tokens={{ childrenGap: 10 }}>
                                                    {attributedisabled && <ComboBox
                                                        multiSelect
                                                        disabled={attributedisabled}
                                                        options={this.state.options}
                                                        allowFreeform={true}
                                                        styles={comboBoxStyles}
                                                        onChange={(event, option) => this.onComboboxChange(event, option, filterdAttributeConfig.attributeName)}
                                                    />
                                                    }
                                                    {!attributedisabled && <ComboBox
                                                        multiSelect
                                                        disabled={attributedisabled}
                                                        options={this.state.options}
                                                        allowFreeform={true}
                                                        styles={comboBoxStyles}
                                                        onChange={(event, option) => this.onComboboxChange(event, option, filterdAttributeConfig.attributeName)}
                                                    />
                                                    }

                                                    <span>&nbsp;</span>

                                                    <Checkbox styles={{ checkbox: { height: '20px', width: '20px' } }} onRenderLabel={() => {
                                                        return (<span>
                                                            Select All
                                                        </span>
                                                        );
                                                    }} onChange={(ev, checked) => {
                                                        let attributeConfigData = this.state.attributeConfig;
                                                        attributeConfigData.forEach(x => {
                                                            x.selectedAttributeValue = []
                                                            this.onAttributeDropDownChange("OperationsDetail", x.selectedAttributeValue);
                                                        });
                                                        this.setState({ attributedisabled: checked, show: true, attributeConfig: attributeConfigData })
                                                        if (checked) {
                                                            this.updateAttributeValues(UIConstants.Attribute.OperationsDetail, [{ "code": "All", "description": "All" }]);
                                                        }
                                                        else {
                                                            this.setState({ selectedAttributes: this.state.selectedAttributes.filter(x => x.attributeName != UIConstants.Attribute.OperationsDetail) });
                                                        }
                                                    }} />


                                                </Stack>
                                            </div>
                                            <br />
                                        </div>);
                                    }
                                    else {
                                        return <Spinner size={SpinnerSize.large} label="loading..." ariaLive="assertive" labelPosition="bottom" />;
                                    }
                                }

                                else if (attributeLabelName == UIConstants.Labletext.CompanyCode) {
                                    attributeItem.push(<div key={'div-attr-' + attributeLabelName} className={"ms-Grid-col ms-sm12 ms-md8 ms-lg6"} style={{ paddingLeft: 0, display: 'contents' }}>
                                        <Label required={false} styles={labelStyles}>{attributeLabelName}</Label>
                                        <div key='grid-row-attr-lkp'>
                                            <Stack horizontal tokens={{ childrenGap: 10 }}>
                                                {ccattributedisabled && <AttributePicker setAttributeFocus={setAttributeFocus} roleName={selectedRole.name} attributeName={attributeName}
                                                    isDisabled={ccattributedisabled} onItemSelected={this.onAttributePickerChange}
                                                    tenantData={this.tenantData} itemLimit={policyDelimiter ? 1 : null}
                                                    isAsync={true}
                                                ></AttributePicker>}
                                                {!ccattributedisabled && <AttributePicker setAttributeFocus={setAttributeFocus} roleName={selectedRole.name} attributeName={attributeName}
                                                    isDisabled={ccattributedisabled} onItemSelected={this.onAttributePickerChange}
                                                    tenantData={this.tenantData} itemLimit={policyDelimiter ? 1 : null}
                                                    isAsync={true}
                                                ></AttributePicker>}
                                                <span>&nbsp;</span>

                                                <Checkbox styles={{ checkbox: { height: '20px', width: '20px' } }} onRenderLabel={() => {
                                                    return (<span>
                                                        Select All
                                                    </span>
                                                    );
                                                }} onChange={(ev, checked) => {
                                                    let attributeConfigData = this.state.attributeConfig;
                                                    attributeConfigData.forEach(x => {
                                                        x.selectedAttributeValue = []
                                                        this.onAttributeDropDownChange("Company", x.selectedAttributeValue);
                                                    });
                                                    this.setState({ ccattributedisabled: checked, show: true, attributeConfig: attributeConfigData })
                                                    if (checked) {
                                                        this.updateAttributeValues(UIConstants.Attribute.Company, [{ "code": "All", "description": "All" }]);
                                                    }
                                                    else {
                                                        this.setState({ selectedAttributes: this.state.selectedAttributes.filter(x => x.attributeName != UIConstants.Attribute.Company) });
                                                    }

                                                }} />
                                            </Stack>
                                        </div>
                                        <br />
                                    </div>);
                                }
                            }
                            else {
                                attributeItem.push(<div key={'div-attr-' + attributeLabelName} className={"ms-Grid-col ms-sm12 ms-md8 ms-lg6"} style={{ paddingLeft: 0, display: 'contents' }}>
                                    <Label required={true} styles={labelStyles}>{attributeLabelName}</Label>
                                    <AttributePicker setAttributeFocus={setAttributeFocus} roleName={selectedRole.name} attributeName={attributeName}
                                        isDisabled={false} onItemSelected={this.onAttributePickerChange}
                                        tenantData={this.tenantData} itemLimit={policyDelimiter ? 1 : null}
                                        isAsync={true}
                                    ></AttributePicker>
                                    <br/>
                                </div>);
                            }
                            break;
                        case AttributeType.Tree:
                            const map = new Map();
                            filterdAttributeConfig.attributeValue.forEach(item => {
                                if (!map.has(item.GroupCode)) {
                                    map.set(item.GroupCode, { value: item.GroupCode, label: item.GroupDescription, children: [] });
                                }
                                if (item.code != null) {
                                    map.get(item.GroupCode).children.push({ value: item.code.replace("Parent-", ''), label: item.description });
                                }
                            });

                            let nodes = Array.from(map.values());
                            let attributeCheckedVariable = attributeChecked != null && attributeChecked.length > 0 ? attributeChecked : [];
                            let attributeExpandedVariable = attributeExpanded != null && attributeExpanded.length > 0 ? attributeExpanded : [];
                            let checkedValues = attributeChecked != null && attributeChecked.length > 0 && attributeChecked.filter(x => x.Name == attributeName).length>0 ? attributeChecked.filter(x => x.Name == attributeName)[0].Values : [];
                            let expandedValues = attributeExpanded != null && attributeExpanded.length > 0 && attributeExpanded.filter(x => x.Name == attributeName).length > 0 ? attributeExpanded.filter(x => x.Name == attributeName)[0].Values : [];
                            attributeItem.push(
                                <div key={'div-attr-' + attributeLabelName} className={"ms-Grid-col ms-sm12 ms-md8 ms-lg6"} style={{ paddingLeft: 0, minHeight: 100, maxHeight: 300, overflowY: 'auto', visibility: 'visible' }}>
                                    <Label required={true} styles={labelStyles}>{attributeLabelName}</Label>
                                    <CheckboxTree
                                        nodes={nodes}
                                        checked={checkedValues}
                                        expanded={expandedValues}
                                        onCheck={(checked, node) => {
                                            if (node.checked) {
                                                if (node['isParent']) {
                                                    node.children.forEach(item => {
                                                        if (checkedValues.indexOf(item.value) == -1) {
                                                            checkedValues.push(item.value)
                                                        }
                                                    }); // push all children
                                                    if (checkedValues.indexOf(node.value) == -1) {
                                                        checkedValues.push(node.value) // push parent
                                                    }
                                                } else {
                                                    if (checkedValues.indexOf(node.value) == -1) {
                                                        checkedValues.push(node.value) // push child
                                                    }
                                                }
                                            } else {
                                                if (node['isParent']) {
                                                    const index = checkedValues.indexOf(node.value);
                                                    if (index > -1) {
                                                        checkedValues.splice(index, 1);
                                                        if (checkedValues.indexOf(node.value) == -1) {
                                                            checkedValues.push(node.value + ":Removed") // push child
                                                        }
                                                    }
                                                    node.children.forEach(item => {
                                                        const index = checkedValues.indexOf(item.value);
                                                        if (index > -1) {
                                                            checkedValues.splice(index, 1);
                                                            if (checkedValues.indexOf(item.value) == -1) {
                                                                checkedValues.push(item.value + ":Removed") // push child
                                                            }
                                                        }
                                                    });
                                                } else {
                                                    const index = checkedValues.indexOf(node.value);
                                                    if (index > -1) {
                                                        checkedValues.splice(index, 1);
                                                        if (checkedValues.indexOf(node.value) == -1 && this.state.editRequest) {
                                                            checkedValues.push(node.value + ":Removed") // push child
                                                        }
                                                    }
                                                }
                                            }
                                            if (attributeCheckedVariable.filter(x => x.Name == attributeName).length > 0) {
                                                attributeCheckedVariable[attributeName] = checkedValues
                                            }
                                            else {
                                                let newValues: CheckBoxTreeModel = {
                                                    Name: attributeName,
                                                    Values: checkedValues
                                                };
                                                attributeCheckedVariable.push(newValues);
                                            }
                                            this.setState({ attributeChecked: attributeCheckedVariable })
                                        }}
                                        onExpand={(expanded, node) => {
                                            if (node.expanded) {
                                                expandedValues.push(node.value);
                                            } else {
                                                const index = expandedValues.indexOf(node.value);
                                                if (index > -1) {
                                                    expandedValues.splice(index, 1);
                                                }
                                            }
                                            if (attributeExpandedVariable.filter(x => x.Name == attributeName).length > 0) {
                                                attributeExpandedVariable[attributeName] = expandedValues
                                            }
                                            else {
                                                let newValues: CheckBoxTreeModel = {
                                                    Name: attributeName,
                                                    Values: expandedValues
                                                };
                                                attributeExpandedVariable.push(newValues);
                                            }
                                            this.setState({ attributeExpanded: attributeExpandedVariable })
                                        }}
                                        nativeCheckboxes={true}
                                        icons={{
                                            expandClose: <i className="fa fa-plus-square" aria-hidden="true"></i>,
                                            expandOpen: < i className="fa fa-minus-square" aria-hidden="true"></i>,
                                            parentClose: null,
                                            parentOpen: null,
                                            leaf: null
                                        }}
                                    />
                                </div>);
                            break;
                        default:
                            break;
                    }

                } else {

                }
            }
            return (attributeItem);
        } else {
            return <Spinner size={SpinnerSize.large} label="loading..." ariaLive="assertive" labelPosition="bottom" />;
        }

    };
    private renderScope = () => {
        let { selectedRole, scope } = this.state;
        let element = [];
        if (selectedRole.scopes && selectedRole.scopes.length > 1) {
            element.push(<div key={'div-scope' + selectedRole.id}>
                <Label required={true} styles={labelStyles}>{UIConstants.Labletext.RoleType}</Label>
                <select ref={this.scopeRef} id={'drpattr-scope-' + selectedRole.id}
                    title={UIConstants.Labletext.RoleType} required={true}
                    onChange={this.onScopeDropDownChange}
                    style={{ height: 32, width: window.innerWidth < 400 ? 250 : 350 }} >
                    <option value="0">-- Select --</option>
                    {selectedRole.scopes.map(x => { return <option key={'drpattr-scope-' + x} value={x}>{x}</option> })}
                </select>
            </div>)
        }
        return (element);
    };
    private renderMessage = (message: string, messageType: MessageBarType) => {
        setTimeout(() => {
            this.alertMsgCloseBtnRef.current.focus();
        }, 100) // set the focus to close button
        return <div>
            <MessageBar key='FetchMsg'
                actions={
                    <div>
                        <MessageBarButton componentRef={this.alertMsgCloseBtnRef} onClick={() => { this.setState({ showMessage: false }) }} >{UIConstants.ButtonText.Ok}</MessageBarButton>
                    </div>
                }
                messageBarType={messageType}
                isMultiline={false}
            >
                <span style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}>{message}</span>
            </MessageBar>
        </div>
    };
    private renderMessage2 = () => {
        let { message, messageType, autoHideMessage } = this.state;

        if (autoHideMessage)
            setTimeout(() => { this.setState({ showMessage: false }) }, 3000); // hide message after 3 seconds
        if (this.tenantData.TenantName != UIConstants.Tenant.MSSales && !this.tenantData?.isExternalAADTenant) {
            setTimeout(() => {
                this.alertMsg2CloseBtnRef.current.focus();
            }, 100) // set the focus to close button
        }
        return <div role="alert" >
            <MessageBar key='SaveMsg' style={{ fontSize: '.875rem' }}
                actions={
                    <div>
                        <IconButton componentRef={this.alertMsg2CloseBtnRef} role="button" iconProps={{ iconName: 'Clear' }} title="Close" onClick={() => { this.setState({ showMessage: false, setAttributeFocus: false }) }} />
                    </div>
                }
                messageBarType={messageType}
                isMultiline={false}
            >
                <span style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}>{message}</span>
            </MessageBar>
        </div>
    };
    private renderAccessTypeDescription = () => {
        const { attributeDescList } = this.state;
        return <React.Fragment>
            <div id="attr-desc-tbl" className='attr-desc-sec' >
                <br />
                <Label styles={labelStyles}>Access Type Description</Label>
                <table style={{ width: '100%' }}>
                    <tbody>
                        <tr>
                            <th style={{ width: '13%' }}>Role</th>
                            <th style={{ width: '12%' }}>Access Type</th>
                            <th style={{ width: '60%' }}>Description</th>
                            <th style={{ width: '15%' }}>{attributeDescList[0].ApplicableFor ? "Applicable for" : "Notes"}</th>
                        </tr>
                        {attributeDescList.map(x => {
                            return <React.Fragment key={'attr-' + x.Name}>
                                <tr>
                                    <td>{x.Role}</td>
                                    <td> {x.Name}</td>
                                    <td><Text>{x.Description}</Text></td>
                                    <td><Text>{x.ApplicableFor ?? x.Notes}</Text></td>
                                </tr>
                            </React.Fragment>
                        })}

                    </tbody>
                </table>

            </div>
        </React.Fragment>;
    }
    private renderAccess = () => {
        const { selectedBGName, enableRoleGrouping, isMSTTenant, mstRoleGroupConfig, selectedBusinessGroupRole, selectedGroup, vlAppsRoleGroupConfig, selectedRole, hasBulkUploadAccess, isRequestModeBulk, RoleDetails, requestorPrincipalId } = this.state;
        return (
            <React.Fragment>
                <div key="grid-request" className={"ms-Grid"} dir="ltr">
                    <div key='grid-row-role' className={"ms-Grid-row"}>
                        <div className={"ms-Grid-col ms-sm12 ms-md8 ms-lg12"}>
                            <div>
                                <Label required={true} styles={labelStyles}>{UIConstants.Group}</Label>
                                <select id={'drpGrp'} value={selectedGroup}
                                    title={UIConstants.Group} required={true}
                                    onChange={this.onBusinessGroupChange}
                                    style={{ height: 32, width: window.innerWidth < 400 ? 250 : 350 }}>
                                    <option value="0">-- Select --</option>
                                    {isMSTTenant && mstRoleGroupConfig.map(x => { return <option key={'drpgrp' + x.GroupId} aria-label={x.GroupName} value={x.key}>{x.GroupName}</option> })}
                                    {enableRoleGrouping && vlAppsRoleGroupConfig.IsDataLoaded && this.getBusinessGroup()?.map(x => { return <option key={'drpgrp' + x.key} aria-label={x.text} value={x.key}>{x.text}</option> })}
                                    {this.tenantData?.isExternalAADTenant && this.getExternalAADBGs()?.map(x => { return <option key={'drpgrp' + x.key} aria-label={x.text} value={x.key}>{x.text}</option> })}
                                </select>
                            </div>
                        </div>
                    </div>
                    {(enableRoleGrouping || this.tenantData?.isExternalAADTenant) &&
                        <div key='grid-row-role' className={"ms-Grid-row"}>
                            <div className={"ms-Grid-col ms-sm12 ms-md8 ms-lg12"}>
                                <div>
                                    <Label required={true} styles={labelStyles}>{UIConstants.Role}</Label>
                                    <select id={'drpRole'} value={selectedBusinessGroupRole} disabled={isRequestModeBulk}
                                        title={UIConstants.Role} required={true}
                                        onChange={this.onGroupspecificRoleDropDownChange}
                                        style={{ height: 32, width: window.innerWidth < 400 ? 250 : 350 }}>
                                        <option value="0">-- Select --</option>
                                        {enableRoleGrouping && this.getGroupSpecificRolesForVlApps()?.map(x => { return <option key={'drpRole' + x.key} aria-label={x.text} value={x.key}>{x.text}</option> })}
                                        {this.tenantData?.isExternalAADTenant && this.getGroupSpecificExternalAADRoles()?.map(x => { return <option key={'drpRole' + x.key} aria-label={x.text} value={x.key}>{x.text}</option> })}
                                    </select>
                                    <br />
                                    <Text> {(selectedRole.description != '') ? '(' + selectedRole.description + ')' : <br />}</Text>
                                </div>
                                {this.tenantData?.isExternalAADTenant && hasBulkUploadAccess && <div>
                                    <ChoiceGroup selectedKey={this.state.selectedKey} options={businessGroupRadio} onChange={this.onRequestModeChange} label="Request Mode" styles={{
                                        flexContainer: [
                                            {
                                                display: "inline-flex",
                                                alignItems: "left",
                                                paddingBottom: '0px',
                                                selectors: {
                                                    ".ms-ChoiceField": {
                                                        paddingLeft: '0px', paddingBottom: '5px', paddingTop: '0px'
                                                    }
                                                }
                                            }
                                        ]
                                    }} required={true} />
                                    {(isRequestModeBulk) && this.renderFileUploadControl()}
                                </div>}
                                {this.tenantData?.isExternalAADTenant && RoleDetails.IsDataLoaded && (selectedRole.name !== UIConstants.BusinessOwner && selectedBGName != UIConstants.Global_Roles) && (!isRequestModeBulk) && (requestorPrincipalId == '') && this.renderAllowedAccountTypes()}
                            </div>
                            {this.renderEditAccess()}
                        </div>}
                </div>
            </React.Fragment>
        );
    }
    private renderEditAccess = () => {
        const { tenantData, cyclesChecked, alfredCyclesNodes, alfredCycles, saveInProgress, hideDialog, Justification, Signature, renderSkiplevel, RoleDescription, selectedRole, ManagerAlias, displayApprovedBasicExistsMessage, approvedaccess, displayApprovedExistsMessage, isTermsAgreed, attestedList, defaultReportingSubsidiariesConfig, isAlternateManager, subsidiariesConfig, subsidiaryChecked, subsidiaryExpanded, businessConfig, businessChecked, businessExpanded, isAlternateManagerChecked, pickerCtrlKey, approvedMSSalesAccess, displayPendingBasicExistsMessage, displayPendingExistsMessage, isRequestModeBulk } = this.state;
        return (
            <React.Fragment>
                <div key="grid-request" className={"ms-Grid"} dir="ltr">
                    <div key='grid-row-role' className={"ms-Grid-row"}>
                        <div className={"ms-Grid-col ms-sm12 ms-md8 ms-lg12"}>
                            {(!this.state.enableRoleGrouping && !this.tenantData?.isExternalAADTenant) &&
                                <div style={{ display: "inline-block" }}>
                                    <Label required={true} styles={labelStyles}>{UIConstants.Role}</Label>
                                    <select id={'drpRole'} disabled={this.isRoleDrpDwnDisabled()} value={selectedRole.id}
                                        title={UIConstants.Role} required={true}
                                        onChange={(event) => this.onRoleDropDownChange(event.target.value)}
                                        style={{ height: 32, width: window.innerWidth < 400 ? 250 : 350 }}>
                                        <option value="0">-- Select --</option>
                                        {!this.state.isMSTTenant && !this.state.enableRoleGrouping && !this.tenantData?.isExternalAADTenant && this.getRoles().map(x => { return <option key={'drpRole' + x.key} aria-label={x.text + ' ' + RoleDescription} value={x.key}>{x.text}</option> })}
                                        {(this.state.isMSTTenant) && this.getGroupSpecificRoles().map(x => { return <option key={'drpRole' + x.key} aria-label={x.text + ' ' + RoleDescription} value={x.key}>{x.text}</option> })}
                                        {(this.state.enableRoleGrouping) && this.getGroupSpecificRolesForVlApps().map(x => { return <option key={'drpRole' + x.key} aria-label={x.text + ' ' + RoleDescription} value={x.key}>{x.text}</option> })}
                                    </select>
                                    {approvedaccess.length == 0 && <><br />
                                        <Text> {(RoleDescription) && '(' + RoleDescription + ')'}</Text></>}
                                    <br />
                                </div>}                            
                            {(this.state.quizUrl != null && selectedRole.id != '0' && selectedRole?.permissions != null && selectedRole?.permissions.length > 0 && selectedRole?.permissions[0]?.actions.length > 0 && selectedRole?.permissions[0]?.actions.filter(x => x.includes('QuizLink:')).length > 0) &&
                                <div id="quiz" style={{ display: "inline-block", verticalAlign: "top", margin: "29px 0px 0px 30px" }}>
                                    <Label styles={labelStyles}>
                                        Required: 
                                        {selectedRole?.permissions[0]?.actions.filter(x => x.includes('QuizLink:')).map((quizLink, index, array) => {
                                            // Define custom quiz labels
                                            let quizLabel = '';
                                            if (quizLink.split(':')[0].includes('Finance')) {
                                                quizLabel = 'MDS Tool Quiz';
                                            } else if (quizLink.split(':')[0].includes('Revenue')) {
                                                quizLabel = 'Pfam Governance Quiz (Revenue Only)';
                                            }
                                            else {
                                                quizLabel = 'Launch Quiz';
                                            }
                                            return (
                                                <span key={index}>
                                                    <Link target="_blank" href={this.state.quizUrl.replace('#quizendpoint#', quizLink.split(':')[1])}>
                                                        {quizLabel}
                                                    </Link>
                                                    {index < array.length - 1 && ' / '}
                                                </span>
                                            );
                                        })}
                                    </Label>
                                </div>
                            }
                            {/*{displayApprovedBasicExistsMessage && !displayPendingBasicExistsMessage && <div className={"ms-Grid-row"} hidden={this.tenantData?.TenantName != UIConstants.Tenant.MSSales} style={{ paddingLeft: 10, paddingTop: 10 }}>*/}
                            {/*    <span><Text style={{ fontWeight: "bold" }}> {'You already have approved access to this role, please click '}</Text></span><span><Link onClick={() => this.prePopulateMSSalesAccess(approvedMSSalesAccess.filter(x => x.attributes != null && x.attributes.DefaultReportingSubsidiary != null), subsidiariesConfig, businessConfig)} style={{ fontWeight: "bold", fontFamily: "Segoe UI" }}>{'here'} </Link></span><span><Text style={{ fontWeight: "bold" }}> {'to modify your permissions and initiate an update request.'} </Text></span>*/}
                            {/*</div>}*/}
                            {/*{displayApprovedExistsMessage && <div className={"ms-Grid-row"} hidden={this.tenantData?.TenantName != UIConstants.Tenant.MSSales} style={{ paddingLeft: 10, paddingTop: 10 }}>*/}
                            {/*    <Text style={{ fontWeight: "bold" }}> {'You already have approved access to this role.'} </Text>*/}
                            {/*</div>}*/}
                            {(displayPendingBasicExistsMessage || displayApprovedBasicExistsMessage || displayPendingExistsMessage || displayApprovedExistsMessage) && <div className={"ms-Grid-row"} hidden={this.tenantData?.TenantName != UIConstants.Tenant.MSSales} style={{ paddingLeft: 10, paddingTop: 10 }}>
                                <Text style={{ fontWeight: "bold" }}> {'You already have access to this Role. Please click "View/Edit My Access" for more details.'} </Text>
                            </div>}
                            {/*{((displayPendingBasicExistsMessage && !displayApprovedBasicExistsMessage) || (displayPendingExistsMessage)) && <div className={"ms-Grid-row"} hidden={this.tenantData?.TenantName != UIConstants.Tenant.MSSales} style={{ paddingLeft: 10, paddingTop: 10 }}>*/}
                            {/*    <Text style={{ fontWeight: "bold" }}> {'You already have a pending access request access to this role, please click on "View/Edit My Access" to track the status.'} </Text>*/}
                            {/*</div>}*/}
                        </div>
                    </div>
                    {(!this.state.editRequest || (this.state.editRequest && this.tenantData?.TenantName == UIConstants.Tenant.MDS)) && < div key='grid-row-attr' className={"ms-Grid-row"} hidden={this.state.hideAttributes}>
                        <div className={"ms-Grid-col ms-sm12 ms-md8 ms-lg12"}>
                            {this.renderScope()}
                            <div hidden={this.state.hideAttributes}>
                                {this.renderAttributeValues()}
                            </div>
                        </div>
                    </div>}

                    {(this.state.enableRequestOnBehalf) &&
                        <div key='grid-row-requestor' className={"ms-Grid-row"}>
                            <div className={"ms-Grid-col ms-sm12 ms-md8 ms-lg12"}>
                                {(this.tenantData?.isExternalAADTenant && (!isRequestModeBulk) || !this.tenantData?.isExternalAADTenant) && this.renderOnbehalfCtrl()}
                            </div>
                        </div>}

                    {this.state.isPartnerCenterTenant && this.state.selectedAttributes.length > 0
                        && this.state.selectedAttributes.filter(x => x.attributeName == "Region")[0] &&
                        <Text>
                            {'(' + this.state.selectedAttributes.filter(x => x.attributeName == "Region")[0]?.attributeValues[0].description + ')'}
                        </Text>}

                    <div style={{ marginTop: '5px' }}>{(this.state.tenantData.TenantName.toLowerCase() == UIConstants.Tenant.Alfred.toLowerCase()) && !this.state.fetchingFiscalCycles ?
                        (alfredCyclesNodes.length > 0 ?
                            <div style={{ width: 'fit-content', paddingLeft: 0, minHeight: 100, maxHeight: 300, overflowY: 'auto', visibility: 'visible' }}>
                                <Label required={true} styles={labelStyles}>Cycles</Label>
                                <CheckboxTree
                                    nodes={alfredCyclesNodes}
                                    checked={cyclesChecked}
                                    onCheck={(checked, node) => {
                                        if (node.checked) {
                                            let attributes;
                                            cyclesChecked.push(node.value);
                                            if (this.state.selectedAttributes.length > 0) {
                                                if (this.state.selectedAttributes[0].attributeValues.findIndex(x => x.code == node.value) != -1) {
                                                    this.state.selectedAttributes[0].attributeValues.find(x => x.code == node.value).isRemove = false;
                                                    attributes = this.state.selectedAttributes[0].attributeValues
                                                }
                                                else {
                                                    this.state.selectedAttributes[0].attributeValues.push({ code: node.value, description: alfredCyclesNodes.find(x => x.value == node.value).label.split(':')[1].trim(), isRemove: false });
                                                    attributes = this.state.selectedAttributes[0].attributeValues;
                                                }
                                            }
                                            else {
                                                attributes = [{ code: node.value, description: alfredCyclesNodes.find(x => x.value == node.value).label.split(':')[1].trim(), isRemove: false }]
                                            }
                                            this.updateAttributeValues('Fiscal Cycles', attributes)
                                        }
                                        else {
                                            let index = cyclesChecked.indexOf(node.value);
                                            if (index != -1) {
                                                cyclesChecked.splice(index, 1)
                                            }
                                            if (this.state.approvedAlfredCycles.findIndex(x => x == node.value) == -1) {
                                                this.state.selectedAttributes[0].attributeValues.splice(this.state.selectedAttributes[0].attributeValues.findIndex(y => y.code == node.value), 1)
                                            }
                                            else {
                                                this.state.selectedAttributes[0].attributeValues.find(x => x.code == node.value).isRemove = true;
                                                this.updateAttributeValues('Fiscal Cycles', this.state.selectedAttributes[0].attributeValues)
                                            }
                                            this.setState({ cyclesChecked: cyclesChecked });
                                        }
                                    }}
                                    nativeCheckboxes={true}
                                    icons={{
                                        parentClose: null,
                                        parentOpen: null,
                                        leaf: null
                                    }}
                                />
                            </div> : null)
                        : this.state.tenantData.TenantName.toLowerCase() == UIConstants.Tenant.Alfred.toLowerCase() ? <div style={{ display: 'flex', marginLeft: '130px' }}><Spinner size={SpinnerSize.large} label="loading..." ariaLive="assertive" labelPosition="bottom" /></div> : null
                    }</div>
                    <div key='grid-row-bj' className={"ms-Grid-row"} hidden={this.state.hideBj}>
                        <div className={"ms-Grid-col ms-sm12 ms-md8 ms-lg8"}>
                            <div hidden={this.state.showMSSalesTermsAndConditions || displayApprovedExistsMessage || displayPendingExistsMessage}>
                                <TextField
                                    componentRef={this.refBJ}
                                    styles={{ root: { marginTop: 10 } }} label={UIConstants.BusinessJustfication} multiline rows={3} maxLength={500} value={Justification}
                                    onChange={this.onJustificationChange} required
                                    description={(500 - Justification.length) + ' / 500 Characters left'}
                                />
                            </div>

                            <div style={{ marginTop: 10 }}>{this.state.renderAttestation &&
                                <div>{((this.state.tenantData.TenantName == UIConstants.Tenant.Alfred && alfredCyclesNodes.length > 0) || (this.state.tenantData.TenantName == UIConstants.Tenant.MDS)) ?
                                    <div>
                                        <Label required={true} styles={labelStyles}>Terms & Conditions</Label>
                                        <div style={{ display: 'inline-flex', "alignItems": 'center' }}>
                                            <span><Checkbox styles={{ root: { width: 20 } }} checked={attestedList.indexOf('responsibiltyAttestation') != -1} onChange={(ev, checked) => { this.onTermsChange(checked, 'responsibiltyAttestation') }} />
                                            </span><span><Label style={{ fontWeight: "normal" }}><span>
                                                &nbsp;I understand and will execute my ressponsibilities as a Data Steward. These responsibilities are documented here: &nbsp;<Link href="https://msit.microsoftstream.com/video/dee60840-98dc-997c-23a2-f1eca40f20bc?referrer=https:%2F%2Fmicrosoftit.visualstudio.com%2F" target="_blank" underline>Data Steward Overview</Link>.
                                            </span></Label>
                                            </span>
                                        </div>
                                        <div style={{ display: 'inline-flex', "alignItems": 'center' }}>
                                            <span><Checkbox styles={{ root: { width: 20 } }} checked={attestedList.indexOf('onboardingCo-ordinationAttestation') != -1} onChange={(ev, checked) => { this.onTermsChange(checked, 'onboardingCo-ordinationAttestation') }} />
                                            </span><span><Label style={{ fontWeight: "normal" }}><span>
                                                &nbsp;I will coordinate onboarding a new Data Steward should I no longer hold this responsibility and inform FDM of this replacement.</span></Label>
                                            </span>
                                        </div>
                                    </div>
                                    : null}
                                </div>
                            }
                            </div>
                            {renderSkiplevel &&
                                <div className={"ms-Grid-col ms-sm12 ms-md8 ms-lg12"} style={{ display: 'inline-flex', "alignItems": 'baseline', float: 'left', height: "50px !important" }}>
                                    <span>
                                        <Checkbox styles={{ root: { paddingBottom: 10, width: 20, marginTop: 4 } }} checked={isAlternateManagerChecked}
                                            onChange={(ev, checked) => { this.setState({ isAlternateManagerChecked: checked, allowSkipLevel: checked }) }} />
                                    </span>
                                    <span>
                                        <Label style={{ fontWeight: "normal" }}>
                                            &nbsp; Use skip level as the alternate manager:  <span style={{ color: '#0078d4' }}> {ManagerAlias ? ManagerAlias?.name?.split(' (')[0] + " (" + ManagerAlias?.upn?.split('@')[0] + ")" : <Spinner size={SpinnerSize.small} ariaLive="assertive" style={{ display: 'inline-flex' }} />}</span>
                                        </Label>
                                    </span>
                                    <br />
                                </div>
                            }
                            <div id="karnak-grp-config">
                                {(this.state.showKarnakAttributeDesc) && this.renderAccessTypeDescription()}
                            </div>
                            <div key="render-alternate-manager" className={"ms-Grid-col ms-sm12 ms-md8 ms-lg12"} hidden={!this.state.renderAlternateApprover}>
                                <div>
                                    <Label style={{ fontWeight: "normal", marginTop: 10, width: '100%' }}>
                                        Your request will be routed to your manager for review
                                        <br />
                                        &nbsp;&nbsp;&nbsp;Manager alias for approval: <span style={{ color: "#0064BF", fontWeight: "bold", fontSize: 13 }}>{this.state.ManagerAlias.upn.split('@')[0]}</span>
                                        <br />
                                        &nbsp;&nbsp;&nbsp;Manager name: <span style={{ color: "#0064BF", fontWeight: "bold", fontSize: 13 }}>{this.state.ManagerAlias?.name?.split('(')[0]}</span>
                                        <br />
                                    </Label>
                                    {ManagerAlias && (ManagerAlias.jobTitle != null && ManagerAlias.jobTitle.toUpperCase() == 'CEO' && <div style={{ color: 'red', fontSize: 13, width: '100%', float: 'right', marginBottom: '2px' }}>{UIConstants.Messages.preventedRoutetoCEO}</div>)}
                                    {(isAlternateManager && isAlternateManager != '' && ManagerAlias && (ManagerAlias.jobTitle != null && ManagerAlias.jobTitle.toUpperCase() != 'CEO')) && <div style={{ color: 'red', fontSize: 13, width: '100%', float: 'right', marginBottom: '2px' }}>{isAlternateManager}</div>}
                                </div>
                                <div style={{ display: 'inline-flex', "alignItems": 'baseline' }}>
                                    <span>
                                        <Checkbox styles={{ root: { paddingBottom: 10, width: 20, marginTop: 4 } }} checked={isAlternateManagerChecked}
                                            onChange={(ev, checked) => { this.setState({ isAlternateManagerChecked: checked, isAlternateManager: '', alternateManagerAlias: '', pickerCtrlKey: this.state.pickerCtrlKey + 1 }) }} />
                                    </span>
                                    <span>
                                        <Label style={{ fontWeight: "normal" }}>
                                            &nbsp;  Select an alternate FTE approver for request review:  &nbsp;
                                        </Label>
                                    </span>
                                    <span>
                                        {!isAlternateManagerChecked ? <TextField styles={{ root: { width: 275 }, field: { height: 38 } }} placeholder={"Search for an alternate FTE approver"} disabled={!this.state.isAlternateManagerChecked} /> :
                                            <div style={{ width: 275 }}><PeoplePicker
                                                key={pickerCtrlKey}
                                                type={PeoplePickerType.Normal}
                                                contentType={PeoplePickerContentType.FTEUser}
                                                selectionMode={PeoplePickerSelectionMode.Single}
                                                onchange={(items) => { this.onPeoplePickerChange(items) }}
                                                defaultSelectedItems={[]}
                                                {...this.props}
                                                setFocusOnPicker={false}
                                                hideDirectFTE={false}
                                            ></PeoplePicker></div>}
                                    </span>
                                </div>
                            </div>
                            <div hidden={!this.state.showTermsAndConditions}>
                                <br />
                                <Label required={true} styles={labelStyles}>Terms & Conditions</Label>
                                <Label style={{ fontWeight: "normal" }}>(All of the below terms & conditions requires acceptance to submit this access request)</Label>
                                <Checkbox styles={{ root: { paddingBottom: 10 } }} onRenderLabel={() => {
                                    return (<span>
                                        I am aware that elevated access is <span style={{ fontWeight: "bold", textDecoration: "underline" }}>not </span> required when I am the PO requester on POs in MyOrder, as I can now make changes to these POs.
                                    </span>
                                    );
                                }} checked={attestedList.indexOf('elevatedAccess') != -1} onChange={(ev, checked) => { this.onTermsChange(checked, 'elevatedAccess') }} />

                                <Checkbox styles={{ root: { paddingBottom: 10 } }} onRenderLabel={() => {
                                    return (<span>
                                        I attest that I am requesting this access because I will be making updates on more than 50 POs in a year on behalf of others. Lower quantity of POs can be managed by P2P support at Get Help [FDA].
                                    </span>
                                    );
                                }} checked={attestedList.indexOf('morePOUpdates') != -1} onChange={(ev, checked) => { this.onTermsChange(checked, 'morePOUpdates') }} />
                                <Checkbox styles={{ root: { paddingBottom: 10 } }} onRenderLabel={() => {
                                    return (<span>
                                        I attest to use this elevated access to only make changes on POs related to my organization and role.
                                    </span>
                                    );
                                }} checked={attestedList.indexOf('myOrgPOAndRoleOnly') != -1} onChange={(ev, checked) => { this.onTermsChange(checked, 'myOrgPOAndRoleOnly') }} />
                                <Checkbox styles={{ root: { paddingBottom: 10 } }} onRenderLabel={() => {
                                    return (<span>
                                        I am aware that both Audit and Controls & Compliance review purchasing and spend at Microsoft, as such, my updates are subject to review and are required to be compliant to MS Policy. Policies include, but are not limited to: <Link href="https://microsoft.sharepoint.com/sites/MS_Policy/Pages/Policy.aspx?policyid=MSPOLICY-2134984086-31" target="_blank" underline>
                                            Procurement
                                        </Link>,  <Link href="https://microsoft.sharepoint.com/sites/ms_policy/Pages/Policy.aspx?policyid=MSPOLICY-2134984086-9" target="_blank" underline>
                                            Global Payout
                                        </Link>, and <Link href="https://microsoft.sharepoint.com/sites/MS_Policy/Pages/Policy.aspx?policyid=MSPOLICY-2134984086-19" target="_blank" underline>
                                            Purchasing
                                        </Link>,  <Link href="https://www.microsoft.com" target="_blank" underline>
                                            Disbursements and Expense Reimbursements Authority
                                        </Link>.
                                    </span>
                                    );
                                }} checked={attestedList.indexOf('audit') != -1} onChange={(ev, checked) => { this.onTermsChange(checked, 'audit') }} />
                                <Checkbox styles={{ root: { paddingBottom: 10 } }} onRenderLabel={() => {
                                    return (<span>
                                        I am aware that I will need to re-attest annually and accept that the access can be revoked at any time.
                                    </span>
                                    );
                                }} checked={attestedList.indexOf('reAttest') != -1} onChange={(ev, checked) => { this.onTermsChange(checked, 'reAttest') }} />
                                <Checkbox styles={{ root: { paddingBottom: 10 } }} onRenderLabel={() => {
                                    return (<span>
                                        I declare I will request to revoke my access in the case this access is no longer applicable/required.
                                    </span>
                                    );
                                }} checked={attestedList.indexOf('revokeIfNotApplicable') != -1} onChange={(ev, checked) => { this.onTermsChange(checked, 'revokeIfNotApplicable') }} />
                                <Checkbox styles={{ root: { paddingBottom: 10 } }} onRenderLabel={() => {
                                    return (<span>
                                        I am aware of and consent to the email notification that will be sent to my manager notifying about the access change.
                                    </span>
                                    );
                                }} checked={attestedList.indexOf('emailToManager') != -1} onChange={(ev, checked) => { this.onTermsChange(checked, 'emailToManager') }} />
                                <br />
                            </div>
                            <div key="grid-request" className={"ms-Grid"} dir="ltr" hidden={!this.state.showMSSalesTermsAndConditions || displayApprovedBasicExistsMessage || displayPendingBasicExistsMessage}>
                                {this.state.showSubsidiary && <>
                                    <div key={'div-attr-' + 'Default Reporting Subsidiary'}>
                                        <Label required={true} styles={labelStyles}>{'Default Reporting Subsidiary'}</Label>
                                        <select id={'div-attr-' + 'Default Reporting Subsidiary'} value={this.state.selectedDefaultReportingSubsidiary}
                                            title={'Default Reporting Subsidiary'} required={true}
                                            onChange={(event) => {
                                                let val = event.target.value;
                                                this.setState({ selectedDefaultReportingSubsidiary: event.target.value });
                                                let attrValue = [{ code: val, description: val, id: Number.parseInt(subsidiariesConfig.find(x => x.children.find(y => y.label.replace(/'/g, '') == val.replace(/'/g, '')))?.children.find(x => x.label.replace(/'/g, '') == val.replace(/'/g, '')).value) }];
                                                this.onAttributeDropDownChange('Default Reporting Subsidiary', attrValue)
                                            }}
                                            style={{ height: 32, width: window.innerWidth < 400 ? 250 : 350 }} >
                                            <option value="0">-- Select --</option>
                                            {defaultReportingSubsidiariesConfig.map(x => { return <option key={'drpattr-' + x} value={x}>{x}</option> })}
                                        </select>
                                    </div>
                                    <div key='selection-lbl-row' className={"ms-Grid-row"} style={{ paddingBottom: 5 }}>
                                        <div className={"ms-Grid-col ms-sm12 ms-md8 ms-lg6"}>
                                            <Label required styles={{ root: { marginTop: 7, fontSize: 17 } }}>Subsidiary Selection</Label>
                                            <Text style={{ fontStyle: 'italic' }}>(Area / Subsidiary)</Text></div>
                                        <div className={"ms-Grid-col ms-sm12 ms-md8 ms-lg6"} style={{ paddingLeft: 0 }}>
                                            <Label required styles={{ root: { marginTop: 7, fontSize: 17 } }}>Business Selection</Label>
                                            <Text style={{ fontStyle: 'italic' }}>(Business Group / Business)</Text></div>
                                    </div>
                                    <div key='tree-ctrl-row' className={"ms-Grid-row"} style={{ paddingLeft: 0, minHeight: 100, maxHeight: 300, overflowY: 'auto', visibility: 'visible' }}>
                                        <div className={"ms-Grid-col ms-sm12 ms-md8 ms-lg6"}>
                                            <Checkbox styles={{ checkbox: { height: '13px', width: '13px' } }} onRenderLabel={() => {
                                                return (<span>
                                                    Select All
                                                </span>
                                                );
                                            }} onChange={(ev, checked) => {
                                                let selectedSubsidiary = [];
                                                if (checked) {
                                                    this.state.subsidiariesConfig.forEach(x => {
                                                        selectedSubsidiary.push(x.value)
                                                        x.children?.forEach(y => {
                                                            selectedSubsidiary.push(y.value);
                                                        });
                                                    });
                                                }
                                                else selectedSubsidiary = [];

                                                this.setState({ subsidiaryChecked: selectedSubsidiary })
                                            }}
                                            />
                                            <CheckboxTree
                                                nodes={subsidiariesConfig}
                                                checked={subsidiaryChecked}
                                                expanded={subsidiaryExpanded}
                                                onCheck={(checked, node) => {
                                                    if (node.checked) {
                                                        if (node['isParent']) {
                                                            node.children.forEach(item => {
                                                                if (subsidiaryChecked.indexOf(item.value) == -1) {
                                                                    subsidiaryChecked.push(item.value)
                                                                }
                                                            }); // push all children
                                                            if (subsidiaryChecked.indexOf(node.value) == -1) {
                                                                subsidiaryChecked.push(node.value) // push parent
                                                            }
                                                        } else {
                                                            if (subsidiaryChecked.indexOf(node.value) == -1) {
                                                                subsidiaryChecked.push(node.value) // push child
                                                            }
                                                        }
                                                    } else {
                                                        if (node['isParent']) {
                                                            const index = subsidiaryChecked.indexOf(node.value);
                                                            if (index > -1) {
                                                                subsidiaryChecked.splice(index, 1);
                                                            }
                                                            node.children.forEach(item => {
                                                                const index = subsidiaryChecked.indexOf(item.value);
                                                                if (index > -1) {
                                                                    subsidiaryChecked.splice(index, 1);
                                                                }
                                                            });
                                                        } else {
                                                            const index = subsidiaryChecked.indexOf(node.value);
                                                            if (index > -1) {
                                                                subsidiaryChecked.splice(index, 1);
                                                            }
                                                        }
                                                    }
                                                    this.setState({ subsidiaryChecked: subsidiaryChecked })
                                                }}
                                                onExpand={(expanded, node) => {
                                                    if (node.expanded) {
                                                        subsidiaryExpanded.push(node.value);
                                                    } else {
                                                        const index = subsidiaryExpanded.indexOf(node.value);
                                                        if (index > -1) {
                                                            subsidiaryExpanded.splice(index, 1);
                                                        }
                                                    }
                                                    this.setState({ subsidiaryExpanded: subsidiaryExpanded })
                                                }}
                                                nativeCheckboxes={true}
                                                icons={{
                                                    expandClose: <i className="fa fa-plus-square" aria-hidden="true"></i>,
                                                    expandOpen: < i className="fa fa-minus-square" aria-hidden="true"></i>,
                                                    parentClose: null,
                                                    parentOpen: null,
                                                    leaf: null
                                                }}
                                            />
                                        </div>
                                        <div className={"ms-Grid-col ms-sm12 ms-md8 ms-lg6"}>
                                            <Checkbox styles={{ checkbox: { height: '13px', width: '13px' } }} onRenderLabel={() => {
                                                return (<span>
                                                    Select All
                                                </span>
                                                );
                                            }} onChange={(ev, checked) => {
                                                let selectedBusiness = [];
                                                if (checked) {
                                                    this.state.businessConfig.forEach(x => {
                                                        selectedBusiness.push(x.value);
                                                        x.children?.forEach(y => {
                                                            selectedBusiness.push(y.value);
                                                        });
                                                    });
                                                }
                                                else selectedBusiness = [];

                                                this.setState({ businessChecked: selectedBusiness })
                                            }}
                                            />
                                            <CheckboxTree
                                                nodes={businessConfig}
                                                checked={businessChecked}
                                                expanded={businessExpanded}
                                                onCheck={(checked, node) => {
                                                    if (node.checked) {
                                                        if (node['isParent']) {
                                                            node.children.forEach(item => {
                                                                if (businessChecked.indexOf(item.value) == -1) {
                                                                    businessChecked.push(item.value)
                                                                }
                                                            }); // push all children
                                                            if (businessChecked.indexOf(node.value) == -1) {
                                                                businessChecked.push(node.value) // push parent
                                                            }
                                                        } else {
                                                            if (businessChecked.indexOf(node.value) == -1) {
                                                                businessChecked.push(node.value) // push child
                                                            }
                                                        }
                                                    } else {
                                                        if (node['isParent']) {
                                                            const index = businessChecked.indexOf(node.value);
                                                            if (index > -1) {
                                                                businessChecked.splice(index, 1);
                                                            }
                                                            node.children.forEach(item => {
                                                                const index = businessChecked.indexOf(item.value);
                                                                if (index > -1) {
                                                                    businessChecked.splice(index, 1);
                                                                }
                                                            });
                                                        } else {
                                                            const index = businessChecked.indexOf(node.value);
                                                            if (index > -1) {
                                                                businessChecked.splice(index, 1);
                                                            }
                                                        }
                                                    }
                                                    this.setState({ businessChecked: businessChecked })
                                                }}
                                                onExpand={(expanded, node) => {
                                                    if (node.expanded) {
                                                        businessExpanded.push(node.value);
                                                    } else {
                                                        const index = businessExpanded.indexOf(node.value);
                                                        if (index > -1) {
                                                            businessExpanded.splice(index, 1);
                                                        }
                                                    }
                                                    this.setState({ businessExpanded: businessExpanded })
                                                }
                                                }
                                                nativeCheckboxes={true}
                                                icons={{
                                                    expandClose: <i className="fa fa-plus-square" aria-hidden="true"></i>,
                                                    expandOpen: < i className="fa fa-minus-square" aria-hidden="true"></i>,
                                                    parentClose: null,
                                                    parentOpen: null,
                                                    leaf: null
                                                }}
                                            />
                                        </div>
                                    </div></>
                                }
                                <TextField
                                    componentRef={this.refBJ}
                                    styles={{ root: { marginTop: 10 } }} label={UIConstants.BusinessJustfication} multiline rows={3} maxLength={1000} value={Justification}
                                    onChange={this.onJustificationChange} required
                                    description={(1000 - Justification.length) + ' / 1000 Characters left'}
                                />
                                <Label style={{ fontWeight: "normal" }}>All users must include adequate business justification for access, referencing the following considerations:
                                    <li>The user's area of responsibility and audience with whom the data will be distributed or shared.</li>
                                    <li>Explanation for access level requested, including reports and downstream scorecards.</li></Label>
                                <Label required={true} styles={labelStyles}>Terms & Conditions</Label>
                                <Label style={{ fontWeight: "normal" }}>(All of the below terms & conditions requires acceptance to submit this access request)</Label>
                                <div style={{ display: 'inline-flex', "alignItems": 'center' }}>
                                    <span><Checkbox styles={{ root: { width: 20 } }} checked={isTermsAgreed} onChange={(ev, checked) => { this.setState({ isTermsAgreed: checked }) }} />
                                    </span><span><Label style={{ fontWeight: "normal" }}><span>
                                        &nbsp;I have read and agree to the <Link href="https://microsoft.sharepoint.com/teams/MS_Sales/SitePages/MS-Sales-Access-Policy.aspx" target="_blank" underline>
                                            MS Sales Security Policy
                                        </Link>.
                                    </span></Label>
                                    </span>
                                </div>
                                <TextField
                                    styles={{ root: { marginTop: 10, width: 300 } }} label={UIConstants.Signature} maxLength={100} value={Signature}
                                    onChange={this.onSignatureChange} required
                                />
                                <Label style={{ fontWeight: "normal", fontStyle: 'italic', whiteSpace: 'pre' }}>
                                    Type <span style={{ fontWeight: "bold" }}>{this.state.userDetails.Name?.split(" (")[0].trim()}</span> in the signature box
                                    <br />
                                </Label>
                                <div >
                                    <div className={"ms-Grid-row"} style={{ paddingTop: 10, display: 'inline-flex', "alignItems": 'baseline', width: 'fit-content' }}>
                                        <span style={{ width: '40%' }}>
                                            <Label style={{ fontWeight: "normal", marginTop: 10, width: '100%' }}>
                                                Your request will be routed to your manager for review
                                                <br />
                                                &nbsp;&nbsp;&nbsp;Manager alias for approval: <span style={{ color: "#0064BF", fontWeight: "bold", fontSize: 13 }}>{this.state.ManagerAlias.upn.split('@')[0]}</span>
                                                <br />
                                                &nbsp;&nbsp;&nbsp;Manager name: <span style={{ color: "#0064BF", fontWeight: "bold", fontSize: 13 }}>{this.state.ManagerAlias?.name?.split('(')[0]}</span>
                                                <br />
                                            </Label>
                                            {<div style={{ color: 'red', fontSize: 13, width: '100%', float: 'right' }}>{this.state.isManager ?? ''}</div>}
                                            {this.state.ManagerAlias?.name == "N/A" && <div style={{ color: 'red', fontSize: 13, width: '100%', float: 'right' }}>{'Information about the manager is unavailable.'}</div>}
                                        </span>
                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                        <span style={{ width: '85%', border: '1px solid', borderColor: '#ddd', padding: 10, background: 'aliceblue' }}>
                                            <Label style={{ fontWeight: "normal" }}>
                                                <span style={{ fontWeight: "bold" }}>(Optional)</span>
                                                <span> If your Manager is on a vacation (or on a long leave), you can route this request to an alternate approver</span>
                                            </Label>
                                            <div style={{ display: 'inline-flex', "alignItems": 'baseline', float: 'right' }}>
                                                <span>
                                                    <Checkbox styles={{ root: { paddingBottom: 10, width: 20, marginTop: 4 } }} checked={isAlternateManagerChecked}
                                                        onChange={(ev, checked) => { this.setState({ isAlternateManagerChecked: checked, isAlternateManager: '', alternateManagerAlias: '', pickerCtrlKey: this.state.pickerCtrlKey + 1 }) }} />
                                                </span>
                                                <span>
                                                    <Label style={{ fontWeight: "normal" }}>
                                                        &nbsp;  Select an alternate FTE approver for request review:  &nbsp;
                                                    </Label>
                                                </span>
                                                <span>
                                                    {!isAlternateManagerChecked ? <TextField styles={{ root: { width: 275 }, field: { height: 38 } }} placeholder={"Search for an alternate FTE approver"} disabled={!this.state.isAlternateManagerChecked} /> :
                                                        <div style={{ width: 275 }}><PeoplePicker
                                                            key={pickerCtrlKey}
                                                            type={PeoplePickerType.Normal}
                                                            contentType={PeoplePickerContentType.FTEUser}
                                                            selectionMode={PeoplePickerSelectionMode.Single}
                                                            onchange={(items) => { this.onPeoplePickerChange(items) }}
                                                            defaultSelectedItems={[]}
                                                            {...this.props}
                                                            setFocusOnPicker={false}
                                                            hideDirectFTE={true}
                                                        ></PeoplePicker></div>}
                                                </span>
                                            </div>
                                            {isAlternateManager.includes("Validating Data") ? <><br /><Spinner size={SpinnerSize.small} label="Validating..." ariaLive="assertive" labelPosition="right" style={{ display: 'inline-flex' }} /></> : <div style={{ color: 'red', fontSize: 13, width: '100%', float: 'right' }}>{isAlternateManager ?? ''}</div>}
                                        </span>
                                    </div>
                                </div>
                                <br />

                            </div>
                            <div className="float-right">
                                {!(this.state.tenantData.TenantName == UIConstants.Tenant.MSSales || this.state.tenantData.TenantName == UIConstants.Tenant.Alfred) && <div>
                                    <Label required={true} styles={labelStyles}>{UIConstants.Labletext.RequestType}</Label>
                                    <select id={'drpRequestType'}
                                        title={UIConstants.Labletext.RequestType} required={true}
                                        onChange={this.OnRequestTypeDropDownChange}
                                        style={{ height: 32, width: 165 }}>
                                        <option value="Add">Add</option>
                                    </select>
                                </div>}
                                <br />
                                <div hidden={displayApprovedBasicExistsMessage || displayApprovedExistsMessage || displayPendingBasicExistsMessage || displayPendingExistsMessage}>
                                    <DefaultButton onClick={this.onResetClick} disabled={saveInProgress == true} > {UIConstants.ButtonText.Reset} </DefaultButton>
                                    <span>&nbsp;</span>
                                    <PrimaryButton disabled={(this.tenantData?.TenantName == UIConstants.Tenant.MSSales && selectedRole.name?.includes("User Account") && ManagerAlias.jobTitle.toLowerCase() == "ceo" && !isAlternateManagerChecked) || (saveInProgress == true)} onClick={this.onSubmitClick} >{UIConstants.ButtonText.Submit} </PrimaryButton>
                                </div>
                            </div>
                            <br />
                            <div>
                                <ModalDialog hidden={hideDialog}
                                    onDismiss={this.closeDialog}
                                    dialogContentProps={this.state.modalDialogContent}
                                    modalProps={{
                                        titleAriaId: this._labelId,
                                        subtitleAriaId: this._subTextId,
                                        isBlocking: false,
                                        styles: { main: { maxWidth: 450 } },
                                    }}
                                >
                                </ModalDialog>
                            </div>
                        </div>
                    </div>
                    <br />
                </div>

            </React.Fragment>
        );
    };

    private isRoleDrpDwnDisabled() {
        const { tenantData, fetchingFiscalCycles, editRequest } = this.state;
        if (tenantData.TenantName == UIConstants.Tenant.Alfred && (editRequest || fetchingFiscalCycles)) {
            return true;
        }
    }
    private renderOnbehalfCtrl = () => {
        const { requestorPickerCtrlKey } = this.state;
        return <div>
            <Label styles={labelStyles}>Request on behalf of User</Label>
            {this.tenantData.EnableExternalRequestOnBehalf ?
                <div style={{ width: window.innerWidth < 400 ? 250 : 350 }}>
                    <PeoplePicker
                        key={requestorPickerCtrlKey}
                        type={PeoplePickerType.Normal}
                        contentType={PeoplePickerContentType.ExternalUser}
                        selectionMode={PeoplePickerSelectionMode.Single}
                        onchange={(items) => { this.onRequestorPeoplePickerChange(items) }}
                        defaultSelectedItems={[]}
                        {...this.props}
                        setFocusOnPicker={false}
                    ></PeoplePicker></div>
                :
                <div style={{ width: window.innerWidth < 400 ? 250 : 350 }}>
                    <PeoplePicker
                        key={requestorPickerCtrlKey}
                        type={PeoplePickerType.Normal}
                        contentType={PeoplePickerContentType.User}
                        selectionMode={PeoplePickerSelectionMode.Single}
                        onchange={(items) => { this.onRequestorPeoplePickerChange(items) }}
                        defaultSelectedItems={[]}
                        {...this.props}
                        setFocusOnPicker={false}
                    ></PeoplePicker></div>
            }
        </div>
    }
    /**
     * Event Handlers
     */

    private onCycleChange = () => {

    }
    private onRoleDropDownChange = (value: any): void => {
        if (value == '0') {
            this.setState({
                hideBj: true,
                Justification: '',
                RoleDescription: null,
                showMessage: false,
                isSignatureVerfied: false,
                Signature: '',
                subsidiaryChecked: [],
                businessChecked: [],
                businessExpanded: [],
                subsidiaryExpanded: [],
                alternateManagerAlias: '',
                approvedaccess: [],
                editRequest: false,
                showMSSalesTermsAndConditions: false,
                selectedAttributes: [],
                programFamily: '',
                accessTypeBI: '',
                requestorPrincipalId: '',
                displayApprovedBasicExistsMessage: false,
                displayApprovedExistsMessage: false,
                cyclesChecked: [],
                alfredCyclesNodes: [],
                attributeChecked: [],
                attributeExpanded: []
            });
            if (this.state.isMSTTenant) {
                this.setState({ hideBj: false });
            }
            this.setInitialState(); // to reset the state of request screen 
        } else {
            this.setState({ attributesLoaded: false, message: '', approvedaccess: [], editRequest: false, programFamily: '', selectedAttributes: [], showSubsidiary: false, accessTypeBI: '', selectedDefaultReportingSubsidiary: "0", displayApprovedBasicExistsMessage: false, displayApprovedExistsMessage: false, showMSSalesTermsAndConditions: false, displayPendingBasicExistsMessage: false, displayPendingExistsMessage: false, attributeChecked: [], attributeExpanded: [] });
            const { RoleDetails, karnakConfig, showMSSalesTermsAndConditions, myAccessDataLoaded } = this.state;
            let hideAttributes = true;
            let showTermsAndConditionFlag = false;
            let showKarnakAttributeDescFlag = false;
            let scope = null;
            let attributesConfig = [];
            let cosmosAttributesConfig = [];
            let attributeDescription: any = [];
            // to hide and show attributes section
            let currentRole = RoleDetails.Data.filter(x => x.id === value)[0];
            if (this.state.tenantData.TenantName === UIConstants.Tenant.MSSales) {
                let hasMSSalesPendingAccess = this.state.myAccess.pendingRequests.filter(x => x.role.roleName.toLowerCase() == currentRole.name.toLowerCase()).length
                if (!currentRole.name.includes("User Account")) {
                    if (this.state.approvedMSSalesAccess.length > 0) {
                        let hasMSSalesAccess = this.state.approvedMSSalesAccess.filter(x => x.roleName.toLowerCase() == currentRole.name.toLowerCase()).length
                        let hasMSSalesPendingAccess = this.state.myAccess.pendingRequests.filter(x => x.role.roleName.toLowerCase() == currentRole.name.toLowerCase()).length
                        {
                            this.setState({ showMSSalesTermsAndConditions: false, displayApprovedExistsMessage: hasMSSalesAccess > 0 ? true : false, displayPendingExistsMessage: hasMSSalesPendingAccess > 0 ? true : false, displayApprovedBasicExistsMessage: false, displayPendingBasicExistsMessage: false })
                        }
                    }
                }
                else {
                    this.setState({ showMSSalesTermsAndConditions: true, displayApprovedBasicExistsMessage: this.state.hasMSSalesBasicAccess, displayPendingBasicExistsMessage: hasMSSalesPendingAccess > 0 ? true : false, displayApprovedExistsMessage: false, displayPendingExistsMessage: false })
                }
            }
            if (this.state.tenantData.TenantName === UIConstants.Tenant.Alfred) {
                if (currentRole.name.split(' ').length > 1) {
                    let nodesInAscendingOrder = [];
                    let fiscalYear = currentRole.name.split(' ')[1].toString();
                    this.setState({ fetchingFiscalCycles: true })
                    AccessAPI.GetCyclesBasedonFiscalYear(this.state.tenantData.TenantId, fiscalYear).then(cycles => {
                        let nodes = cycles.map(x => { return { label: x.code.trim() + ': ' + x.name, value: x.code, children: null } })
                        nodes.map(x => x.value).map(Number).sort((a, b) => a - b).map(String).map(str => str.padStart(3, '0')).forEach(x => {
                            nodes.forEach(node => {
                                if (node.value == x) {
                                    nodesInAscendingOrder.push(node)
                                }
                            })
                        })
                        this.setState({ selectedRole: currentRole, alfredCycles: cycles, alfredCyclesNodes: nodesInAscendingOrder, fetchingFiscalCycles: false, cyclesChecked: [] })
                    })
                }
                else {
                    this.setState({ selectedRole: currentRole, alfredCycles: [], alfredCyclesNodes: [], cyclesChecked: [], fetchingFiscalCycles: false })
                }
                let iswriteRole = currentRole.name.includes('Write') ? true : false;
                this.setState({ renderAttestation: iswriteRole });
            }
            if (currentRole.permissions && currentRole.permissions.length > 0) {
                this.setState({ renderSkiplevel: this.state.requestorPrincipalId == '' ? currentRole.permissions[0].actions.includes("AllowAlternateManager:true") : false, renderAlternateApprover: this.state.requestorPrincipalId == '' ? currentRole.permissions[0].actions.includes("RenderPickerForAlternateManager:true") : false })
                let displayAttributes = currentRole.permissions[0].actions.filter(x => x.indexOf(`AttributesDisplay:`) >= 0);
                let cosmosDisplayAttributes = currentRole.permissions[0].actions.filter(x => x.indexOf(`FetchCosmosAttributes:`) >= 0) //MDS
                if (this.state.tenantData.TenantName == UIConstants.Tenant.MSSales) {
                    let showSubsidiary = currentRole.permissions[0].actions.filter(x => x.indexOf(`ShowSubsidiary:true`) >= 0)
                    if (showSubsidiary.length > 0) {
                        this.setState({ showSubsidiary: true, subsidiaryChecked: [], businessChecked: [], businessExpanded: [], subsidiaryExpanded: [] });
                    }
                }
                let attributesToDisplay = [];
                let cosmosAttributesToDisplay = [];

                showTermsAndConditionFlag = currentRole.permissions[0].actions.filter(x => x.indexOf(`ShowTermsFlag:true`) >= 0).length > 0;

                let filteredValue = karnakConfig.filter(x => x.RoleId == value);

                if (filteredValue.length > 0) {
                    attributeDescription = filteredValue[0].Attribute;
                    if (attributeDescription?.filter(x => x.Description).length > 0)
                        showKarnakAttributeDescFlag = true; // set to true only if Attribute array has description property
                }

                if (displayAttributes && displayAttributes.length > 0) {
                    let attributes = displayAttributes[0].split('AttributesDisplay:');
                    attributesToDisplay = attributes[1].split(',');
                }
                if (cosmosDisplayAttributes && cosmosDisplayAttributes.length > 0) {
                    let attribute = cosmosDisplayAttributes[0].split('FetchCosmosAttributes:');
                    cosmosAttributesToDisplay = attribute[1].split(',');
                }//MDS
                if (attributesToDisplay.length > 0) {
                    hideAttributes = false;
                }
                if (cosmosAttributesToDisplay.length > 0) {
                    hideAttributes = false;
                }//MDS

                if (currentRole.scopes.length === 1) {
                    scope = currentRole.scopes[0];
                }

                attributesConfig = attributesToDisplay.map(x => {
                    let splitVal = x.split('-');
                    if (splitVal.length > 1) {
                        let attrName = splitVal[0];
                        let attrDisplayName = attrName === UIConstants.RoleDefinition.Company ? UIConstants.Labletext.CompanyCode : attrName;
                        return { attributeName: attrName, attributeDisplayName: attrDisplayName, attributeType: splitVal[1], attributeValue: null, selectedAttributeValue: [] }
                    } else {
                        let attrName = splitVal[0];
                        let attrDisplayName = attrName === UIConstants.RoleDefinition.Company ? UIConstants.Labletext.CompanyCode : attrName;
                        return { attributeName: attrName, attributeDisplayName: attrDisplayName, attributeType: AttributeType.Lookup, attributeValue: null, selectedAttributeValue: [] }
                    }
                })

                cosmosAttributesConfig = cosmosAttributesToDisplay.map(x => {
                    let splitVal = x.split('-');
                    if (splitVal.length > 1) {
                        let attrName = splitVal[0];
                        let attrDisplayName = attrName === UIConstants.RoleDefinition.Company ? UIConstants.Labletext.CompanyCode : attrName;
                        return { attributeName: attrName, attributeDisplayName: attrDisplayName, attributeType: splitVal[1], attributeValue: null, selectedAttributeValue: [] }
                    } else {
                        let attrName = splitVal[0];
                        let attrDisplayName = attrName === UIConstants.RoleDefinition.Company ? UIConstants.Labletext.CompanyCode : attrName;
                        return { attributeName: attrName, attributeDisplayName: attrDisplayName, attributeType: AttributeType.Lookup, attributeValue: null, selectedAttributeValue: [] }
                    }
                })//MDS
            }

            let isInvoiceTenant = this.tenantData.TenantName.toUpperCase() == UIConstants.Tenant.Invoice.toUpperCase();
            let isMDSTenant = this.tenantData.TenantName.toUpperCase() == UIConstants.Tenant.MDS.toUpperCase();
            if (isInvoiceTenant) {
                scope = this.tenantData.TenantName;
            }

            if (attributesConfig.length === 0 && cosmosAttributesConfig.length === 0) {
                this.setState({
                    selectedRole: currentRole,
                    showMessage: false,
                    hideBj: false,
                    hideAttributes: hideAttributes,
                    selectedAttributes: [],
                    scope: scope,
                    RoleDescription: currentRole.description,
                    attributesLoaded: true,
                    attributeConfig: []
                });
            }

            let counter = 0;
            for (const iterator of attributesConfig) {                
                    if (iterator.attributeType.toLowerCase() === AttributeType.List.toLowerCase()) {
                        AccessAPI.getAttributeData('', this.tenantData.TenantId, iterator.attributeName).then(attributeResponse => {
                            iterator.attributeValue = attributeResponse;
                            counter++;
                            if (attributesConfig.length === counter) {
                                this.setState({
                                    selectedRole: currentRole,
                                    showMessage: false,
                                    hideBj: false,
                                    hideAttributes: hideAttributes,
                                    selectedAttributes: [],
                                    scope: scope,
                                    RoleDescription: currentRole.description,
                                    attributesLoaded: true,
                                    attributeConfig: attributesConfig
                                });
                            }
                        });
                    } else if (iterator.attributeType.toLowerCase() === AttributeType.MultiSelectList.toLowerCase()) {
                        AccessAPI.getAttributeData('', this.tenantData.TenantId, iterator.attributeName).then(attributeResponse => {
                            iterator.attributeValue = attributeResponse;
                            counter++;
                            if (attributesConfig.length === counter) {
                                this.setState({
                                    selectedRole: currentRole,
                                    showMessage: false,
                                    hideBj: false,
                                    hideAttributes: hideAttributes,
                                    selectedAttributes: [],
                                    scope: scope,
                                    RoleDescription: currentRole.description,
                                    attributesLoaded: true, attributeConfig: attributesConfig
                                });
                            }
                        });
                    } else {
                        iterator.attributeValue = [];
                        counter++;
                        if (attributesConfig.length === counter) {
                            this.setState({
                                selectedRole: currentRole,
                                showMessage: false,
                                hideBj: false,
                                hideAttributes: hideAttributes,
                                selectedAttributes: [],
                                scope: scope,
                                RoleDescription: currentRole.description,
                                attributesLoaded: true,
                                attributeConfig: attributesConfig
                            });
                        }
                    }
                
            }
            //domain data from cosmos
            let index = 0;
            if (cosmosAttributesConfig.length != 0)  {
                AccessAPI.getMDSRoleAndScopes(currentRole.name).then(attributeResponse => {
                    for (const iterator of cosmosAttributesConfig) {
                        if (iterator.attributeType.toLowerCase() === AttributeType.List.toLowerCase() || iterator.attributeType.toLowerCase() === AttributeType.MultiSelectList.toLowerCase()) {
                            let scopeValues = attributeResponse && attributeResponse.find(scope => scope.scopeName === iterator.attributeName);
                            if (scopeValues) {
                                let attributes: Array<AttributeValues> = scopeValues.scopes.map(scope => ({
                                    code: scope.domainCode,
                                    description: scope.domainName
                                }));
                                iterator.attributeValue = attributes.sort((a, b) => (a.description.toUpperCase() < b.description.toUpperCase()) ? -1 : 1);
                            }
                            index++;
                            if (cosmosAttributesConfig.length === index) {
                                this.setState({
                                    selectedRole: currentRole,
                                    showMessage: false,
                                    hideBj: false,
                                    hideAttributes: hideAttributes,
                                    selectedAttributes: [],
                                    scope: scope,
                                    RoleDescription: currentRole.description,
                                    attributesLoaded: true,
                                    attributeConfig: cosmosAttributesConfig
                                });
                            }
                        }
                        else if (iterator.attributeType.toLowerCase() === AttributeType.Tree.toLowerCase()) {
                            let scopeValues = attributeResponse && attributeResponse.find(scope => scope.scopeName === iterator.attributeName);
                            let attributes: Array<AttributeValues> = [];
                            if (scopeValues) {
                                if (scopeValues.childName != null && scopeValues.childName != "") {
                                    let childScopeValues = attributeResponse.find(scope => scope.scopeName === scopeValues.childName);
                                    scopeValues.scopes.map(parentScope => {
                                        let childscopes = childScopeValues.scopes.filter(scope => scope.parentDomainCode === parentScope.domainCode)
                                        if (childscopes?.length > 0) {
                                            childscopes.forEach(item => {
                                                attributes.push({
                                                    GroupCode: "Parent-"+parentScope.domainCode,
                                                    GroupDescription: parentScope.domainName,
                                                    code: item?.domainCode,
                                                    description: item?.domainName
                                                });
                                            });
                                        }
                                        else {
                                            attributes.push({
                                                GroupCode: "Parent-" +parentScope.domainCode,
                                                GroupDescription: parentScope.domainName,
                                                code: childScopeValues.scopes.find(scope => scope.parentDomainCode === parentScope.domainCode)?.domainCode,
                                                description: childScopeValues.scopes.find(scope => scope.parentDomainCode === parentScope.domainCode)?.domainName
                                            });
                                        }
                                    });
                                }
                                else {
                                    attributes = scopeValues.scopes.map(scope => ({
                                        GroupCode: "Parent-" +scope.domainCode,
                                        GroupDescription: scope.domainName,
                                        code: null,
                                        description: null
                                    }));
                                }
                                iterator.attributeValue = attributes.sort((a, b) => (a.description?.toUpperCase() < b.description?.toUpperCase()) ? -1 : 1).sort((a, b) => (a.GroupDescription.toUpperCase() < b.GroupDescription.toUpperCase()) ? -1 : 1);
                            }
                            index++;
                            if (cosmosAttributesConfig.length === index) {
                                this.setState({
                                    selectedRole: currentRole,
                                    showMessage: false,
                                    hideBj: false,
                                    hideAttributes: hideAttributes,
                                    selectedAttributes: [],
                                    scope: scope,
                                    RoleDescription: currentRole.description,
                                    attributesLoaded: true,
                                    attributeConfig: cosmosAttributesConfig
                                });
                            }
                        }
                        else {
                            iterator.attributeValue = [];
                            index++;
                            if (cosmosAttributesConfig.length === index) {
                                this.setState({
                                    selectedRole: currentRole,
                                    showMessage: false,
                                    hideBj: false,
                                    hideAttributes: hideAttributes,
                                    selectedAttributes: [],
                                    scope: scope,
                                    RoleDescription: currentRole.description,
                                    attributesLoaded: true,
                                    attributeConfig: cosmosAttributesConfig
                                });
                            }
                        }

                    }
                });
            }
            if (this.tenantData.TenantName.toUpperCase() == UIConstants.Tenant.MDS.toUpperCase()) {
                let isApproverRoleMDS = currentRole.name.includes('Approver') ? true : false;
                this.setState({
                    renderAttestation: isApproverRoleMDS
                });
            }
            this.setState({
               hideAttributes: hideAttributes, showTermsAndConditions: showTermsAndConditionFlag, isTermsAgreed: false, attestedList: []
               , showKarnakAttributeDesc: showKarnakAttributeDescFlag, attributeDescList: attributeDescription
             });
        }
    };

    private onRequestModeChange = (ev: React.FormEvent<HTMLInputElement>, option: IChoiceGroupOption) => {
        this.setState({ selectedKey: option.key })
        if (option.key == 'OnBelfInBulk') {
            this.setState({
                requestorPrincipalId: '',
                selectedAccountType: '0',
                selectedAccountUPN: '',
                isRequestModeBulk: true,
                isSelected: false,
                selectedFile: null,
                fileData: '',
                selectedRole: { id: '0', roleId: null, name: null, numberOfApprovalLevels: 0, isApprovalRequired: false, description: '' },
                selectedBusinessGroupRole: '0',
                BulkUserDetails: []
            })
        }
        else {
            this.setState({
                isRequestModeBulk: false,
                isSelected: false,
                selectedFile: null,
                fileData: '',
                selectedRole: { id: '0', roleId: null, name: null, numberOfApprovalLevels: 0, isApprovalRequired: false, description: '' },
                selectedBusinessGroupRole: '0',
                BulkUserDetails: []
            })
        }
    }

    private onFileChange = (event: any) => {
        this.setState
            ({
                selectedFile: event.target.files[0],
                isSelected: event.target.files[0] != null ? true : false
            });
        if (event.target.files != null && event.target.files[0] != null) {
            const [file] = event.target.files
            const reader = new FileReader();

            reader.onload = (evt) => {
                const bstr = evt.target.result;
                const wb = XLSX.read(bstr, { type: "binary" });
                const wsname = wb.SheetNames[0];
                const ws = wb.Sheets[wsname];
                const data = XLSX.utils.sheet_to_csv(ws);
                this.setState({ fileData: data });
            };
            reader.readAsBinaryString(file);
        }
    }

    private renderFileUploadControl = () => {
        return (
            <div>
                <span>
                    <input ref={this.bulkUploadref} type="file" name="file" onChange={this.onFileChange} />
                    <a style={{ marginLeft: '5px', cursor: 'pointer', textDecoration: 'underline', color: 'blue' }} onClick={this.downloadBulkUploadTemplate} >Download Template</a>
                </span>
                <p style={{ marginBlock: 5 }}>(Please upload a .CSV file with columns UserUPN and RoleName)</p>
                <span>
                    <DefaultButton onClick={this.ResetFile} > {UIConstants.ButtonText.Remove} </DefaultButton>
                </span>
            </div>
        );
    }
    private renderAllowedAccountTypes = () => {
        const { selectedAccountType, allowedAccountTypes, selectedAccountUPN, userDetails, selectedRole, selectedBGName } = this.state;
        return (
            allowedAccountTypes && allowedAccountTypes.length > 0 ? <>
                <div ref={this.accountTypeDivRef}>
                    <Label styles={labelStyles} required={true}>{UIConstants.AccountType}</Label>
                    <select id={'drpRole'} value={selectedAccountType} required={true}
                        title={UIConstants.AccountType}
                        onChange={(event) => { this.setState({ selectedAccountType: event.target.value, selectedAccountUPN: allowedAccountTypes?.find(x => x.AllowedType == event.target.value).IsDynamic ? '' : userDetails.Alias.split("@")[0] + '@' + allowedAccountTypes?.find(x => x.AllowedType == event.target.value).DomainSuffix }) }}
                        style={{ height: 32, width: window.innerWidth < 400 ? 250 : 350 }}>
                        <option value="0"> -- Select -- </option>
                        {allowedAccountTypes?.map(x => { return <option key={'drpRole' + x.AllowedType} aria-label={x.AllowedType} value={x.AllowedType}>{x.AllowedType}</option> })}
                    </select>
                </div>
                {selectedAccountType != "0" &&
                    <div>
                        <TextField disabled={!allowedAccountTypes?.find(x => x.AllowedType == selectedAccountType).IsDynamic}
                            styles={{ root: { marginTop: 10, width: 350 } }} maxLength={100} placeholder={!(allowedAccountTypes?.find(x => x.AllowedType == selectedAccountType).IsDynamic) ? selectedAccountUPN : ''}
                            onChange={(event, val) => { this.setState({ selectedAccountUPN: val.trim() }) }}
                        />
                    </div>
                }
            </> : <></>
        );
    }

    private downloadBulkUploadTemplate = () => {
        const filePath = '/BulkUploadTemplate.csv';
        const downloadLink = document.createElement('a');
        downloadLink.href = process.env.PUBLIC_URL + filePath;
        downloadLink.download = 'Template.csv';
        downloadLink.style.color = 'blue';
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    }

    private ResetFile = (): void => {
        this.bulkUploadref.current.value = "";
        this.setState({ selectedFile: null, isSelected: false, fileData: '', BulkUserDetails: [] })
    }
    private handleSubmission = () => {
        const { fileData, selectedGroup, cosmosRoleDefinitions, Justification, selectedFile, tenantData } = this.state
        event.preventDefault();
        const fileInfo = new FormData();
        this.setState({ scope: this.tenantData.TenantName });
        let businessGroup = selectedGroup.length > 0 ? cosmosRoleDefinitions?.filter(x => x.businessGroupId == selectedGroup)[0]?.businessGroupName : ""
        fileInfo.append('File', selectedFile);
        fileInfo.append('FileName', selectedFile.name);
        fileInfo.append('BusinessGroup', businessGroup);
        fileInfo.append('TenantId', tenantData.TenantId);
        fileInfo.append('FileData', fileData);

        AccessAPI.validateFile(fileInfo).then(async res => {
            if (res.status && res.status === 200) {
                this._modalDialogContent = {
                    subText: UIConstants.Messages.SaveConfirmation,
                    title: UIConstants.MessageBoxTitle.SaveAccess,
                    okButtonText: UIConstants.ButtonText.Save,
                    type: DialogType.normal,
                    okAction: this.saveAccessRequest,
                    cancelAction: this.closeDialog,
                    closeButtonAriaLabel: UIConstants.ButtonText.Cancel,
                }
                this.setState({ BulkUserDetails: res.data, hideDialog: false, autoHideMessage: false, modalDialogContent: this._modalDialogContent });
            }
            else if ((res.status && res.status !== 200) || res.message) {
                this.setState({
                    saveInProgress: false,
                    messageType: MessageBarType.warning,
                    message: res.message,
                    showMessage: true,
                    hideBj: false,
                    Justification: Justification,
                    autoHideMessage: false
                });
            }
        });
    }
    private onBusinessGroupChange = (event: any): void => {
        const { selectedGroup, RoleDetails, tenantData, cosmosRoleDefinitions, vlAppsRoleGroupConfig } = this.state;
        this.setState({ mstGrouplist: true, selectedGroup: event.target.value, selectedBusinessGroupRole: '0', selectedRole: { id: '0', name: null, scopes: null, description: '', permissions: [], sortOrder: '', attributes: [], hasBulkUploadAccess: false } })
        if (event.target.value != '0' && this.tenantData?.isExternalAADTenant) {
            let bgName = cosmosRoleDefinitions?.filter(x => x.businessGroupId == event.target.value)[0]?.businessGroupName;
            if (bgName == UIConstants.Global_Roles) {
                this.setState({ hasBulkUploadAccess: false, isRequestModeBulk: false })
            }
            else {
                AccessAPI.CheckBulkUploadAccess(this.tenantData?.TenantId, bgName).then(res => {
                    this.setState({ hasBulkUploadAccess: res })
                })
            }
            this.setState({ selectedAccountType: '0', selectedAccountUPN: '', selectedBGName: bgName });
        }
    };
    private onGroupspecificRoleDropDownChange = (event: any): void => {
        const { selectedGroup, RoleDetails, tenantData, cosmosRoleDefinitions, vlAppsRoleGroupConfig } = this.state;
        if (event.target.value == '0') {
            this.setState({
                hideBj: false,
                Justification: '',
                RoleDescription: null,
                showMessage: false,
                selectedBusinessGroupRole: '0'
            });
            this.setInitialState(); // to reset the state of request screen 
        } else {
            this.setState({ attributesLoaded: false });
            if (this.tenantData?.isExternalAADTenant) {
                let currentRole = cosmosRoleDefinitions.filter(x => x.roleId == event.target.value);
                this.setState({
                    selectedAccountType: '0',
                    selectedAccountUPN: '',
                    selectedBusinessGroupRole: currentRole[0].roleId,
                    scope: this.tenantData.TenantName,
                    selectedRole: { id: currentRole[0].roleId, name: currentRole[0].roleName, scopes: "", description: "", permissions: "", sortOrder: "", attributes: null, businessGroup: currentRole[0].businessGroupName },
                    renderSkiplevel: currentRole[0].allowSkipLevel.includes("AllowAlternateManager:true") ? true : false
                });
            }
            else {
                if (tenantData.EnableExternalRequestOnBehalf) {
                    this.setState({ enableRequestOnBehalf: !tenantData.DisableExternalRequestOnBehalfForRoles?.includes(event.target.value) })
                }
                this.onRoleDropDownChange(event.target.value);
                let roles = selectedGroup.length > 0 ? vlAppsRoleGroupConfig?.Data?.businessGroups.filter(x => x.businessGroupId == selectedGroup)[0]?.roles : [];
                let filteredRoles = (roles.length > 0) ? roles?.filter(x => x.isActive === true) : [];
                let currentRole = filteredRoles.length > 0 ? filteredRoles.filter(x => x.roleId == event.target.value) : [];
                let role = RoleDetails.Data.find(x => x.id == event.target.value)
                this.setState({
                    selectedBusinessGroupRole: currentRole[0].roleId,
                    scope: this.tenantData.TenantName,
                    selectedRole: { id: currentRole[0].roleId, name: role.name, scopes: role.scopes, description: role.description, permissions: role.permissions, sortOrder: role.sortOrder, attributes: role.attributes, businessGroup: vlAppsRoleGroupConfig?.Data?.businessGroups.filter(x => x.businessGroupId == selectedGroup)[0].businessGroupName },
                    renderSkiplevel: this.state.requestorPrincipalId == '' ? role.permissions[0].actions.includes("AllowAlternateManager:true") : false
                });
            }
        }
    };
    private onJustificationChange = (ev: React.FormEvent<HTMLInputElement>, newValue?: string) => {
        if (newValue.length <= 1000) {
            this.setState({ Justification: newValue || '', showMessage: false });
        } else {
            this.setState({ showMessage: true, message: UIConstants.Messages.JustificationMaxLength, messageBarType: MessageBarType.warning })
        }
        if (this.tenantData.TenantName == UIConstants.Tenant.MSSales && this.state.message != '') {
            this.setState({ showMessage: true });
        }
    };
    private onTermsChange = (checked, attestationName): void => {
        const { attestedList } = this.state;
        let tmpAttestedList = attestedList;
        if (checked) {
            tmpAttestedList.push(attestationName);
        } else {
            var index = tmpAttestedList.indexOf(attestationName);
            if (index !== -1) {
                tmpAttestedList.splice(index, 1);
            }
        }

        switch (this.state.tenantData.TenantName) {
            case (UIConstants.Tenant.Alfred):
                this.setState({ attestedList: tmpAttestedList, isTermsAgreed: tmpAttestedList.length == 2 });
                break;
            case (UIConstants.Tenant.MDS):
                this.setState({ attestedList: tmpAttestedList, isTermsAgreed: tmpAttestedList.length == 2 });
                break;

            default:
                this.setState({ attestedList: tmpAttestedList, isTermsAgreed: tmpAttestedList.length == 7 });
        }
    };
    private getAreaSubsidairies(config, subsidiarycheck) {
        let values = [];
        for (var value in subsidiarycheck) {
            for (var x in config) {
                for (var child in config[x].children) {
                    if (config[x].children[child].value === subsidiarycheck[value] && values?.filter(item => item.code == config.label).length < 1) {
                        values.push({ GroupId: Number.parseInt(config[x].value.split("subsidiaryGroup-")[1]), GroupCode: config[x].label, GroupDescription: config[x].label, Id: Number.parseInt(config[x].children[child].value), code: config[x].children[child].label, description: config[x].children[child].label });
                    }
                }
            }
        }
        values.sort((a, b) => (a.code.toUpperCase() < b.code.toUpperCase()) ? -1 : 1);
        return values.sort((a, b) => (a.GroupCode.toUpperCase() < b.GroupCode.toUpperCase()) ? -1 : 1);
    };
    private getBusinessGroup = (): IDropdownOption[] => {
        const { vlAppsRoleGroupConfig } = this.state;
        let businessGroups = vlAppsRoleGroupConfig.Data.businessGroups.map(x => { return { key: x.businessGroupId, text: x.businessGroupName } });
        return businessGroups.sort((a, b) => a.text == "Global_Roles" ? -1 : b.text == "Global_Roles" ? 1 : (a.text.toUpperCase() < b.text.toUpperCase()) ? -1 : 1);
    };
    private getExternalAADBGs = (): IDropdownOption[] => {
        const { cosmosRoleDefinitions } = this.state;
        let businessGroups = cosmosRoleDefinitions?.map(x => { return { key: x.businessGroupId, text: x.businessGroupName } });
        businessGroups.sort((a, b) => a.text == "Global_Roles" ? -1 : b.text == "Global_Roles" ? 1 : (a.text.toUpperCase() < b.text.toUpperCase()) ? -1 : 1);
        let uniqueBusinessGroups = [];
        businessGroups.reduce((a, d) => {
            if (!a.includes(d['text'])) {
                uniqueBusinessGroups.push(d);
                a.push(d['text']);
            }
            return a;
        }, []);
        return uniqueBusinessGroups;
    };
    private getBusinessSubsidairies(config, businessCheck) {
        let values = []
        businessCheck.sort((a, b) => (a.toUpperCase() < b.toUpperCase()) ? -1 : 1);
        for (var value in businessCheck) {
            for (var x in config) {
                for (var child in config[x].children) {
                    if (config[x].children[child].value === businessCheck[value] && values?.filter(item => item.code == config.label).length < 1) {
                        values.push({ GroupId: Number.parseInt(config[x].value.split("BusinessGroup-")[1]), GroupCode: config[x].label, GroupDescription: config[x].label, Id: Number.parseInt(config[x].children[child].value), code: config[x].children[child].label, description: config[x].children[child].label })
                    }
                }
            }
        }
        values.sort((a, b) => (a.code.toUpperCase() < b.code.toUpperCase()) ? -1 : 1);
        return values.sort((a, b) => (a.GroupCode.toUpperCase() < b.GroupCode.toUpperCase()) ? -1 : 1);
    }
    private getCheckboxTreeAttributes(config, checkValues) {
        let values = []
        checkValues.sort((a, b) => (a.toUpperCase() < b.toUpperCase()) ? -1 : 1);
        for (var value in checkValues) {
            for (var x in config) {
                if (config[x].children != null && config[x].children.length > 0) {
                    for (var child in config[x].children) {
                        if (config[x].children[child].value === checkValues[value].replace(":Removed", '') && values?.filter(item => item.code == config.label).length < 1) {
                            values.push({ GroupCode: config[x].value.replace("Parent-", ''), GroupDescription: config[x].label, code: config[x].children[child].value, description: config[x].children[child].label, isRemove: checkValues[value].includes(":Removed") })
                        }
                    }
                }
                else {
                    if (config[x].value === checkValues[value].replace(":Removed", '') && values?.filter(item => item.code == config.label).length < 1) {
                        values.push({ code: config[x].value.replace("Parent-", ''), description: config[x].label, isRemove: checkValues[value].includes(":Removed") })
                    }
                }
            }
        }
        values.sort((a, b) => (a.code.toUpperCase() < b.code.toUpperCase()) ? -1 : 1);
        return values.sort((a, b) => (a.GroupCode?.toUpperCase() < b.GroupCode?.toUpperCase()) ? -1 : 1);
    }
    private onSignatureChange = (ev: React.FormEvent<HTMLInputElement>, newValue?: string) => {
        if (newValue.length <= 100) {
            this.setState({ Signature: newValue || '' });
        } else {
            this.setState({ showMessage: true, message: UIConstants.Signature, messageBarType: MessageBarType.warning })
        }
    };
    private OnRequestTypeDropDownChange = (event: any): void => {
        this.setState({ requestType: event.target.value });
    };

    private OnFinControllerTypeDropDownChange = (event: any): void => {
        this.setState({ roleType: event.target.value });
    };

    private onResetClick = (): void => {
        this._modalDialogContent = {
            subText: UIConstants.Messages.ResetConfirmation,
            title: UIConstants.MessageBoxTitle.Reset,
            okButtonText: UIConstants.ButtonText.Reset,
            okAction: this.ResetUI,
            cancelAction: this.closeDialog,
            type: DialogType.normal,
            closeButtonAriaLabel: UIConstants.ButtonText.Cancel,
        }

        this.setState({ hideDialog: false, modalDialogContent: this._modalDialogContent });
    };
    private onSubmitClick = async (): Promise<void> => {
        const { showSubsidiary, subsidiaryChecked, businessChecked, selectedAttributes, vlAppsRoleGroupConfig, selectedGroup, enableRoleGrouping, subsidiariesConfig, businessConfig, cosmosRoleDefinitions, isRequestModeBulk, isSelected, attributeChecked, selectedRole, attributeConfig } = this.state;
        if (attributeChecked != null && attributeChecked.length > 0) {
            let attributes: Attributes[] = JSON.parse(JSON.stringify(selectedAttributes));
            let attributeNames = selectedRole.attributes && selectedRole.attributes.length > 0 ? selectedRole.attributes : [];
            for (const attributeName of attributeNames) {
                let filterdAttributeConfig = attributeConfig.filter(x => x.attributeName === attributeName)[0];
                const map = new Map();
                filterdAttributeConfig.attributeValue.forEach(item => {
                    if (!map.has(item.GroupCode)) {
                        map.set(item.GroupCode, { value: item.GroupCode, label: item.GroupDescription, children: [] });
                    }
                    if (item.code != null) {
                        map.get(item.GroupCode).children.push({ value: item.code, label: item.description });
                    }
                });

                let nodes = Array.from(map.values());
                let checkedValues = attributeChecked != null && attributeChecked.length > 0 && attributeChecked.filter(x => x.Name == attributeName).length > 0 ? attributeChecked.filter(x => x.Name == attributeName)[0].Values : [];
                if (attributes.find(x => x.attributeName == attributeName)) {
                    attributes.find(x => x.attributeName == attributeName).attributeValues = this.getCheckboxTreeAttributes(nodes, checkedValues);
                }
                else {
                    let newAttributeValues: Attributes = { attributeName: attributeName, attributeValues: this.getCheckboxTreeAttributes(nodes, checkedValues) };
                    attributes.push(newAttributeValues);
                }
            }
            this.setState({ selectedAttributes: attributes });
        }
        let validationResponse = this.ValidateData();
        if ((this.tenantData?.isExternalAADTenant && isRequestModeBulk && isSelected)) {
            if (validationResponse.isValid) {
                let attrValue = [{ code: cosmosRoleDefinitions?.filter(x => x.businessGroupId == selectedGroup)[0]?.businessGroupName, description: cosmosRoleDefinitions?.filter(x => x.businessGroupId == selectedGroup)[0]?.businessGroupName }];
                this.updateAttributeValues(UIConstants.Attribute.BusinessGroup, attrValue);
                this.handleSubmission();
            }
            else {
                // Show Error message and skip submission
                this.setState({ showMessage: true, messageType: MessageBarType.warning, message: validationResponse.validationMessage, autoHideMessage: false });
            }
        }
        else {
            if (validationResponse.isValid) {
                if (showSubsidiary) {
                    let attributes: Attributes[] = JSON.parse(JSON.stringify(selectedAttributes));
                    if (attributes.find(x => x.attributeName == "Subsidiary")) {
                        attributes.find(x => x.attributeName == "Subsidiary").attributeValues = this.getAreaSubsidairies(subsidiariesConfig, subsidiaryChecked);
                    }
                    else {
                        let newSubsidiaryAttributeValues: Attributes = { attributeName: "Subsidiary", attributeValues: this.getAreaSubsidairies(subsidiariesConfig, subsidiaryChecked) };
                        attributes.push(newSubsidiaryAttributeValues);
                    }
                    if (attributes.find(x => x.attributeName == "Business")) {
                        attributes.find(x => x.attributeName == "Business").attributeValues = this.getBusinessSubsidairies(businessConfig, businessChecked);
                    }
                    else {
                        let newBusinessAttributeValues: Attributes = { attributeName: "Business", attributeValues: this.getBusinessSubsidairies(businessConfig, businessChecked) };
                        attributes.push(newBusinessAttributeValues);
                    }
                    this.setState({ selectedAttributes: attributes });
                }
                else if (enableRoleGrouping) {
                    let attrValue = [{ code: vlAppsRoleGroupConfig?.Data?.businessGroups.filter(x => x.businessGroupId == selectedGroup)[0]?.businessGroupName, description: vlAppsRoleGroupConfig?.Data?.businessGroups.filter(x => x.businessGroupId == selectedGroup)[0]?.businessGroupName }];
                    this.updateAttributeValues(UIConstants.Attribute.BusinessGroup, attrValue);
                }
                else if (this.tenantData?.isExternalAADTenant) {
                    let attrValue = [{ code: cosmosRoleDefinitions?.filter(x => x.businessGroupId == selectedGroup)[0]?.businessGroupName, description: cosmosRoleDefinitions?.filter(x => x.businessGroupId == selectedGroup)[0]?.businessGroupName }];
                    this.updateAttributeValues(UIConstants.Attribute.BusinessGroup, attrValue);
                }
                this._modalDialogContent = {
                    subText: UIConstants.Messages.SaveConfirmation,
                    title: UIConstants.MessageBoxTitle.SaveAccess,
                    okButtonText: UIConstants.ButtonText.Submit,
                    type: DialogType.normal,
                    okAction: this.saveAccessRequest,
                    cancelAction: this.closeDialog,
                    closeButtonAriaLabel: UIConstants.ButtonText.Cancel,
                }
                this.setState({ hideDialog: false, autoHideMessage: false, modalDialogContent: this._modalDialogContent });// Show confirmation pop-up      
            } else {
                // Show Error message and skip submission
                this.setState({ showMessage: true, messageType: MessageBarType.warning, message: validationResponse.validationMessage, autoHideMessage: false });
                if (this.tenantData.TenantName == UIConstants.Tenant.MSSales) {
                    setTimeout(() => {
                        this.alertMsg2CloseBtnRef.current.focus();
                    }, 100) // set the focus to close button
                }
            }
        }
    };

    private toggleProgressBar = (value): void => {
        this.setState({ saveInProgress: value });
    };
    private toggleMessageBar = (msg, messageType): void => {
        if (msg != '') {
            this.setState({ showMessage: true, messageType: messageType, message: msg, autoHideMessage: false });
        }
        else {
            this.setState({ showMessage: false, messageType: messageType, message: msg, autoHideMessage: false, isMercuryEditMode: false });
        }
    };


    private saveAccessRequest = (): void => {
        const { selectedAccountUPN, Justification, requestType, editRequest, tenantData, scope, Signature, alternateManagerAlias, isSelected, isRequestModeBulk,
            isTermsAgreed, isSignatureVerfied, isAlternateManagerChecked, ManagerAlias, allowSkipLevel, requestorPickerCtrlKey, requestorPrincipalId, BulkUserDetails } = this.state;
        let t = this;
        let data: AccessRequestSubmissionModel = {
            editRequest: editRequest,
            tenantId: tenantData.TenantId,
            tenantName: tenantData.TenantName,
            businessJustification: Justification,
            scope: scope,
            role: this.getRolesDetails(),
            bulkRequestUserDetails: this.tenantData?.isExternalAADTenant && BulkUserDetails.length > 0 ? BulkUserDetails : null,
            requestType: requestType,
            signatureVerified: isSignatureVerfied,
            termsAndConditionsApplied: isTermsAgreed,
            alternateMangerAlias: isAlternateManagerChecked ? (tenantData.TenantName.toLowerCase() == UIConstants.Tenant.MSSales.toLowerCase() ? alternateManagerAlias : alternateManagerAlias?.split('@')[0]) : null,
            managerAlias: ManagerAlias?.upn != "Manager not found" ? (tenantData.TenantName.toLowerCase() == UIConstants.Tenant.MSSales.toLowerCase() ? ManagerAlias?.upn : ManagerAlias?.upn.split('@')[0]) : null,
            allowSkipLevel: allowSkipLevel,
            requestorPrincipalId: requestorPrincipalId,
            onBehalfExternalUser: !isRequestModeBulk ? (selectedAccountUPN == '' ? null : selectedAccountUPN) : null
        };
        this.setState({ hideDialog: true, saveInProgress: true, showMessage: false });
        window.scrollTo(0, 0);
        this.messageBarDivRef.current.scrollIntoView({ behavior: 'smooth' });
        this.messageBarDivRef.current.focus();
        AccessAPI.saveUserAccessAsync(data)
            .then(async res => {
                if (res.status && res.status === 200) {
                    t.setState({
                        saveInProgress: false,
                        messageType: MessageBarType.success,
                        message: UIConstants.Messages.SaveSuccess,
                        showMessage: true,
                        hideBj: true,
                        Justification: '',
                        autoHideMessage: false,
                        RoleDescription: null,
                        isSignatureVerfied: false,
                        Signature: '',
                        subsidiaryChecked: [],
                        businessChecked: [],
                        businessExpanded: [],
                        subsidiaryExpanded: [],
                        attributedisabled: false,
                        pickerCtrlKey: this.state.pickerCtrlKey + 1,
                        isAlternateManager: '',
                        approvedAccess: [],
                        editRequest: false,
                        isAlternateManagerChecked: false,
                        selectedAttributes: [],
                        allowSkipLevel: false,
                        programFamily: '',
                        accessTypeBI: '',
                        requestorPrincipalId: '',
                        requestorPickerCtrlKey: requestorPickerCtrlKey + 1,
                        selectedGroup: '',
                        isSelected: false,
                        isRequestModeBulk: false,
                        selectedKey: 'Self',
                        fileData: '',
                        selectedFile: null,
                        BulkUserDetails: [],
                        hasBulkUploadAccess: false,
                        selectedAccountUPN: '',
                        selectedAccontType: '0',
                        alfredCyclesNodes: [],
                        attributeChecked: [],
                        selectedValues: [],
                        selectAllAttributeList: []
                        
                    });
                    if (this.state.isMSTTenant) {
                        this.setState({ hideBj: false });
                    }
                    if (this.state.enableRoleGrouping || this.tenantData?.isExternalAADTenant) {
                        this.setState({ hideBj: false, selectedBusinessGroupRole: '0', selectedGroup: '0', selectedAccountType: '0' });
                    }
                    if (this.tenantData.TenantName == UIConstants.Tenant.MSSales) {
                        setTimeout(() => {
                            this.alertMsg2CloseBtnRef.current.focus();
                        }, 100) // set the focus to close button
                        this.setState({ alternateManagerAlias: '' })
                    }
                    this.setInitialState(); // to reset the state of request screen
                } else if ((res.status && res.status !== 200) || res.message) {
                    t.setState({
                        saveInProgress: false,
                        messageType: MessageBarType.warning,
                        message: res.message,
                        showMessage: true,
                        hideBj: false,
                        Justification: Justification,
                        autoHideMessage: false,
                        Signature: Signature
                    });
                    if (this.tenantData.TenantName == UIConstants.Tenant.MSSales) {
                        setTimeout(() => {
                            this.alertMsg2CloseBtnRef.current.focus();
                        }, 100) // set the focus to close button
                    }
                }
            });
    };

    private closeDialog = (): void => {
        this.setState({ hideDialog: true });
    };
    private ResetUI = (): void => {
        this.setState({
            hideDialog: true,
            Justification: '',
            requestType: 'Add',
            selectedRole: { id: '0', roleId: null, name: null, numberOfApprovalLevels: 0, isApprovalRequired: false, description: '' },
            hideBj: true,
            showMessage: false,
            RoleDescription: null,
            subsidiaryChecked: [],
            subsidiaryExpanded: [],
            businessChecked: [],
            businessExpanded: [],
            message: '',
            showSubsidiary: false,
            pickerCtrlKey: this.state.pickerCtrlKey + 1,
            isAlternateManager: '',
            approvedaccess: [],
            editRequest: false,
            selectedAttributes: [],
            programFamily: '',
            isAlternateManagerChecked: false,
            allowSkipLevel: false,
            accessTypeBI: '',
            Signature: '',
            requestorPrincipalId: '',
            requestorPickerCtrlKey: this.state.requestorPickerCtrlKey + 1,
            selectedGroup: '',
            isSelected: false,
            isRequestModeBulk: false,
            selectedKey: 'Self',
            fileData: '',
            BulkUserDetails: [],
            hasBulkUploadAccess: false,
            selectedAccountUPN: '',
            alfredCyclesNodes: [],
            attributeChecked: [],
            attributeExpanded: [],
            selectedValues: [],
            selectAllAttributeList: []

        });
        if (this.state.isMSTTenant) {
            this.setState({ hideBj: false });
        }
        if (this.state.enableRoleGrouping || this.tenantData?.isExternalAADTenant) {
            this.setState({ hideBj: false, selectedBusinessGroupRole: '0', selectedGroup: '0', selectedAccountType: '0' });
            AccessAPI.GetManager(this.state.userDetails.Alias).then(res => {
                res && AccessAPI.GetManager(res?.upn).then(response => {
                    response && this.setState({ alternateManagerAlias: response?.upn.split('@')[0], ManagerAlias: response });
                });
            });
        }
    };

    private onAttributeDropDownChange = (name, val): void => {
        this.updateAttributeValues(name, val);
    };
    private onAttributePickerChange: any = (name, val) => {
        this.updateAttributeValues(name, val);
    };
    private onComboboxChange: any = (event, val, attributeName) => {
        let attributeConfigData = this.state.attributeConfig;
        attributeConfigData.forEach(x => {
            if (val.selected) {
                x.selectedAttributeValue.push({ code: val.key, description: val.key });
            } else {
                _.remove(x.selectedAttributeValue, (y) => { return y.code === val.key });
            }
            this.onAttributeDropDownChange(attributeName, x.selectedAttributeValue);
        });
        this.setState({ attributeConfig: attributeConfigData });
    };
    private onScopeDropDownChange = (event) => {
        this.setState({ scope: event.target.value });
    };
    private onMultiSelectDropDownChange = (event, option, attributeName) => {
        const { attributeConfig, selectedValues, editRequest } = this.state;
        let attributeConfigData = [...attributeConfig];
        let selectedKeyValues = selectedValues;
        attributeConfigData.forEach(x => {
            if (x.attributeName == attributeName) {
                if (option.selected) {
                    if (editRequest && x.selectedAttributeValue.filter(x => x.code == option.key)?.length > 0) {
                        _.remove(x.selectedAttributeValue, (y) => { return y.code === option.key });
                    }
                    x.selectedAttributeValue.push({ code: option.key, description: option.text });
                    selectedKeyValues.push(option.key);
                    let removedItems = x.selectedAttributeValue.filter(x => !selectedKeyValues.includes(x.code))
                    if (editRequest && removedItems?.length > 0) {
                        removedItems.forEach(y => {
                            _.remove(x.selectedAttributeValue, (z) => { return y.code === z.code });
                            x.selectedAttributeValue.push({ code: y.code, description: y.description, isRemove: true });
                        })
                    }
                } else {
                    _.remove(x.selectedAttributeValue, (y) => { return y.code === option.key });
                    _.remove(selectedKeyValues, (z) => { return z === option.key })
                    if (editRequest) {
                        x.selectedAttributeValue.push({ code: option.key, description: option.text, isRemove: true });
                    }
                }
                this.onAttributeDropDownChange(attributeName, x.selectedAttributeValue);
            }
        });
        this.setState({ attributeConfig: attributeConfigData, selectedValues: selectedKeyValues });
    }

    private onPeoplePickerChange(items: any) {
        this.setState({ isAlternateManager: '', alternateManagerAlias: '' });
        if (items && items.length > 0) {
            let userProperties = items[0].tertiaryText;
            let parsedData = JSON.parse(userProperties);
            this.setState({ alternateManagerAlias: parsedData.upn, isAlternateManager: 'Validating Data' });
            AccessAPI.ValidateUserJobTitle(parsedData.upn).then(res => {
                if (res) {
                    this.setState({ isAlternateManager: "Warning: Your submission for access will be directed to a member of the Microsoft Senior Leadership Team. Do you want to continue?" });
                }
                else {
                    this.setState({ isAlternateManager: '' });
                }
            });
        }
    }

    private onRequestorPeoplePickerChange(items: any) {
        this.setState({ requestorPrincipalId: '', selectedAccountType: '0', selectedAccountUPN: '' });
        if (items && items.length > 0) {
            let userProperties = items[0].tertiaryText;
            let parsedData = JSON.parse(userProperties);
            this.setState({ requestorPrincipalId: parsedData.id });
            if (parsedData.upn?.includes("#EXT#") || !parsedData.upn?.includes("@microsoft.com")) {
                this.setState({ isAlternateManagerChecked: false, allowSkipLevel: false, renderSkiplevel: false })
            }
            else {
                let allowAlternateManager = this.state.selectedRole?.permissions?.length > 0 && this.state.selectedRole.permissions[0].actions.includes("AllowAlternateManager:true")
                if (allowAlternateManager) {
                    AccessAPI.GetManager(parsedData.upn).then(res => {
                        res && res.upn != '' && AccessAPI.GetManager(res?.upn).then(response => {
                            response && this.setState({ alternateManagerAlias: response?.upn, ManagerAlias: response });
                        });
                        if (res.upn == '') {
                            this.setState({ alternateManagerAlias: '', ManagerAlias: { id: '', name: '', displayName: '', email: '', upn: 'Manager not found', surname: '', domain: '', jobTitle: '' } });
                        }
                    });
                }
                this.setState({ renderSkiplevel: allowAlternateManager })
            }
        }
        else {
            AccessAPI.GetManager(this.state.userDetails.Alias).then(res => {
                res && AccessAPI.GetManager(res?.upn).then(response => {
                    response && this.setState({ alternateManagerAlias: response?.upn, ManagerAlias: response });
                });
            });
            this.setState({ renderSkiplevel: this.state.selectedRole?.permissions?.length > 0 && this.state.selectedRole.permissions[0].actions.includes("AllowAlternateManager:true") })
        }
    };
    private onMyAccessEditClick = (data) => {
        const { subsidiariesConfig, businessConfig } = this.state;

        if (this.tenantData.TenantName == UIConstants.Tenant.Mercury) {
            this.prePopulateMercuryAccess(data);
        }
        else if (this.tenantData.TenantName == UIConstants.Tenant.Alfred) {
            this.prePopulateAlfredAccess(data);
        }
        else if (this.tenantData.TenantName == UIConstants.Tenant.MDS) {
            this.prePopulateMDSAccess(data);
        }
        else {
            this.prePopulateMSSalesAccess(data, subsidiariesConfig, businessConfig);
        }
    }
    /**
     * Data Initializer
     */
    private getGroupSpecificRoles = (): IDropdownOption[] => {
        const { RoleDetails, mstRoleGroupConfig, selectedGroup, vlAppsRoleGroupConfig, isMSTTenant } = this.state;
        if (selectedGroup.length > 0) {
            let roles = isMSTTenant ? mstRoleGroupConfig.filter(x => x.GroupName == selectedGroup)[0].Roles : [];
            let filteredroles = RoleDetails.Data.filter(x => roles.includes(x.id));
            this._roleDropdownItems = filteredroles.map(x => { return { key: x.id, text: x.name } });
        } else {
            this._roleDropdownItems = [];
        }

        return this._roleDropdownItems;
    };
    private getGroupSpecificRolesForVlApps = (): IDropdownOption[] => {
        const { RoleDetails, selectedGroup, vlAppsRoleGroupConfig, enableRoleGrouping } = this.state;
        if (selectedGroup.length > 0) {
            let roles = enableRoleGrouping ? vlAppsRoleGroupConfig?.Data.businessGroups?.filter(x => x.businessGroupId == selectedGroup)[0]?.roles : [];
            this._roleDropdownItems = [];
            roles?.forEach(item => {
                let role = RoleDetails.Data.find(x => x.id == item.roleId);
                if (role) {
                    this._roleDropdownItems.push({ key: role.id, text: role.name })
                }
            })
        } else {
            this._roleDropdownItems = [];
        }

        return this._roleDropdownItems;
    };
    private getGroupSpecificExternalAADRoles = (): IDropdownOption[] => {
        const { selectedGroup, cosmosRoleDefinitions, isRequestModeBulk } = this.state;
        if (selectedGroup.length > 0) {
            this._roleDropdownItems = [];
            let bgName = cosmosRoleDefinitions?.filter(x => x.businessGroupId == selectedGroup)[0]?.businessGroupName;
            cosmosRoleDefinitions?.filter(x => x.businessGroupName == bgName).forEach(item => {
                this._roleDropdownItems.push({ key: item.roleId, text: item.roleName })
            })
            //sorts rolenames alphabetically in ascending order
            this._roleDropdownItems.sort((a, b) => {
                if (a.text.toUpperCase() < b.text.toUpperCase()) {
                    return -1
                }
                else if (a.text.toUpperCase() > b.text.toUpperCase()) {
                    return 1
                }
                else {
                    return 0
                }
            })
        } else {
            this._roleDropdownItems = [];
        }
        return this._roleDropdownItems;
    };
    private getRoles = (): IDropdownOption[] => {
        const { RoleDetails } = this.state;
        this._roleDropdownItems = RoleDetails.Data.map(x => { return { key: x.id, text: x.name } });
        return this._roleDropdownItems;
    };
    private getRolesDetails = (): RoleDetails => {
        let { selectedRole, selectedAttributes, BulkUserDetails } = this.state;
        let role: RoleDetails = {
            roleId: selectedRole.id,
            roleName: selectedRole.name,
            roleDescription: selectedRole.description,
            attributes: selectedAttributes,
            actions: selectedRole.permissions && selectedRole.permissions.length > 0 ? selectedRole.permissions[0].actions : []
        };
        if (this.tenantData?.isExternalAADTenant && BulkUserDetails.length > 0) {
            role = {
                roleId: "f6750a07-2630-b4da-2469-5492496b5f1a",
                roleName: "On Behalf (Bulk Request)",
                roleDescription: "",
                attributes: selectedAttributes,
                actions: []
            };
        }
        return role;
    };
    private getProgramValues = (): any[] => {
        const { selectedRole, programFamily, partnerCenterConfig, attributeConfig } = this.state;
        let programItems = [];
        let filterdAttributeConfig = attributeConfig.filter(x => x.attributeName === 'Program')[0];
        let attributeValues = filterdAttributeConfig?.attributeValue;
        let program = programFamily != '' ? partnerCenterConfig.filter(x => x.ProgramFamily == programFamily && x.Role == selectedRole.id)[0]?.Program : [];
        program?.forEach(x => {
            if (attributeValues?.filter(y => y.code == x)) {
                programItems.push(x);
            }
        });
        return programItems;
    }
    private getProgramFamilyValues = (): any[] => {
        const { selectedRole, partnerCenterConfig, attributeConfig } = this.state;
        let programItems = [];
        let filterdAttributeConfig = attributeConfig.filter(x => x.attributeName === 'ProgramFamily')[0];
        let attributeValues = filterdAttributeConfig?.attributeValue;
        let program = selectedRole.id != '' ? partnerCenterConfig.filter(x => x.Role == selectedRole.id)[0]?.ProgramFamily : [];
        program?.forEach(x => {
            if (attributeValues?.filter(y => y.code == x)) {
                programItems.push(x);
            }
        });
        return programItems;
    }
    private getFunctionValues = (): any[] => {
        const { accessTypeBI, karnakFunctionConfig, attributeConfig } = this.state;
        let functionItems = [];
        let filterdAttributeConfig = attributeConfig.filter(x => x.attributeName === 'Function')[0];
        let attributeValues = filterdAttributeConfig?.attributeValue;
        let functionItem = karnakFunctionConfig != '' ? karnakFunctionConfig.filter(x => x.AccessTypeBI == accessTypeBI)[0]?.Function : [];
        functionItem?.forEach(x => {
            if (attributeValues?.filter(y => y.code == x)) {
                functionItems.push(x);
            }
        });
        return functionItems;
    }
    /**
     * Helper function to keep the role attributes values updated
     * will be called when there is change in attributes
     */
    private updateAttributeValues = (attributeName: string, attributeValue: AttributeValues[]) => {
        const { selectedAttributes } = this.state;
        let updatedAttributes: Attributes[] = JSON.parse(JSON.stringify(selectedAttributes));
        let filteredAttribute: Attributes[] = updatedAttributes.filter(x => x.attributeName === attributeName);

        if (filteredAttribute.length > 0) { // If present update existing value
            if (attributeValue.length == 0 || (attributeValue.length > 0 && attributeValue[0].code == '0')) { // reset attribute if -- Select -- 
                updatedAttributes = updatedAttributes.filter(x => x.attributeName != attributeName);
                if (attributeName == 'ProgramFamily') {
                    updatedAttributes = updatedAttributes.filter(x => x.attributeName != 'Program');
                }
                if (attributeName == 'AccessTypeBI') {
                    updatedAttributes = updatedAttributes.filter(x => x.attributeName != 'Function');
                }
                else if (attributeName == 'Fiscal Cycles') {
                    updatedAttributes.push({ attributeName: 'Fiscal Cycles', attributeValues: attributeValue })
                }
            } else {
                updatedAttributes.forEach(x => {
                    if (x.attributeName === attributeName) {
                        x.attributeValues = attributeValue
                    }
                });
            }

        } else {// else add new value
            let newAttributeValues: Attributes = { attributeName: attributeName, attributeValues: attributeValue };
            updatedAttributes.push(newAttributeValues);
        }
        this.setState({ selectedAttributes: updatedAttributes, showMessage: false, setAttributeFocus: false });
        if (this.tenantData.TenantName == UIConstants.Tenant.MSSales && this.state.message != '') {
            this.setState({ showMessage: true });
        }
    };
    private prePopulateMSSalesAccess(data: any, subsidiariesConfig: any, businessConfig: any) {
        var sub = data[0].attributes.Subsidiary;
        var bus = data[0].attributes.Business;

        let uniqueSubsidiaryExpandedValues = new Set();
        let subsidiaryExpanded = [];
        let subsidiaryChecked = sub.map(z => { return subsidiariesConfig.find(x => x.children.find(y => y.label.replace(/'/g, '') == z.split(":")[1]))?.children.find(x => x.label.replace(/'/g, '') == z.split(":")[1]).value; });
        sub.forEach(x => { uniqueSubsidiaryExpandedValues.add(subsidiariesConfig.find(y => y.label == x.split(":")[0])?.value); });
        subsidiaryExpanded = Array.from(uniqueSubsidiaryExpandedValues);

        let uniqueBusinessExpandedValues = new Set();
        let businessExpanded = [];
        let businessChecked = bus.map(z => { return businessConfig.find(x => x.children.find(y => y.label.replace(/'/g, '') == z.split(":")[1]))?.children.find(x => x.label.replace(/'/g, '') == z.split(":")[1]).value; });
        bus.forEach(x => { uniqueBusinessExpandedValues.add(businessConfig.find(y => y.label == x.split(":")[0])?.value); });
        businessExpanded = Array.from(uniqueBusinessExpandedValues);
        let roleData = {
            id: data[0].roleDefinitionId,
            name: data[0].roleName,
            scopes: null,
            description: data[0].roleDescription,
            permissions: null,
            sortOrder: '',
            attributes: data[0].attributes
        };
        let defaultReportingSubsidiary = subsidiariesConfig.find(x => x.children.find(y => y.label.replace(/'/g, '') == data[0].attributes.DefaultReportingSubsidiary[0]))?.children.find(x => x.label.replace(/'/g, '') == data[0].attributes.DefaultReportingSubsidiary[0]).label;
        let attributeData = [{
            attributeName: "Default Reporting Subsidiary",
            attributeValues: [{ code: defaultReportingSubsidiary, description: defaultReportingSubsidiary, id: Number.parseInt(subsidiariesConfig.find(x => x.children.find(y => y.label.replace(/'/g, '') == data[0].attributes.DefaultReportingSubsidiary[0].replace(/'/g, '')))?.children.find(x => x.label.replace(/'/g, '') == data[0].attributes.DefaultReportingSubsidiary[0].replace(/'/g, '')).value) }]
        }];
        this.setState({
            showModal: false, showSubsidiary: true, editRequest: true, approvedaccess: data, showMessage: false, displayApprovedBasicExistsMessage: false,
            showMSSalesTermsAndConditions: true, hideBj: false, subsidiaryChecked: subsidiaryChecked, subsidiaryExpanded: subsidiaryExpanded,
            businessChecked: businessChecked, businessExpanded: businessExpanded, updateRequest: false, displayApprovedExistsMessage: false,
            selectedRole: roleData,
            selectedAttributes: attributeData, scope: this.tenantData.TenantName, selectedDefaultReportingSubsidiary: defaultReportingSubsidiary
        });
    }

    private setInitialState() {
        this.setState({
            selectedRole: { id: '0', name: null, scopes: null, description: '', permissions: [], sortOrder: '', attributes: [] }, showSubsidiary: false
        })
    };
    /**
     * Initial onInit function - first entry point
     */
    private onInit = (tenantData: ITenant) => {
        this.setState({ isPartnerCenterTenant: false, enableRoleGrouping: false, isMSTTenant: false, showMSSalesTermsAndConditions: false })
        if (tenantData) {
            this.setState({ isPartnerCenterTenant: false, isMSTTenant: false, showMSSalesTermsAndConditions: false });
            ConfigurationAPI.getConfigurationByKey(UIConstants.Configuration.QuizValidation).then(response => {
                this.setState({ quizUrl: response })
            })
            if (tenantData?.isExternalAADTenant) {
                AccessAPI.getCosmosRoleDefinitions(tenantData?.TenantId).then(res => {
                    let roleDefinitions: ExternalAADRoles[] = res;
                    this.setState({ RoleDetails: { Data: roleMockData, IsSuccess: true, IsDataLoaded: true }, cosmosRoleDefinitions: roleDefinitions, hideBj: false })
                });
                AccessAPI.GetManager(this.state.userDetails.Alias).then(res => {
                    res && AccessAPI.GetManager(res?.upn).then(response => {
                        response && this.setState({ alternateManagerAlias: response?.upn.split('@')[0], ManagerAlias: response, hideBj: false });
                    });
                });
                ConfigurationAPI.getConfigurationByKey(UIConstants.Configuration.AllowedAccountTypes).then(response => {
                    let config = response ? JSON.parse(response.replace(/'/g, `"`)) : null;
                    if (config != null && config.length > 0) {
                        let allowedTypes = config.filter(x => x.TenantName?.toLowerCase() == this.tenantData?.TenantName.toLowerCase())
                        this.setState({ allowedAccountTypes: allowedTypes?.length > 0 ? allowedTypes[0].AllowedDomains : [] })
                    }
                })
            }
            else if (this.tenantData.TenantName != UIConstants.Tenant.Mercury) {
                AccessAPI.requestRoleByTenant(tenantData.TenantId).then(roleDetails => {
                    this.setState({ RoleDetails: roleDetails });
                    // Call API to Get Role
                    if (tenantData.TenantName.toLowerCase() == UIConstants.Tenant.Alfred.toLowerCase()) {
                        AccessAPI.GetManager(this.state.userDetails.Alias).then(res => {
                            res && this.setState({ ManagerAlias: res });
                            if (res && res.jobTitle?.toLowerCase() == 'ceo') {
                                this.setState({ managerCEOMinus1or2: "Warning: Access request cannot be routed to CEO. Please choose an alternate FTE approver." });
                            }
                            else {
                                res && res.name != "N/A" && res.name != '' && AccessAPI.ValidateUserJobTitle(res.upn).then(res => {
                                    if (res) {
                                        this.setState({ managerCEOMinus1or2: "Warning: Your submission for access will be directed to a member of the Microsoft Senior Leadership Team. Do you want to continue?" });
                                    }
                                    else {
                                        this.setState({ managerCEOMinus1or2: '' });
                                    }
                                });
                            }
                        });
                    }
                    if (tenantData.TenantName.toLowerCase() == UIConstants.Tenant.PartnerCenter.toLowerCase()) {
                        ConfigurationAPI.getConfigurationByKey(UIConstants.Configuration.PartnerCenterConfig).then(response => {
                            let ProgramConfig = response ? JSON.parse(response.replace(/'/g, `"`)) : [];
                            this.setState({ partnerCenterConfig: ProgramConfig })
                        })
                        this.setState({ isPartnerCenterTenant: true });
                    }
                    else if (tenantData.TenantName == UIConstants.Tenant.Karnak) {
                        ConfigurationAPI.getConfigurationByKey(UIConstants.Configuration.KarnakGroupConfig)// Call API to Get Karnak EnvironmentConfig
                            .then(response => {
                                let GroupConfig = response ? JSON.parse(response.replace(/'/g, `"`)) : [];
                                this.setState({ karnakConfig: GroupConfig });
                            });
                        ConfigurationAPI.getConfigurationByKey(UIConstants.Configuration.KarnakFunctionConfig)
                            .then(response => {
                                let GroupConfig = response ? JSON.parse(response.replace(/'/g, `"`)) : [];
                                this.setState({ karnakFunctionConfig: GroupConfig });
                            });
                    }
                    else if (tenantData.TenantName == UIConstants.Tenant.MSSales) {
                        ConfigurationAPI.getConfigurationByKey("MSSalesTestConfig").then((configRes) => {
                            let config = configRes ? JSON.parse(configRes.replace(/'/g, `"`)) : null;
                            if (config != null && config?.EnableCEOMinus1 && config?.testRequestor.includes(this.state.userDetails.Alias.split("@")[0])) {
                                AccessAPI.searchUser(PeoplePickerContentType.User, config.staticCEOMinus1RequestorAlias).then(searchResponse => {
                                    let user = searchResponse[0];
                                    this.setState({ userDetails: { PrincipalId: user.id, Alias: user.upn, Name: user.name } })
                                    AccessAPI.GetFTEManager(user.upn).then(res => {
                                        res && this.setState({ ManagerAlias: res[0], userDetails: { PrincipalId: user.id, Alias: user.upn, Name: user.name } });
                                        if (res && res[0].jobTitle?.toLowerCase() == 'ceo') {
                                            this.setState({ isManager: "Warning: Access request cannot be routed to CEO. Please choose an alternate FTE approver." });
                                        }
                                        else {
                                            res && res[0].name != "N/A" && AccessAPI.ValidateUserJobTitle(res[0].upn).then(res => {
                                                if (res) {
                                                    this.setState({ isManager: "Warning: Your submission for access will be directed to a member of the Microsoft Senior Leadership Team. Do you want to continue?" });
                                                }
                                                else {
                                                    this.setState({ isManager: '' });
                                                }
                                            });
                                        }
                                    });
                                })
                            }
                            else {
                                AccessAPI.GetFTEManager(this.state.userDetails.Alias).then(res => {
                                    res && this.setState({ ManagerAlias: res[0] });
                                    if (res && res[0].jobTitle?.toLowerCase() == 'ceo') {
                                        this.setState({ isManager: "Warning: Access request cannot be routed to CEO. Please choose an alternate FTE approver." });
                                    }
                                    res && res[0].name != "N/A" && AccessAPI.ValidateUserJobTitle(res[0].upn).then(res => {
                                        if (res) {
                                            this.setState({ isManager: "Warning: Your submission for access will be directed to a member of the Microsoft Senior Leadership Team. Do you want to continue?" });
                                        }
                                        else {
                                            this.setState({ isManager: '' });
                                        }
                                    });

                                });
                            }
                        });
                        let myaccess = AccessAPI.getMyAccess(tenantData.TenantId);
                        let subsidiariesConfig = AccessAPI.getSubsidiaries();
                        let businessConfig = AccessAPI.getBusiness();
                        Promise.all([subsidiariesConfig, businessConfig, myaccess]).then((responses) => {
                            let approvedMSSalesAccess = responses[2].Data.approvedAccess;
                            let hasMSSalesBasicAccess = responses[2].Data.approvedAccess.filter(x => x.attributes != null && x.attributes.DefaultReportingSubsidiary != null).length
                            this.setState({ hasMSSalesBasicAccess: hasMSSalesBasicAccess > 0 ? true : false, approvedMSSalesAccess: approvedMSSalesAccess, myAccessDataLoaded: true, myAccess: responses[2].Data });

                            let defaultReportingSubsidiaries = responses[0].map(x => x.subsidiaryName);
                            let uniqueDefaultReportingSubsidiaries = new Set();
                            defaultReportingSubsidiaries.forEach(item => {
                                item.map(x => uniqueDefaultReportingSubsidiaries.add(x));
                            });
                            defaultReportingSubsidiaries = Array.from(uniqueDefaultReportingSubsidiaries).sort();
                            let subsidiaries = responses[0].map((x) => {
                                return {
                                    value: "subsidiaryGroup-" + x.subsidiaryGroupID.toString(),
                                    label: x.subsidiaryGroupName,
                                    children: x.subsidiaries.map(y => { return { value: y.subsidiaryId.toString(), label: y.subsidiaryName } })
                                }
                            });
                            let business = responses[1].map((x) => {
                                return {
                                    value: "BusinessGroup-" + x.businessGroupID.toString(),
                                    label: x.businessGroupName,
                                    children: x.businesses.map(y => { return { value: y.businessID.toString(), label: y.businessName } })
                                }
                            });
                            this.setState({ defaultReportingSubsidiariesConfig: defaultReportingSubsidiaries, subsidiariesConfig: subsidiaries, businessConfig: business, showMSSalesTermsAndConditions: true });
                        });
                    }
                    //MDS
                    else if (tenantData.TenantName == UIConstants.Tenant.MDS) {
                        let myaccess = AccessAPI.getMyAccess(tenantData.TenantId).then(response => {
                            let approvedMDSAccess = response && response.Data.approvedAccess;
                            response && this.setState({ approvedMDSAccess: approvedMDSAccess, myAccessDataLoaded: true, myAccess: response.Data });
                        }) ;
                    }
                    else if (tenantData.TenantName == UIConstants.Tenant.MST) {
                        ConfigurationAPI.getConfigurationByKey(UIConstants.Configuration.MSTRoleGroupConfig)// Call API to Get MST EnvironmentConfig
                            .then(response => {
                                let GroupConfig = response ? JSON.parse(response.replace(/'/g, `"`)) : [];
                                this.setState({ mstRoleGroupConfig: GroupConfig, isMSTTenant: true, hideBj: false });
                            });
                    }
                    else if (tenantData.EnableRoleGrouping == true) {
                        roleDetails && AccessAPI.getBusinessGroupByTenant(tenantData.TenantId).then(res => {
                            let groupName = new URLSearchParams(window.location.search.toLowerCase()).get("groupname");
                            let roleName = new URLSearchParams(window.location.search.toLowerCase()).get("rolename");
                            if (groupName != null && roleName != null) {
                                let selectedGroupId = res?.Data?.businessGroups.filter(x => x.businessGroupName.toLowerCase() == groupName)[0]?.businessGroupId;
                                let roles = res?.Data?.businessGroups.filter(x => x.businessGroupName.toLowerCase() == groupName)[0]?.roles;
                                let selectedRoleId = roles?.filter(x => x.roleName.toLowerCase() == roleName)[0]?.roleId;
                                let selectedRole = roleDetails.Data.filter(x => x.id == selectedRoleId)[0] ?? { id: '0', name: null, scopes: null, description: '', permissions: [], sortOrder: '', attributes: [] };
                                selectedRoleId && this.onRoleDropDownChange(selectedRoleId)
                                this.setState({
                                    selectedBusinessGroupRole: selectedRoleId ?? '0', selectedGroup: selectedGroupId ?? '0', scope: this.tenantData.TenantName,
                                    selectedRole: { id: selectedRole.id, name: selectedRole.name, scopes: selectedRole.scopes, description: selectedRole.description, permissions: selectedRole.permissions, sortOrder: selectedRole.sortOrder, attributes: selectedRole.attributes, businessGroup: res?.Data?.businessGroups.filter(x => x.businessGroupName.toLowerCase() == groupName)[0]?.businessGroupName }
                                });
                                let attrValue = [{ code: res?.Data?.businessGroups.filter(x => x.businessGroupId == selectedGroupId)[0]?.businessGroupName, description: res?.Data?.businessGroups.filter(x => x.businessGroupId == selectedGroupId)[0]?.businessGroupName }];
                                this.updateAttributeValues(UIConstants.Attribute.BusinessGroup, attrValue);
                            }
                            this.setState({ vlAppsRoleGroupConfig: res, hideBj: false, enableRoleGrouping: true });
                        });
                        AccessAPI.GetManager(this.state.userDetails.Alias).then(res => {
                            res && AccessAPI.GetManager(res?.upn).then(response => {
                                response && this.setState({ alternateManagerAlias: response?.upn.split('@')[0], ManagerAlias: response });
                            });
                        });
                    }
                    else if (this.tenantData.TenantName == UIConstants.Tenant.FCW) {
                        AccessAPI.getAttributeData('', this.tenantData.TenantId, UIConstants.Attribute.OperationsDetail).then(searchResponse => {
                            let searchResult = [];
                            let options: IComboBoxOption[] = [];
                            if (searchResponse && searchResponse && searchResponse.length > 0) {
                                searchResult = searchResponse.map(item => ({ key: item.code, name: item.code !== item.description ? item.code + " : " + item.description : item.code }));
                            }
                            searchResponse && searchResponse.length > 0 && searchResult.forEach(attribute => {
                                options.push({
                                    key: attribute.key, text: attribute.name
                                });
                            });
                            this.setState({ options: options, operationsDetailsLoaded: true });
                        });
                    }
                });
            } else {
                this.setState({ RoleDetails: { Data: roleMockData, IsSuccess: true, IsDataLoaded: true } })
            }
            if (tenantData.EnableExternalRequestOnBehalf || (tenantData.EnableRequestOnBehalf != null && tenantData.EnableRequestOnBehalf.length > 0)) {
                AccessAPI.AllowUserToRequestOnBehalf(tenantData.TenantId).then(res => {
                    this.setState({ enableRequestOnBehalf: res })
                })
            }
        } else {
            window.location.href = "/";
        }
    };
    /**
   * Helper function to validate the data to be submitted
   */
    private ValidateData = (): ValidationResponse => {
        const { alfredCyclesNodes, renderAttestation, cyclesChecked, selectedAccountUPN, selectedRole, selectedAttributes, Justification, enableRoleGrouping, selectedGroup, alternateManagerAlias, ManagerAlias,
            showMSSalesTermsAndConditions, showTermsAndConditions, isTermsAgreed, tenantData, Signature, userDetails, attributeChecked,
            isAlternateManagerChecked, isAlternateManager, showSubsidiary, isRequestModeBulk, isSelected, selectedFile, approvedaccess } = this.state;
        let response: ValidationResponse = { isValid: true };
        let errorMessage = null;
        // Validation case 1 : check if mandatory scopes are selected
        if (selectedRole.scopes && selectedRole.scopes.length > 1) {
            if (this.state.scope === null) {
                this.scopeRef.current.focus();
                errorMessage = errorMessage == null ? UIConstants.Labletext.RoleType : `${errorMessage}, ${UIConstants.Labletext.RoleType}`
            }
        }
        let allScopesRequired = false;

        let permissionAction = selectedRole.permissions && selectedRole.permissions.length > 0 && selectedRole.permissions[0].actions;
        if (permissionAction) {
            allScopesRequired = permissionAction.find(x => x.indexOf(UIConstants.RoleDefinition.AllScopesRequired) > -1) ? true : false;
        }
        // Validation case 2 : check if company codes are selected
        if (selectedRole.permissions && selectedRole.permissions.length > 0 &&
            selectedRole.permissions[0].condition !== null && selectedRole.attributes && selectedRole.attributes.length > 0) {
            if (selectedAttributes.length === 0) {
                if (allScopesRequired) {
                    this.setState({ setAttributeFocus: true });
                    for (const attribute of selectedRole.attributes) {
                        errorMessage = errorMessage == null ? attribute : `(${errorMessage} or ${attribute})`
                    }
                }
                else if (attributeChecked != null && attributeChecked.length > 0) {
                    let attributeSelected = selectedRole.attributes.find(attribute => attributeChecked.some(x => x.Name == attribute)).length;
                    if (attributeSelected <= 0) {
                        this.setState({ setAttributeFocus: true });
                        errorMessage = errorMessage == null ? UIConstants.Messages.MDSScopesRequired : `${errorMessage}, ${UIConstants.Messages.MDSScopesRequired}`
                    }                    
                }
                else {
                    for (const attribute of selectedRole.attributes) {
                        // this.attributePickerRef.current.focus();
                        this.setState({ setAttributeFocus: true });
                        errorMessage = errorMessage == null ? attribute : `${errorMessage}, ${attribute}`
                    }
                }
            }
            else if (selectedAttributes.length > 0) {
                if (tenantData.TenantName == UIConstants.Tenant.MDS) {
                    if (attributeChecked.length > 0) {
                        let attributeSelected = selectedRole.attributes.find(attribute => attributeChecked.some(x => x.Name == attribute)).length;
                        if (attributeSelected <= 0) {
                            this.setState({ setAttributeFocus: true });
                            errorMessage = errorMessage == null ? UIConstants.Messages.MDSScopesRequired : `${errorMessage}, ${UIConstants.Messages.MDSScopesRequired}`
                        }
                    }
                    else {
                        let attributeSelected = selectedRole.attributes.filter(attribute =>
                            selectedAttributes.filter(x =>
                                x.attributeName == attribute && x.attributeValues.filter(z => z.isRemove != true).length > 0
                            ).length > 0
                        );
                        if (attributeSelected != null && attributeSelected.length <= 0) {
                            this.setState({ setAttributeFocus: true });
                            errorMessage = errorMessage == null ? UIConstants.Messages.MDSScopesRequired : `${errorMessage}, ${UIConstants.Messages.MDSScopesRequired}`
                        }
                    }
                   
                }
                else {
                    for (const attribute of selectedRole.attributes) {
                        let attributeValue = selectedAttributes.filter(x => x.attributeName == attribute)[0]?.attributeValues;
                        let attributes = selectedAttributes.filter(x => x.attributeName == attribute)
                        if (attributes.length > 0) {
                            if (!attributeValue || (attributeValue && attributeValue.length === 0)) {
                                errorMessage = errorMessage == null ? attribute : `${errorMessage}, ${attribute}`
                            }
                        }
                        else {
                            errorMessage = errorMessage == null ? attribute : `${errorMessage}, ${attribute}`
                        }
                    }
                }
                
                if (tenantData.TenantName == UIConstants.Tenant.FCW && selectedRole.name == "Data Reader") {
                    this.setState({ setAttributeFocus: true });
                    errorMessage = null
                }
            }
        }
        //Validation
        if (this.tenantData?.TenantName == UIConstants.Tenant.MSSales && selectedRole.name?.includes("User Account") && ManagerAlias.name == "N/A") {
            return { isValid: false, validationMessage: UIConstants.Messages.ManagerNotFound }
        }
        // Validation : check if user alias is updated or not
        if (tenantData.TenantName == UIConstants.Tenant.MSSales && approvedaccess != null && approvedaccess.length > 0) {
            let userAlias = approvedaccess[0].attributes.UserAlias
            if (userAlias == null || userAlias.length == 0 || userAlias[0] == '' || userAlias[0] != userDetails.Alias.split("@")[0]) {
                return { isValid: false, validationMessage: UIConstants.Messages.UserAliasChange }
            }
        }
        // Validation case 3 : check if subsidairies are selected for MSSales
        if (tenantData.TenantName == UIConstants.Tenant.MSSales && showSubsidiary) {
            if (this.state.businessChecked.length == 0 && this.state.subsidiaryChecked.length == 0)
                errorMessage = errorMessage == null ? UIConstants.Subsidaries : `${errorMessage}, ${UIConstants.Subsidaries}`
            else if (this.state.businessChecked.length == 0)
                errorMessage = errorMessage == null ? UIConstants.BusinessSelection : `${errorMessage}, ${UIConstants.BusinessSelection}`
            else if (this.state.subsidiaryChecked.length == 0)
                errorMessage = errorMessage == null ? UIConstants.SubsidiarySelection : `${errorMessage}, ${UIConstants.SubsidiarySelection}`
            if (!selectedAttributes.find(x => x.attributeName == 'Default Reporting Subsidiary')) {
                errorMessage = errorMessage == null ? UIConstants.DefaultReportingSubsidiary : `${errorMessage}, ${UIConstants.DefaultReportingSubsidiary}`
            }
        }

        //Validation case 4 : check Role is selected or not for indivudual and file is selected or not for bulk
        if (this.tenantData?.isExternalAADTenant) {
            if (selectedGroup.length == 0 || selectedGroup == '0') {
                errorMessage = errorMessage == null ? UIConstants.Group : `${errorMessage}, ${UIConstants.Group}`
            }
            if ((this.tenantData?.isExternalAADTenant && this.tenantData?.TenantName.toLowerCase() != UIConstants.Tenant.MST.toLowerCase()) && !isRequestModeBulk && this.accountTypeDivRef.current != null && (selectedAccountUPN == null || selectedAccountUPN == '')) {
                errorMessage = errorMessage == null ? UIConstants.AccountType : `${errorMessage}, ${UIConstants.AccountType}`
            }
            if (!isRequestModeBulk && selectedRole.id == '0') {
                errorMessage = errorMessage == null ? UIConstants.Role : `${errorMessage}, ${UIConstants.Role}`
            }
            if (isRequestModeBulk && !isSelected) {
                errorMessage = errorMessage == null ? UIConstants.File : `${errorMessage}, ${UIConstants.File}`
            }
            else if (isRequestModeBulk && isSelected && selectedFile.type.toLowerCase() != "text/csv") {
                errorMessage = errorMessage == null ? UIConstants.FileType : `${errorMessage}, ${UIConstants.FileType}`
            }
        }

        if (this.state.tenantData.TenantName == UIConstants.Tenant.Alfred) {
            if (alfredCyclesNodes.length > 0 && cyclesChecked.length < 1) {
                errorMessage = errorMessage == null ? UIConstants.FiscalCycles : `${errorMessage}, ${UIConstants.FiscalCycles}`
            }
        }

        // Validation case 5 : check if role and business group are selected for VLApps
        if (enableRoleGrouping) {
            if (selectedGroup.length == 0 || selectedGroup == '0') {
                errorMessage = errorMessage == null ? UIConstants.Group : `${errorMessage}, ${UIConstants.Group}`
            }
            if (selectedRole.id == '0') {
                errorMessage = errorMessage == null ? UIConstants.Role : `${errorMessage}, ${UIConstants.Role}`
            }
        }

        // Validation case 6 : check if Business Justification is empty
        if (Justification.trim().length === 0) {
            this.refBJ.current.focus();
            errorMessage = errorMessage == null ? UIConstants.BusinessJustfication : `${errorMessage}, ${UIConstants.BusinessJustfication}`
        }

        // Validation case 7 : check if T&C applicable if yes validate
        if ((showTermsAndConditions || showMSSalesTermsAndConditions || renderAttestation) && isTermsAgreed === false) {
            // this.refTC.current.focus();
            errorMessage = errorMessage == null ? UIConstants.TermsAndConditions : `${errorMessage}, ${UIConstants.TermsAndConditions}`
        }

        // Validation case 8 : check if Signature is correct
        if (tenantData.TenantName == UIConstants.Tenant.MSSales && showMSSalesTermsAndConditions && Signature.trim().toLowerCase() != userDetails.Name?.split(" (")[0].trim().toLowerCase()) {
            errorMessage = errorMessage == null ? UIConstants.Signature : `${errorMessage}, ${UIConstants.Signature}`
            this.setState({ isSignatureVerfied: false });
        }
        else {
            this.setState({ isSignatureVerfied: true });
        }


        // Validation case 9 : check if alternate Manager is correct
        if ((tenantData.TenantName == UIConstants.Tenant.MSSales || tenantData.TenantName == UIConstants.Tenant.Alfred) && isAlternateManagerChecked) {
            if (alternateManagerAlias == '') {
                errorMessage = errorMessage == null ? UIConstants.AlternateManager : `${errorMessage}, ${UIConstants.AlternateManager}`
            }
        }

        if (errorMessage) {
            return { isValid: false, validationMessage: ` ${errorMessage} is required.` }
        }
        else {
            return response; // Validation Success , Allow user to Submit
        }
    };

    private prePopulateMercuryAccess = (data) => {
        this.setState({ isMercuryEditMode: true, mercuryEditAccessData: data });
    }
    private prePopulateAlfredAccess = (data) => {
        const { RoleDetails } = this.state;
        let roleData = {
            id: data.roleId,
            name: data.role,
            scopes: RoleDetails.Data.find(x => x.id == data.roleId).scopes,
            description: RoleDetails.Data.find(x => x.id == data.roleId).description,
            permissions: RoleDetails.Data.find(x => x.id == data.roleId).permissions,
            sortOrder: RoleDetails.Data.find(x => x.id == data.roleId).sortOrder,
            attributes: RoleDetails.Data.find(x => x.id == data.roleId).attributes
        };
        let attributeValues = []
        data.multiAttributes[0].attributeValueText.split("; ").forEach(item => {
            attributeValues.push({ code: item.split(":")[0], description: item.split(":")[1] });
        });
        let attributeData = [{
            attributeName: data.multiAttributes[0].attributeName,
            attributeValues: attributeValues
        }];

        let provisionedCycles = attributeData[0].attributeValues.map(x => x.code);

        this.setState({
            showModal: false, editRequest: true, approvedaccess: data, showMessage: false, hideBj: false, selectedRole: roleData,
            selectedAttributes: attributeData, scope: this.tenantData.TenantName, approvedAlfredCycles: provisionedCycles
        });
        if (roleData.name.split(' ').length > 1) {
            let nodesInAscendingOrder = [];
            let fiscalYear = roleData.name.split(' ')[1].toString();
            this.setState({ fetchingFiscalCycles: true })
            AccessAPI.GetCyclesBasedonFiscalYear(this.state.tenantData.TenantId, fiscalYear).then(cycles => {
                let nodes = cycles.map(x => { return { label: x.code.trim() + ': ' + x.name, value: x.code, children: null } })
                nodes.map(x => x.value).map(Number).sort((a, b) => a - b).map(String).map(str => str.padStart(3, '0')).forEach(x => {
                    nodes.forEach(node => {
                        if (node.value == x) {
                            nodesInAscendingOrder.push(node)
                        }
                    })
                })
                let checkedCycles = []
                nodes.forEach(item => {
                    if (data.multiAttributes[0].attributeValueText.split(';').findIndex(x => x.split(':')[0].trim() == item.label.split(':')[0].trim()) != -1) {
                        checkedCycles.push(item.value);
                    }
                });
                this.setState({ selectedRole: roleData, alfredCycles: cycles, alfredCyclesNodes: nodesInAscendingOrder, fetchingFiscalCycles: false, cyclesChecked: checkedCycles })
            })
        }
        else {
            this.setState({ selectedRole: roleData, alfredCycles: [], alfredCyclesNodes: [], cyclesChecked: [] })
        }
        let iswriteRole = roleData.name.includes('Write') ? true : false;
        this.setState({ renderAttestation: iswriteRole });
    }

    private prePopulateMDSAccess = (data) => {
        const { RoleDetails, attributeChecked, attributeExpanded } = this.state;
        let roleData = {
            id: data.roleId,
            name: data.role,
            scopes: RoleDetails.Data.find(x => x.id == data.roleId).scopes,
            description: data.roleDescription,
            permissions: RoleDetails.Data.find(x => x.id == data.roleId).permissions,
            sortOrder: RoleDetails.Data.find(x => x.id == data.roleId).sortOrder,
            attributes: RoleDetails.Data.find(x => x.id == data.roleId).attributes
        };
        let attributeData = []
        let attributeCheckedVariable = attributeChecked != null && attributeChecked.length > 0 ? attributeChecked : [];
        let attributeExpandedVariable = attributeExpanded != null && attributeExpanded.length > 0 ? attributeExpanded : [];
        data.multiAttributes.forEach(attribute => {
            let attributeValues = []
            attribute.attributeValueText.split("; ").forEach(item => {
                if (roleData.name == "Approver - Revenue & Pricing Level") {
                    attributeValues.push({ code: "Parent-" + item.split(":")[0], description: item.split(":")[1] });
                }
                else {
                    attributeValues.push({ code: item.split(":")[0], description: item.includes(":") ? item.split(":")[1] : item.split(":")[0]});
                }                
                });
            attributeData.push({
                attributeName: attribute.attributeName.replaceAll(" ", ""),
                attributeValues: attributeValues
            });
            if (roleData.name == "Approver - Revenue & Pricing Level") {
                let checkedValues = attributeChecked != null && attributeChecked.length > 0 && attributeChecked.filter(x => x.Name == attribute.attributeName).length > 0 ? attributeChecked.filter(x => x.Name == attribute.attributeName)[0].Values : [];
                let expandedValues = attributeExpanded != null && attributeExpanded.length > 0 && attributeExpanded.filter(x => x.Name == attribute.attributeName).length > 0 ? attributeExpanded.filter(x => x.Name == attribute.attributeName)[0].Values : [];
                let newValues: CheckBoxTreeModel = {
                    Name: attribute.attributeName.replaceAll(" ", ""),
                    Values: attributeValues.map(x => x.code)
                };
            if (attributeCheckedVariable.filter(x => x.Name == attribute.attributeName).length > 0) {
                attributeCheckedVariable[attribute.attributeName] = newValues
            }
            else {
                attributeCheckedVariable.push(newValues);
            }
            if (attributeExpandedVariable.filter(x => x.Name == attribute.attributeName).length > 0) {
                attributeExpandedVariable[attribute.attributeName] = newValues
            }
            else {
                attributeExpandedVariable.push(newValues);
                }
            }
        })
        
        let index = 0;
        let selectedKeys = [];
        attributeData.forEach(x => x.attributeValues.forEach(y => selectedKeys.push(y.code)));
        let cosmosDisplayAttributes = roleData.permissions[0].actions.filter(x => x.indexOf(`FetchCosmosAttributes:`) >= 0) //MDS
        let attribute = cosmosDisplayAttributes[0].split('FetchCosmosAttributes:');
        let cosmosAttributesToDisplay = attribute[1].split(',');
        let cosmosAttributesConfig = cosmosAttributesToDisplay.map(x => {
            let splitVal = x.split('-');
            if (splitVal.length > 1) {
                let attrName = splitVal[0];
                let attrDisplayName = attrName === UIConstants.RoleDefinition.Company ? UIConstants.Labletext.CompanyCode : attrName;
                return { attributeName: attrName, attributeDisplayName: attrDisplayName, attributeType: splitVal[1], attributeValue: null, selectedAttributeValue: [] }
            } else {
                let attrName = splitVal[0];
                let attrDisplayName = attrName === UIConstants.RoleDefinition.Company ? UIConstants.Labletext.CompanyCode : attrName;
                return { attributeName: attrName, attributeDisplayName: attrDisplayName, attributeType: AttributeType.Lookup, attributeValue: null, selectedAttributeValue: [] }
            }
        })//MDS
        AccessAPI.getMDSRoleAndScopes(roleData.name).then(attributeResponse => {
            for (const iterator of cosmosAttributesConfig) {
                if (iterator.attributeType.toLowerCase() === AttributeType.List.toLowerCase() || iterator.attributeType.toLowerCase() === AttributeType.MultiSelectList.toLowerCase()) {
                    let scopeValues = attributeResponse && attributeResponse.find(scope => scope.scopeName === iterator.attributeName);
                    if (scopeValues) {
                        let attributes: Array<AttributeValues> = scopeValues.scopes.map(scope => ({
                            code: scope.domainCode,
                            description: scope.domainName
                        }));
                        iterator.attributeValue = attributes.sort((a, b) => (a.description?.toUpperCase() < b.description?.toUpperCase()) ? -1 : 1);
                        iterator.selectedAttributeValue = attributeData.filter(x => x.attributeName.toLowerCase() === iterator.attributeName.toLowerCase()).length > 0 ? attributeData.find(x => x.attributeName.toLowerCase() === iterator.attributeName.toLowerCase()).attributeValues : [];
                    }
                    index++;
                    if (cosmosAttributesConfig.length === index) {
                        this.setState({
                            attributeConfig: cosmosAttributesConfig
                        });
                    }
                }
                else if (iterator.attributeType.toLowerCase() === AttributeType.Tree.toLowerCase()) {
                    let scopeValues = attributeResponse && attributeResponse.find(scope => scope.scopeName === iterator.attributeName);
                    let attributes: Array<AttributeValues> = [];
                    if (scopeValues) {
                        if (scopeValues.childName != null && scopeValues.childName != "") {
                            let childScopeValues = attributeResponse.find(scope => scope.scopeName === scopeValues.childName);
                            scopeValues.scopes.map(parentScope => {
                                let childscopes = childScopeValues.scopes.filter(scope => scope.parentDomainCode === parentScope.domainCode)
                                if (childscopes?.length > 0) {
                                    childscopes.forEach(item => {
                                        let attributeAsChild = attributeCheckedVariable.filter(x => x.Name == iterator.attributeName)[0].Values?.includes("Parent-" + item.domainCode);
                                        attributes.push({
                                            GroupCode: "Parent-" + parentScope.domainCode,
                                            GroupDescription: parentScope.domainName,
                                            code: item?.domainCode,
                                            description: item?.domainName
                                        });
                                        if (attributeAsChild) {
                                            _.remove(attributeCheckedVariable.filter(x => x.Name == iterator.attributeName)[0].Values, (z) => { return z === "Parent-" + item.domainCode });
                                            attributeCheckedVariable.filter(x => x.Name == iterator.attributeName)[0].Values.push(item.domainCode);
                                            attributeExpandedVariable.filter(x => x.Name == iterator.attributeName)[0].Values.push("Parent-" + parentScope.domainCode);
                                        }
                                    });
                                }
                                else {
                                    attributes.push({
                                        GroupCode: "Parent-" + parentScope.domainCode,
                                        GroupDescription: parentScope.domainName,
                                        code: childScopeValues.scopes.find(scope => scope.parentDomainCode === parentScope.domainCode)?.domainCode,
                                        description: childScopeValues.scopes.find(scope => scope.parentDomainCode === parentScope.domainCode)?.domainName
                                    });
                                }
                            });
                        }
                        else {
                            attributes = scopeValues.scopes.map(scope => ({
                                GroupCode: "Parent-" + scope.domainCode,
                                GroupDescription: scope.domainName,
                                code: null,
                                description: null
                            }));
                        }
                        iterator.attributeValue = attributes.sort((a, b) => (a.description?.toUpperCase() < b.description?.toUpperCase()) ? -1 : 1).sort((a, b) => (a.GroupDescription.toUpperCase() < b.GroupDescription.toUpperCase()) ? -1 : 1);
                        let uniqueExpandedValues = new Set();
                        attributeExpandedVariable.filter(x => x.Name == iterator.attributeName)[0].Values.forEach(item => uniqueExpandedValues.add(item));
                        attributeExpandedVariable.filter(x => x.Name == iterator.attributeName)[0].Values = Array.from(uniqueExpandedValues);
                    }
                    index++;
                    if (cosmosAttributesConfig.length === index) {
                        this.setState({
                            attributeConfig: cosmosAttributesConfig
                        });
                    }
                }
                else {
                    iterator.attributeValue = [];
                    index++;
                    if (cosmosAttributesConfig.length === index) {
                        this.setState({
                            attributeConfig: cosmosAttributesConfig
                        });
                    }
                }

            }

            let isApproverRole = roleData.name.includes('Approver') ? true : false;
            this.setState({
                showModal: false, approvedaccess: data, showMessage: false, hideBj: false, selectedRole: roleData, renderAttestation: isApproverRole, editRequest: true,
                selectedAttributes: attributeData, scope: this.tenantData.TenantName, selectedValues: selectedKeys, hideAttributes: false, attributesLoaded: true,
                attributeChecked: attributeCheckedVariable, attributeExpanded: attributeExpandedVariable
            });

        });
        
    }
}
