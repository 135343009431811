import React from "react";
import _ from "lodash";
import {
  Text,
  IDropdownOption,
  ILabelStyles,
  Label,
  Spinner,
  SpinnerSize,
  getId,
  ILabelStyleProps,
  IStyleFunctionOrObject,
  ShimmeredDetailsList,
  SelectionMode,
  DetailsListLayoutMode,
} from "@fluentui/react";
import { UIConstants } from "../../shared/models/Constants";
import AccessAPI from "../../store/AccessAPI";
import ITenant from "../../shared/models/Tenant.model";
import { Policy } from "../../shared/models/UserAccess.model";
import {
  ExternalAADRoles,
  Role,
  RoleResponse,
} from "../../shared/models/Role.model";
import { msalAuth } from "../../shared/auth/MsalAuthProvider";
import { IUser } from "../../shared/models/User.model";
import {
  Permission,
  PermissionGroup,
  PermissionLevel,
  PermissionRender,
  Forms,
  PlancastModel,
  FormRender
} from "../../shared/models/Mercury";
import { Utility } from "../../shared/models/Helper";
import { RouteComponentProps } from "react-router";

const labelStyles: Partial<
  IStyleFunctionOrObject<ILabelStyleProps, ILabelStyles>
> = {
  root: { marginTop: 7 },
};

export interface IScopeApproversState {
  selectedRole?: Role;
  userDetails: IUser;
  permissionType: string;
  permissionGroup: string;
  permissionLevel: string;
  permissionStructure: Permission[];
  requestorPrincipalId: string;
  selectedPermisisonType: Permission;
  selectedPermisisonGroup: PermissionGroup;
  selectedPermisisonLevel: PermissionLevel;
  permissionLevelList: any;
  tenantData: ITenant;
  permissionMaster: PermissionRender[];
  policy: Policy;
  dataLoaded: boolean;
  RoleDetails: RoleResponse;
  ExternalAADRoleDetails: ExternalAADRoles[];
  isExternalAADRoleDataLoaded: boolean;
  selectedBgRoles: IDropdownOption[];
  businessGrps: any;
  plancastStructure: PlancastModel[];
  formMaster: FormRender[];
  platform: string;
  formList: any;
  form: string;
}
export interface IScopeApproversProperty {
  tenantData: ITenant;
}

export default class ScopeApprovers extends React.Component<
  IScopeApproversProperty & RouteComponentProps,
  IScopeApproversState
> {
  private tenantData: ITenant = null;
  private submitClicked: boolean = false;
  private _labelId: string = getId("dialogLabel");
  private _subTextId: string = getId("subTextLabel");
  private _roleDropdownItems: IDropdownOption[] = [];
  private _businessgrpDropDownItems: IDropdownOption[] = [];
  public state: IScopeApproversState = {
    selectedRole: {
      typeId: "0",
      type: null,
      groupId: "0",
      group: null,
      id: "0",
      name: null,
      scopes: null,
      description: "",
      permissions: [],
      sortOrder: "",
      attributes: [],
      approvalPolicy: "",
    },
    userDetails: null,
    permissionType: "0",
    permissionGroup: "0",
    permissionLevel: "0",
    permissionStructure: [],
    requestorPrincipalId: "",
    selectedPermisisonType: null,
    selectedPermisisonGroup: null,
    selectedPermisisonLevel: null,
    tenantData: null,
    permissionLevelList: [],
    permissionMaster: [],
    policy: null,
    dataLoaded: true,
    RoleDetails: { Data: [], IsSuccess: false, IsDataLoaded: false },
    ExternalAADRoleDetails: [],
    isExternalAADRoleDataLoaded: false,
    selectedBgRoles: [],
    businessGrps: [],
    plancastStructure: [],
    formMaster: [],
    platform: '0',
    formList: [],
    form: '0'
  };
  public constructor(props) {
    super(props);
  }
  /**
   * React Life cycle Events
   */
  public async componentDidMount() {
    let userAccount = msalAuth.getAccount();
    let user: IUser = {
      PrincipalId: userAccount.accountIdentifier,
      Alias: userAccount.userName,
      Name: userAccount.name,
    };
    let tenantName = (this.props.match.params as any).tenantName;
    tenantName = tenantName.toUpperCase();
    let TenantData = Utility.GetTenantData();
    this.tenantData = TenantData.filter(
      (x) => x.TenantName.toUpperCase() === tenantName
    )[0];
    setTimeout(() => {
      this.setState({ userDetails: user, tenantData: this.tenantData });
    }, 500);
    if (tenantName == UIConstants.Tenant.Mercury.toUpperCase()) {
      AccessAPI.GetMercuryData().then((res) => {
        let permissionData: Permission[] = [...res];
        let permissionMasterList: PermissionRender[] = [];
        permissionData.forEach((permissionTypeItem) => {
          permissionTypeItem.permission.forEach((permGrpItem) => {
            permGrpItem.permissionLevels.forEach((permLevelItem) => {
              permissionMasterList.push({
                permissionTypeID: permissionTypeItem.permissionTypeID,
                permissionType: permissionTypeItem.permissionType,
                permissionTypesortOrder: permissionTypeItem.sortOrder,
                permissionGroupID: permGrpItem.permissionGroupID,
                permissionGroup: permGrpItem.permissionGroup,
                permissionGroupDescription:
                  permGrpItem.permissionGroupDescription,
                permissionGroupsortOrder: permGrpItem.sortOrder,
                permissionLevelID: permLevelItem.permissionLevelID,
                permissionLevel: permLevelItem.permissionLevel,
                permissionLevelSortOrder: permLevelItem.sortOrder,
                permissionLevelDescription:
                  permLevelItem.permissionLevelDescription,
                allowEdit: permLevelItem.allowEdit,
                approvalPolicy: permLevelItem.approvalPolicy,
                attribute: permLevelItem.attribute,
                isActive: permLevelItem.isActive,
                role: permLevelItem.role,
              });
            });
          });
        });
        permissionMasterList = _.orderBy(permissionMasterList, [
          "permissionTypesortOrder",
          "permissionGroupsortOrder",
          "permissionLevelSortOrder",
        ]);
        this.setState({
          permissionStructure: res,
          permissionMaster: permissionMasterList,
        });
      });
    } else if (this.tenantData.isExternalAADTenant) {
        AccessAPI.getCosmosRoleDefinitions(this.tenantData.TenantId).then(
            (res) => {
                this.setState({
                    ExternalAADRoleDetails: res,
                    isExternalAADRoleDataLoaded: true,
                });
            }
        );
    }
    else if (tenantName!=null && tenantName == UIConstants.Tenant.MSPlancast.toUpperCase()) {
            AccessAPI.GetPlancastData().then(res => {
                let platformData: PlancastModel[] = [...res];
                let formMasterList: FormRender[] = [];
                platformData.forEach(platformItem => {
                    platformItem.forms.forEach(formItem => {
                        formMasterList.push({
                            platformId: platformItem.id,
                            platformName: platformItem.platformName,
                            platformNameDescription: platformItem.platformDescription,
                            platformsortOrder: platformItem.sortOrder,
                            formId: formItem.formId,
                            formName: formItem.formName,
                            formNameDescription: formItem.formDescription,
                            formSortOrder: formItem.sortOrder,
                            allowEdit: formItem.allowEdit,
                            approvalPolicy: formItem.approvalPolicy,
                            isActive: formItem.isActive
                        })
                    });
                });
                formMasterList = _.orderBy(formMasterList, ['platformsortOrder', 'formSortOrder']);
                this.setState({ plancastStructure: res, formMaster: formMasterList });
            });
        }
        else if (this.tenantData.EnableRoleGrouping) {
        AccessAPI.getBusinessGroupByTenant(this.tenantData.TenantId).then(
            (res) => {
                let bgGrps = res.Data?.businessGroups;
                this.setState({ businessGrps: bgGrps });
            }
        );

      AccessAPI.requestRoleByTenant(this.tenantData.TenantId).then(
        (roleDetails) => {
          this.setState({ RoleDetails: roleDetails });
          // Call API to Get Role
        }
      );
    } else {
      AccessAPI.requestRoleByTenant(this.tenantData.TenantId).then(
        (roleDetails) => {
          this.setState({ RoleDetails: roleDetails });
          // Call API to Get Role
        }
      );
    }
  }

  public render(): JSX.Element {
    const {
      permissionGroup,
      permissionLevel,
      permissionStructure,
      selectedPermisisonGroup,
      selectedPermisisonLevel,
      permissionLevelList,
      policy,
      RoleDetails,
      selectedRole,
      plancastStructure,
      platform,
      formList,
      form
    } = this.state;

    return (
      <React.Fragment>
        <div
          id={"div-req-section"}
          style={{ marginTop: "50px", padding: "1%", paddingTop: "2%" }}
        >
          <div key="grid-request" className={"ms-Grid"} dir="ltr">
            <div key="grid-row-pgtitle" className={"ms-Grid-row"}>
              <div className={"ms-Grid-col ms-sm6 ms-md8 ms-lg10"}>
                <Label style={{ paddingLeft: "8px", fontSize: 20 }}>
                  {" "}
                  <h1
                    role="heading"
                    aria-level={1}
                    className={"h1"}
                    style={{ paddingLeft: 0, fontSize: 20, margin: 0 }}
                  >
                    {" "}
                    {UIConstants.PageTitle.ApprovalPolicy}
                  </h1>
                </Label>
              </div>
               <div className={"ms-Grid-col ms-sm6 ms-md8 ms-lg10"}>
                 {this.tenantData?.TenantName == UIConstants.Tenant.Mercury &&
                  (permissionStructure ? (
                    <div key="grid-row-role" className={"ms-Grid-row"}>
                      <div
                        className={"ms-Grid-col ms-sm12 ms-md8 ms-lg12"}
                        style={{ paddingLeft: "23px" }}
                      >
                        <div>
                          <Label required={true} styles={labelStyles}>
                            {UIConstants.Role}
                          </Label>
                          <select
                            id={"drpGrp-pg"}
                            value={permissionGroup}
                            title={UIConstants.PermissionGroup}
                            required={true}
                            onChange={this.onRoleChange}
                            style={{
                              height: 32,
                              width: window.innerWidth < 400 ? 250 : 350,
                            }}
                          >
                            <option value="0">-- Select --</option>
                            {this.getPermissionGroups()?.map((x) => {
                              return (
                                <option
                                  key={"drpGrp-pg" + x.key}
                                  aria-label={x.text}
                                  value={x.key}
                                >
                                  {x.text}
                                </option>
                              );
                            })}
                          </select>
                          {selectedPermisisonGroup && (
                            <div>
                              <Text>
                                {
                                  selectedPermisisonGroup.permissionGroupDescription
                                }
                              </Text>
                            </div>
                          )}
                        </div>
                      </div>
                      {permissionGroup != "0" &&
                        permissionLevelList != null &&
                        permissionLevelList.length > 0 && (
                          <div
                            className={"ms-Grid-col ms-sm12 ms-md8 ms-lg12"}
                            style={{ paddingLeft: "23px" }}
                          >
                            <div>
                              <Label required={true} styles={labelStyles}>
                                {UIConstants.PermissionScope}
                              </Label>
                              <select
                                id={"drpGrp-pl"}
                                value={permissionLevel}
                                title={UIConstants.Role}
                                required={true}
                                onChange={(event) => {
                                  this.onPermissionScopeChange(event);
                                }}
                                style={{
                                  height: 32,
                                  width: window.innerWidth < 400 ? 250 : 350,
                                }}
                              >
                                <option value="0">-- Select --</option>
                                {permissionLevelList &&
                                  permissionLevelList.map((x, index) => {
                                    return (
                                      <option
                                        key={"drpGrp-pl" + index + x.key}
                                        aria-label={x.text}
                                        value={x.key}
                                      >
                                        {x.text}
                                      </option>
                                    );
                                  })}
                              </select>
                              {selectedPermisisonLevel && (
                                <div>
                                  <Text>
                                    {
                                      selectedPermisisonLevel.permissionLevelDescription
                                    }
                                  </Text>
                                </div>
                              )}
                            </div>
                          </div>
                        )}
                    </div>
                  ) : (
                    <Spinner
                      size={SpinnerSize.large}
                      label="loading..."
                      ariaLive="assertive"
                      labelPosition="bottom"
                    />
                                ))}
                            {this.tenantData?.TenantName == UIConstants.Tenant.MSPlancast && (plancastStructure ? <div key='grid-row-role' className={"ms-Grid-row"}>
                                <div className={"ms-Grid-col ms-sm12 ms-md8 ms-lg12"} style={{ paddingLeft: '23px' }}>
                                    <div>
                                        <Label required={true} styles={labelStyles}>{UIConstants.Platform}</Label>
                                        <select id={'drpGrp-pg'} value={platform}
                                            title={UIConstants.Platform} required={true}
                                            onChange={this.onPlaCastRoleChange}
                                            style={{ height: 32, width: window.innerWidth < 400 ? 250 : 350 }}>
                                            <option value="0">-- Select --</option>
                                            {this.getPlaformDetails()?.map(x => { return <option key={'drpGrp-pg' + x.key} aria-label={x.text} value={x.key}>{x.text}</option> })}
                                        </select>
                                    </div>
                                </div>
                                {platform != "0" && formList != null && formList.length > 0 && <div className={"ms-Grid-col ms-sm12 ms-md8 ms-lg12"} style={{ paddingLeft: '23px' }}>
                                    <div>
                                        <Label required={true} styles={labelStyles}>{UIConstants.Form}</Label>
                                        <select id={'drpGrp-pl'} value={form}
                                            title={UIConstants.Form} required={true}
                                            onChange={(event) => { this.onFormChange(event) }}
                                            style={{ height: 32, width: window.innerWidth < 400 ? 250 : 350 }}>
                                            <option value="0">-- Select --</option>
                                            {formList && formList.map((x, index) => { return <option key={'drpGrp-pl' + index + x.key} aria-label={x.text} value={x.key}>{x.text}</option> })}
                                        </select>
                                    </div>
                                </div>}
                            </div> : <Spinner size={SpinnerSize.large} label="loading..." ariaLive="assertive" labelPosition="bottom" />)}
                {this.tenantData?.TenantName != UIConstants.Tenant.MSPlancast && 
                this.tenantData?.TenantName != UIConstants.Tenant.Mercury &&
                  (RoleDetails.Data ||
                  this.state.ExternalAADRoleDetails.length > 0 ? (
                    <div>
                      {(this.state.tenantData?.isExternalAADTenant ||
                        this.state.tenantData?.EnableRoleGrouping) && (
                        <div key="grid-row-role" className={"ms-Grid-row"}>
                          <div
                            className={"ms-Grid-col ms-sm12 ms-md8 ms-lg12"}
                            style={{ paddingLeft: "23px" }}
                          >
                            <div>
                              <Label required={true} styles={labelStyles}>
                                {UIConstants.Group}
                              </Label>
                              <select
                                id={"drpRole"}
                                title={UIConstants.Group}
                                required={true}
                                onChange={(event) =>
                                  this.onBusinessGrpDrpdwnChange(
                                    event.target.value
                                  )
                                }
                                style={{
                                  height: 32,
                                  width: window.innerWidth < 400 ? 250 : 350,
                                }}
                              >
                                <option value="0">-- Select --</option>
                                {this.getBusinessGroups().map((x) => {
                                  return (
                                    <option
                                      key={"drpRole" + x.key}
                                      aria-label={x.text}
                                      value={x.key}
                                    >
                                      {x.text}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                          </div>
                        </div>
                      )}
                      <div key="grid-row-role" className={"ms-Grid-row"}>
                        <div
                          className={"ms-Grid-col ms-sm12 ms-md8 ms-lg12"}
                          style={{ paddingLeft: "23px" }}
                        >
                          <div>
                            <Label required={true} styles={labelStyles}>
                              {UIConstants.Role}
                            </Label>
                            <select
                              id={"drpRole"}
                              title={UIConstants.Role}
                              required={true}
                              onChange={(event) =>
                                this.onRoleDropDownChange(event.target.value)
                              }
                              style={{
                                height: 32,
                                width: window.innerWidth < 400 ? 250 : 350,
                              }}
                            >
                              <option value="0">-- Select --</option>
                              {this.state.tenantData &&
                              (this.state.tenantData?.isExternalAADTenant ||
                                this.state.tenantData?.EnableRoleGrouping)
                                ? this.state.selectedBgRoles.map((x) => {
                                    return (
                                      <option
                                        key={"drpRole" + x.key}
                                        aria-label={x.text}
                                        value={x.key}
                                      >
                                        {x.text}
                                      </option>
                                    );
                                  })
                                : this.getRoles().map((x) => {
                                    return (
                                      <option
                                        key={"drpRole" + x.key}
                                        aria-label={x.text}
                                        value={x.key}
                                      >
                                        {x.text}
                                      </option>
                                    );
                                  })}
                            </select>
                          </div>{" "}
                        </div>
                      </div>{" "}
                    </div>
                  ) : (
                    <Spinner
                      size={SpinnerSize.large}
                      label="loading..."
                      ariaLive="assertive"
                      labelPosition="bottom"
                    />
                  ))}
                <div key="work-area">
                  {this.state.dataLoaded ? (
                    policy != null && (
                      <ShimmeredDetailsList
                        setKey="scopeapprover-items"
                        columns={this.ApproverDataCoulmns()}
                        items={this.getPolicyItems()}
                        layoutMode={DetailsListLayoutMode.justified}
                        selectionMode={SelectionMode.none}
                        enableShimmer={false}
                        ariaLabelForShimmer="Scope Approver details is being fetched"
                        ariaLabelForGrid="Scope Approver details"
                        detailsListStyles={{ root: { overflow: "visible" } }}
                        listProps={{
                          renderedWindowsAhead: 10,
                          renderedWindowsBehind: 10,
                        }}
                      />
                    )
                  ) : (
                    <Spinner
                      size={SpinnerSize.large}
                      style={{
                        paddingLeft: 120,
                        paddingTop: 30,
                        alignItems: "start",
                      }}
                      label="loading..."
                      ariaLive="assertive"
                      labelPosition="bottom"
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
  private getBusinessGroups() {
    const { tenantData, ExternalAADRoleDetails, businessGrps } = this.state;
    let uniqueItems;
    if (tenantData?.isExternalAADTenant) {
      this._businessgrpDropDownItems = ExternalAADRoleDetails.map((x) => {
        return { key: x.businessGroupId, text: x.businessGroupName };
      });
      uniqueItems = this._businessgrpDropDownItems.filter(
        (item, index) =>
          this._businessgrpDropDownItems.findIndex(
            (x) => x.text == item.text
          ) == index
      );
    } else if (tenantData?.EnableRoleGrouping) {
      this._businessgrpDropDownItems = businessGrps.map((x) => {
        return { key: x.businessGroupId, text: x.businessGroupName };
      });
      uniqueItems = this._businessgrpDropDownItems.filter(
        (item, index) =>
          this._businessgrpDropDownItems.findIndex(
            (x) => x.text == item.text
          ) == index
      );
    }
    uniqueItems.sort((x, y) => {
      if (x.text.toUpperCase() > y.text.toUpperCase()) {
        return -1;
      } else if (x.text.toUpperCase() < y.text.toUpperCase()) {
        return 1;
      } else {
        return 0;
      }
    });
    let index = uniqueItems.findIndex((x) => x.text == "Global_Roles");
    if (index != -1) {
      let globalRoles = uniqueItems[index];
      uniqueItems.splice(index, 1);
      uniqueItems.push(globalRoles);
    }
    return uniqueItems.reverse();
  }
  private onBusinessGrpDrpdwnChange(value: any) {
    const { ExternalAADRoleDetails, tenantData, businessGrps } = this.state;
    let selectedBusinessGrpId = value;
    let roles;
    if (tenantData?.isExternalAADTenant) {
      let bgName = ExternalAADRoleDetails.find(
        (x) => x.businessGroupId == value
      ).businessGroupName;
      roles = ExternalAADRoleDetails.filter(
        (x) => x.businessGroupName == bgName
      ).map((x) => {
        return { key: x.roleId, text: x.roleName };
      });
    } else if (tenantData?.EnableRoleGrouping) {
      let bgName = businessGrps.find(
        (x) => x.businessGroupId == selectedBusinessGrpId
      ).businessGroupName;
      let groupSpecificRoles = businessGrps
        .filter((x) => x.businessGroupName == bgName)
        .map((x) => x.roles);
      roles = groupSpecificRoles[0].map((x) => {
        return { key: x.roleId, text: x.roleName };
      });
    }
    roles.sort((a, b) => {
      if (a.text.toUpperCase() < b.text.toUpperCase()) {
        return -1;
      } else if (a.text.toUpperCase() > b.text.toUpperCase()) {
        return 1;
      } else {
        return 0;
      }
    });
    this.setState({ selectedBgRoles: roles });
  }
  private onRoleDropDownChange = (value: any): void => {
    const { RoleDetails, tenantData, ExternalAADRoleDetails, businessGrps } =
      this.state;
    if (tenantData.isExternalAADTenant) {
      let selectedRoleKey = value;
      let policyName = ExternalAADRoleDetails.find(
        (x) => x.roleId == selectedRoleKey
      ).policyType;
      this.GetPolicyDetails(policyName.split(":")[1].trim());
    } else {
      let currentRole = RoleDetails.Data.filter((x) => x.id === value)[0];
      let approvalPolicy =
        currentRole.permissions[0].actions[0].split("Policy:")[1];
      this.GetPolicyDetails(approvalPolicy);
    }
  };
  private getRoles = (): IDropdownOption[] => {
    const { RoleDetails, tenantData, ExternalAADRoleDetails } = this.state;
    this._roleDropdownItems = RoleDetails.Data.map((x) => {
      return { key: x.id, text: x.name };
    });
    return this._roleDropdownItems;
  };
  private onRoleChange = (event) => {
    const { permissionStructure, permissionMaster } = this.state;
    let plList: any = [];
    if (event.target.value == "0") {
      this.setState({
        permissionGroup: event.target.value,
        permissionLevel: "",
        policy: null,
        selectedPermisisonLevel: null,
        permissionLevelList: plList,
        selectedRole: {
          typeId: "0",
          type: null,
          groupId: "0",
          group: null,
          id: "0",
          name: null,
          scopes: null,
          description: "",
          permissions: [],
          sortOrder: "",
          attributes: [],
          approvalPolicy: "",
        },
      });
    } else {
      let selectedPermGrp = permissionMaster.filter(
        (x) => x.permissionGroupID == event.target.value
      );
      if (selectedPermGrp.length != 1) {
        plList = selectedPermGrp.map((x) => {
          return { key: x.permissionLevelID, text: x.permissionLevel };
        });
        plList = _.uniqBy(plList, "key");
        this.setState({
          permissionGroup: event.target.value,
          permissionLevel: "0",
          policy: null,
          selectedPermisisonLevel: null,
          permissionLevelList: plList,
          selectedRole: {
            typeId: "0",
            type: null,
            groupId: "0",
            group: null,
            id: "0",
            name: null,
            scopes: null,
            description: "",
            permissions: [],
            sortOrder: "",
            attributes: [],
            approvalPolicy: "",
          },
        });
      } else {
        plList = [];
        this.setState({
          permissionGroup: event.target.value,
          permissionLevel: permissionMaster.filter(
            (x) => x.permissionGroupID == event.target.value
          )[0].permissionLevelID,
          permissionLevelList: plList,
          policy: null,
          selectedRole: {
            typeId: permissionMaster.filter(
              (x) => x.permissionGroupID == event.target.value
            )[0].permissionTypeID,
            type: permissionMaster.filter(
              (x) => x.permissionGroupID == event.target.value
            )[0].permissionType,
            groupId: permissionMaster.filter(
              (x) => x.permissionGroupID == event.target.value
            )[0].permissionGroupID,
            group: permissionMaster.filter(
              (x) => x.permissionGroupID == event.target.value
            )[0].permissionGroup,
            id: permissionMaster.filter(
              (x) => x.permissionGroupID == event.target.value
            )[0].permissionLevelID,
            name: permissionMaster.filter(
              (x) => x.permissionGroupID == event.target.value
            )[0].permissionLevel,
            scopes: null,
            description: "",
            permissions: [],
            sortOrder: "",
            attributes: [],
            approvalPolicy: permissionMaster.filter(
              (x) => x.permissionGroupID == event.target.value
            )[0].approvalPolicy,
          },
        });
        let approvalPolicy = permissionMaster.filter(
          (x) => x.permissionGroupID == event.target.value
        )[0].approvalPolicy;
        this.GetPolicyDetails(approvalPolicy);
      }
    }
  };

    private onPlaCastRoleChange = (event) => {
        const { plancastStructure, formMaster } = this.state;
        let plList: any = [];
        if (event.target.value == '0') {
            this.setState({
                platform: event.target.value, permissionLevel: '', policy: null, selectedPermisisonLevel: null, formList: plList, selectedRole: { typeId: '0', type: null, groupId: '0', group: null, id: '0', name: null, scopes: null, description: '', permissions: [], sortOrder: '', attributes: [], approvalPolicy: '' }
            })
        }
        else {
            let selectedplatForm = formMaster.filter(x => x.platformId == event.target.value);
            if (selectedplatForm.length == 1 && selectedplatForm[0].platformName.toLowerCase() == selectedplatForm[0].formName.toLowerCase()) {
                plList = [];
                this.setState({
                    platform: event.target.value, form: formMaster.filter(x => x.platformId == event.target.value)[0].formId, permissionLevel: '0', formList: plList, policy: null,
                    selectedRole: {
                        typeId: formMaster.filter(x => x.platformId == event.target.value)[0].formId, type: formMaster.filter(x => x.platformId == event.target.value)[0].formName, groupId: null, id: formMaster.filter(x => x.platformId == event.target.value)[0].formId, name: formMaster.filter(x => x.platformId == event.target.value)[0].formName, scopes: null, description: '', permissions: [], sortOrder: '', attributes: [], approvalPolicy: formMaster.filter(x => x.platformId == event.target.value)[0].approvalPolicy
                    }
                })
                let approvalPolicy = formMaster.filter(x => x.platformId == event.target.value)[0].approvalPolicy;
                this.GetPolicyDetails(approvalPolicy);
            }
            else {
                plList = selectedplatForm.map(x => { return { key: x.formId, text: x.formName } });
                plList = _.uniqBy(plList, 'key');
                this.setState({
                    platform: event.target.value, form: '0', permissionLevel: '0', policy: null, selectedPermisisonLevel: null, formList: plList, selectedRole: { typeId: '0', type: null, groupId: '0', group: null, id: '0', name: null, scopes: null, description: '', permissions: [], sortOrder: '', attributes: [], approvalPolicy: '' }
                })
            }
        }
    };

  private getPermissionGroups = (): IDropdownOption[] => {
    const { permissionMaster } = this.state;
    let permissionGroupList = permissionMaster.map((y) => {
      return { key: y.permissionGroupID, text: y.permissionGroup };
    });
    permissionGroupList = _.uniqBy(permissionGroupList, "key");
    return permissionGroupList;
    };
    private getPlaformDetails = (): IDropdownOption[] => {
        const { formMaster } = this.state;
        let platFormList = formMaster.map(y => { return { key: y.platformId, text: y.platformName } });
        platFormList = _.uniqBy(platFormList, 'key');
        return platFormList;
    };

  private onPermissionScopeChange(event) {
    const { permissionStructure, permissionMaster } = this.state;
    this.setState({ permissionLevel: event.target.value, policy: null });
    let selectedPermissionLevel = permissionMaster.filter(
      (x) => x.permissionLevelID == event.target.value
    )[0];
    let permissionLevels = permissionStructure
      ?.filter(
        (x) => x.permissionTypeID == selectedPermissionLevel.permissionTypeID
      )[0]
      ?.permission.filter(
        (x) => x.permissionGroupID == selectedPermissionLevel.permissionGroupID
      )[0]
      ?.permissionLevels.filter(
        (x) => x.permissionLevelID == selectedPermissionLevel.permissionLevelID
      )[0];
    this.setState({
      selectedRole: {
        id: event.target.value,
        name: null,
        scopes: [],
        description: "",
        permissions: [],
        sortOrder: "",
        attributes: [],
        approvalPolicy: permissionLevels.approvalPolicy,
      },
    });
    this.GetPolicyDetails(permissionLevels.approvalPolicy);
  }

    private onFormChange(event) {
        const { plancastStructure, formMaster } = this.state;
        let selectedForm = formMaster.filter(x => x.formId == event.target.value)[0];
        let formDetails = plancastStructure?.filter(x => x.id == selectedForm.platformId)[0]?.forms
            .filter(x => x.formId == selectedForm.formId)[0]
        this.setState({
            form: event.target.value, policy: null,
            selectedRole: {
                id: event.target.value,
                name: null, scopes: [], description: '', permissions: [], sortOrder: '', attributes: [], approvalPolicy: formDetails.approvalPolicy
            }
        });
        this.GetPolicyDetails(formDetails.approvalPolicy);
    }
    private GetPolicyDetails(approvalPolicy: any) {
    const { policy } = this.state;
    this.setState({ dataLoaded: false });
    AccessAPI.GetPolicyBasedOnPolicyId(approvalPolicy).then((res) => {
      this.setState({ policy: res, dataLoaded: true });
    });
  }

  private getPolicyItems = () => {
    const { policy } = this.state;
    let policyData = [];
    if (
      policy != null &&
      policy.approval.levelInfo != null &&
      policy.approval.levelInfo.length > 0
    ) {
      let approverTypeFlag = policy.approval.levelInfo.filter(
        (x) => x.type == 3
      );
      let approverDetails = "";
      if (approverTypeFlag != null) {
        let approverTypeDetails = policy.approval.levelInfo.filter(
          (x) => x.type != 3
        );
        for (const approval of approverTypeDetails) {
          approverDetails +=
            "Level " + approval.levelId + " : " + approval.LevelCategory + "; ";
        }
      }
      for (const approval of policy.approval.levelInfo) {
        if (approval.levelScope != null && approval.type == 3) {
          policyData.push({
            code: approval.levelScope[0].code,
            description: approval.levelScope[0].description,
            approvers:
              approverDetails +
              "Level " +
              approval.levelId +
              "-" +
              approval.LevelCategory +
              " : " +
              approval.value,
          });
        } else {
          if (approverTypeFlag == null || approverTypeFlag.length == 0) {
            policyData.push({
              code: "-",
              description: "-",
              approvers:
                approval.LevelCategory != null
                  ? approval.type == 1
                    ? "Level " +
                      approval.levelId +
                      " : " +
                      approval.LevelCategory +
                      " - " +
                      approval.value
                    : "Level " +
                      approval.levelId +
                      " : " +
                      approval.LevelCategory
                  : "Level " +
                    approval.levelId +
                    " : " +
                    approval.value.replaceAll("$", ""),
            });
          }
        }
      }
    }
    return policyData;
  };

  private ApproverDataCoulmns = () => {
    const { policy } = this.state;
    let columnDef = [];
    if (
      policy != null &&
      policy.approval.levelInfo != null &&
      policy.approval.levelInfo.length > 0 &&
      policy.approval.levelInfo.filter((x) => x.type == 3)?.length > 0
    ) {
      columnDef.push({
        key: "code",
        name: "Code",
        fieldName: "code",
        minWidth: 120,
        maxWidth: 180,
        isResizable: true,
      });
      columnDef.push({
        key: "description",
        name: "Description",
        fieldName: "description",
        minWidth: 320,
        maxWidth: 320,
        isMultiline: true,
        isResizable: true,
      });
    }
    columnDef.push({
      key: "approvers",
      name: "Approvers",
      fieldName: "approvers",
      minWidth: 600,
      maxWidth: 600,
      isMultiline: true,
      isResizable: true,
    });
    return columnDef;
  };
}
